import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useData } from "../../Context/WalletFormProvider";
import { WalletContext } from "../../Context/WalletProvider";
import { setDynamicLabel } from "../../Store/Slices/WalletSlice.jsx";
import { addPrimaryFormsData } from "../../Store/Slices/primaryFormsDataSlice.jsx";
import { addOrUpdateAuxiliaryField } from "../../Store/Slices/auxiliaryFormsDataSlice.jsx";
import { isInSecondaryFields } from "../../utils/ckeckIsInPrimaryField.jsx";
import { isInAuxiliaryFields } from "../../utils/chackIsInAuxiliaryField.jsx";
import { isThisRowContainThisForm } from "../../utils/checkRowFormName.jsx";
import { storeGoogleFormsData } from "../../Store/Slices/GoogleWalletData/allRowFormsData/googleWalletFormsData.jsx";
import { updateGoogleRowLabel } from "../../Store/Slices/GoogleWalletData/Fields/googleFieldsRow.jsx";
const MobileNumber = () => {
  const {
    removeForm,
    setFormName,
    setActiveField,
    setPrimaryFields,
    primaryFields,
    auxFields,
    setAuxFields,
    setSecondaryFields,
    secondaryFields,
    wallets,
    formInputs,
    setFormInputs,
  } = useContext(WalletContext);
  const { googleWalletFieldsRow } = useSelector((state) => state);
  const { firstRow, secondRow, thirdRow, fourthRow } = googleWalletFieldsRow;
  const { addOrUpdateSecondaryFormData, setPrimaryFormData } = useData();
  const mobileNumberLabel = useSelector((state) => state.wallet.mobileNumber);
  const { primaryFormsData, googleWalletData } = useSelector((state) => state);
  const { firstRowData, secondRowData, thirdRowData, fourthRowData } =
    googleWalletData;
  const allRowsData = [
    firstRowData,
    secondRowData,
    thirdRowData,
    fourthRowData,
  ];
  const [defaulDisplayValue, setDefaulDisplayValue] = useState("");
  const dispatch = useDispatch();
  const [mobileNumber, setMobileNumber] = useState({
    id: 13,
    label: {
      label: "Label",
      value: "Mobile Number",
      baseValue: "Mobile Number",
    },
    displayValue: {
      label: "Display Value",
      value: "",
    },
  });

  const handleLabelChange = (e) => {
    const value = e.target.value;
    const payload = { form: "Mobile Number", value };
    dispatch(setDynamicLabel(payload));
    dispatch(updateGoogleRowLabel(payload));

    //
    if (!value) {
      setFormName("Mobile Number");
      setActiveField("Mobile Number");
    }
    const updatedPrimaryFields =
      primaryFields.label === "Mobile Number"
        ? { ...primaryFields, value }
        : primaryFields;
    setPrimaryFields(updatedPrimaryFields);

    const updatedAuxiliaryFields = auxFields.map((field) =>
      field.label === "Mobile Number" ? { ...field, value } : field
    );
    setAuxFields(updatedAuxiliaryFields);

    const updatedFormInputs = formInputs.map((field) =>
      field.label === "Mobile Number" ? { ...field, value } : field
    );
    setFormInputs(updatedFormInputs);

    const updatedsecondaryFields = secondaryFields.map((field) =>
      field.label === "Mobile Number" ? { ...field, value } : field
    );
    setSecondaryFields(updatedsecondaryFields);
    setActiveField(value);
    //

    setMobileNumber((prevFormData) => ({
      ...prevFormData,
      label: {
        ...prevFormData.label,
        value: value,
      },
    }));
  };
  const handleDisplayValueChange = (e) => {
    const value = e.target.value;
    const payload = { formId: 13, displayValue: value };
    dispatch(addPrimaryFormsData(payload));
    setMobileNumber((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: value,
      },
    }));
  };
  useEffect(() => {
    if (wallets === "Google Wallet") {
      setDataInGoogleStore();
    } else {
      if (isInSecondaryFields(secondaryFields, "Mobile Number")) {
        addOrUpdateSecondaryFormData(mobileNumber);
      } else if (isInAuxiliaryFields(auxFields, "Mobile Number")) {
        dispatch(addOrUpdateAuxiliaryField(mobileNumber));
      } else if (primaryFields?.label === "Mobile Number") {
        setPrimaryFormData(mobileNumber);
      }
    }
  }, [mobileNumber]);

  // Check where is the form landed

  //
  useEffect(() => {
    if (wallets === "Google Wallet") {
      const matchingElement = allRowsData
        .flatMap((rowData) => rowData)
        .find((element) => element?.id === 13);
      setDefaulDisplayValue(matchingElement?.displayValue?.value);
    } else {
      const matchingElement = primaryFormsData.find(
        (element) => element?.formId === 13
      );
      setDefaulDisplayValue(matchingElement?.displayValue);
    }
  }, []);
  // default display value
  useEffect(() => {
    setMobileNumber((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: defaulDisplayValue ? defaulDisplayValue : "",
      },
      label: {
        ...prevFormData.label,
        value: mobileNumberLabel,
      },
    }));
  }, [defaulDisplayValue, mobileNumberLabel]);
  //
  const setDataInGoogleStore = () => {
    if (isThisRowContainThisForm(firstRow, "Mobile Number")) {
      const payload = { formData: mobileNumber, row: 1 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(secondRow, "Mobile Number")) {
      const payload = { formData: mobileNumber, row: 2 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(thirdRow, "Mobile Number")) {
      const payload = { formData: mobileNumber, row: 3 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(fourthRow, "Mobile Number")) {
      const payload = { formData: mobileNumber, row: 4 };
      dispatch(storeGoogleFormsData(payload));
    }
  };
  //

  return (
    <>
      <div className="sidebar">
        <div className="action-area d-flex justify-content-between align-items-center">
          <span>Mobile Number</span>
          <div>
            <img
              src="/assets/images/delete.svg"
              alt="delete"
              className="delete"
              onClick={() => removeForm("Mobile Number", 13)}
            />
            <img
              src="/assets/images/close.svg"
              alt="close"
              className="close"
              onClick={() => setFormName("")}
            />
          </div>
        </div>
        <div className="form-area">
          <div className="mb-4">
            <label htmlFor="mobileNumber" className="form-label">
              Label
            </label>
            <input
              type="text"
              className="form-control"
              name="mobileNumber"
              id="mobileNumber"
              defaultValue={mobileNumberLabel}
              onChange={handleLabelChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="displayValue" className="form-label">
              Display Value
            </label>
            <input
              type="text"
              className="form-control"
              name="displayValue"
              id="displayValue"
              defaultValue={defaulDisplayValue}
              onChange={handleDisplayValueChange}
            />
            <span className="input-bottom">
              {" "}
              <FontAwesomeIcon icon={faCircleQuestion} /> Static text on the
              customer pass
            </span>
          </div>
          <div className="label-text">
            <label className="form-label">Field Key</label>
            <span className="input-bottom">person.mobileNumber</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileNumber;
