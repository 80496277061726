import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

function ConfirmModal({ showCard, setShowCardModal, cardName, setModalValue }) {

    const handleOnClick = (value) => {
        setModalValue(value);
        setShowCardModal(false);
    };
    
    return (
        <Modal size="md" show={showCard} onHide={() => setShowCardModal(false)} centered>
            <div className="pt-4 px-4 modal-heading">
                <h2>Confirm Design Change</h2>
                <p className='mt-3'>Are you sure you want to change your pass design? Your previous edits will be lost</p>
            </div>

            <Modal.Body>
                <div className="pb-4 buttons d-flex justify-content-between align-items-center flex-wrap">
                    <button type="button" onClick={()=>handleOnClick("cancel")}>Cancel</button>
                    <button type="submit" onClick={()=>handleOnClick("confirm")}>Confirm</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConfirmModal