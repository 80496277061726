import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useData } from "../../Context/WalletFormProvider";
import { WalletContext } from "../../Context/WalletProvider";
import { setDynamicLabel } from "../../Store/Slices/WalletSlice";
import { addPrimaryFormsData } from "../../Store/Slices/primaryFormsDataSlice";
import { addOrUpdateAuxiliaryField } from "../../Store/Slices/auxiliaryFormsDataSlice.jsx";
import { isInSecondaryFields } from "../../utils/ckeckIsInPrimaryField.jsx";
import { isInAuxiliaryFields } from "../../utils/chackIsInAuxiliaryField.jsx";
import { isThisRowContainThisForm } from "../../utils/checkRowFormName.jsx";
import { storeGoogleFormsData } from "../../Store/Slices/GoogleWalletData/allRowFormsData/googleWalletFormsData.jsx";
import { updateGoogleRowLabel } from "../../Store/Slices/GoogleWalletData/Fields/googleFieldsRow.jsx";

const Tier = () => {
  const {
    removeForm,
    setFormName,
    setActiveField,
    setPrimaryFields,
    primaryFields,
    auxFields,
    setAuxFields,
    setSecondaryFields,
    secondaryFields,
    wallets,
    formInputs,
    setFormInputs,
  } = useContext(WalletContext);
  const { firstRow, secondRow, thirdRow, fourthRow } = useSelector(
    (state) => state.googleWalletFieldsRow
  );
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const { primaryFormsData, googleWalletData } = useSelector((state) => state);
  const { firstRowData, secondRowData, thirdRowData, fourthRowData } =
    googleWalletData;
  const allRowsData = [
    firstRowData,
    secondRowData,
    thirdRowData,
    fourthRowData,
  ];
  const [defaulDisplayValue, setDefaulDisplayValue] = useState("");
  const { addOrUpdateSecondaryFormData, setPrimaryFormData } = useData();
  const [tier, setTier] = useState({
    id: 22,
    tierName: {
      label: "Tier Name",
      value: "Bronze",
    },
    label: {
      label: "Label",
      value: "Tier",
      baseValue: "Tier",
    },
    displayValue: {
      label: "Display Value",
      value: "",
    },
  });

  const handleTierNameChange = (e) => {
    const value = e.target.value;

    setTier((prevFormData) => ({
      ...prevFormData,
      tierName: {
        ...prevFormData.tierName,
        value: value,
      },
    }));
  };
  const handleLabelChange = (e) => {
    const value = e.target.value;
    const payload = { form: "Tier", value };
    dispatch(setDynamicLabel(payload));
    dispatch(updateGoogleRowLabel(payload));

    //
    if (!value) {
      setFormName("Tier");
      setActiveField("Tier");
    }
    const updatedPrimaryFields =
      primaryFields.label === "Tier"
        ? { ...primaryFields, value }
        : primaryFields;
    setPrimaryFields(updatedPrimaryFields);

    const updatedAuxiliaryFields = auxFields.map((field) =>
      field.label === "Tier" ? { ...field, value } : field
    );
    setAuxFields(updatedAuxiliaryFields);

    const updatedFormInputs = formInputs.map((field) =>
      field.label === "Tier" ? { ...field, value } : field
    );
    setFormInputs(updatedFormInputs);

    const updatedsecondaryFields = secondaryFields.map((field) =>
      field.label === "Tier" ? { ...field, value } : field
    );
    setSecondaryFields(updatedsecondaryFields);
    setActiveField(value);
    //

    setTier((prevFormData) => ({
      ...prevFormData,
      label: {
        ...prevFormData.label,
        value: value,
      },
    }));
  };
  const handledDisplayValueChange = (e) => {
    const value = e.target.value;
    const payload = { formId: 22, displayValue: value };
    dispatch(addPrimaryFormsData(payload));
    setTier((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: value,
      },
    }));
  };
  useEffect(() => {
    if (wallets === "Google Wallet") {
      setDataInGoogleStore();
    } else {
      if (isInSecondaryFields(secondaryFields, "Tier")) {
        addOrUpdateSecondaryFormData(tier);
      } else if (isInAuxiliaryFields(auxFields, "Tier")) {
        dispatch(addOrUpdateAuxiliaryField(tier));
      } else if (primaryFields?.label === "Tier") {
        setPrimaryFormData(tier);
      }
    }
  }, [tier]);

  useEffect(() => {
    if (wallets === "Google Wallet") {
      const matchingElement = allRowsData
        .flatMap((rowData) => rowData)
        .find((element) => element?.id === 22);
      setDefaulDisplayValue(matchingElement?.displayValue?.value);
    } else {
      const matchingElement = primaryFormsData.find(
        (element) => element?.formId === 22
      );
      setDefaulDisplayValue(matchingElement?.displayValue);
    }
  }, []);
  // default display value
  useEffect(() => {
    setTier((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: defaulDisplayValue ? defaulDisplayValue : "",
      },
      label: {
        ...prevFormData.label,
        value: wallet?.tier,
      },
    }));
  }, [defaulDisplayValue, wallet?.tier]);
  //
  const setDataInGoogleStore = () => {
    if (isThisRowContainThisForm(firstRow, "Tier")) {
      const payload = { formData: tier, row: 1 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(secondRow, "Tier")) {
      const payload = { formData: tier, row: 2 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(thirdRow, "Tier")) {
      const payload = { formData: tier, row: 3 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(fourthRow, "Tier")) {
      const payload = { formData: tier, row: 4 };
      dispatch(storeGoogleFormsData(payload));
    }
  };

  return (
    <>
      <div className="sidebar">
        <div className="action-area d-flex justify-content-between align-items-center">
          <span>Tier Settings (Apple and Google)</span>
          <div>
            <img
              src="/assets/images/delete.svg"
              alt="delete"
              className="delete"
              onClick={() => removeForm("Tier", 22)}
            />
            <img
              src="/assets/images/close.svg"
              alt="close"
              className="close"
              onClick={() => setFormName("")}
            />
          </div>
        </div>
        <div className="form-area">
          <div className="mb-4">
            <label htmlFor="tierName" className="form-label">
              Tier Name
            </label>
            <input
              type="text"
              className="form-control"
              name="tierName"
              id="tierName"
              defaultValue="bronze"
              onChange={handleTierNameChange}
            />
          </div>
          <div className="mb-3">
            <div className="hr"></div>
          </div>
          <div className="mb-4">
            <label htmlFor="tier" className="form-label">
              Label
            </label>
            <input
              type="text"
              className="form-control"
              name="tier"
              id="tier"
              defaultValue={wallet?.tier}
              onChange={handleLabelChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="displayValue" className="form-label">
              Display Value
            </label>
            <input
              type="text"
              className="form-control"
              name="displayValue"
              id="displayValue"
              defaultValue={defaulDisplayValue}
              onChange={handledDisplayValueChange}
            />
            <span className="input-bottom">
              {" "}
              <FontAwesomeIcon icon={faCircleQuestion} /> Static text on the
              customer pass
            </span>
          </div>
          <div className="label-text">
            <label className="form-label">Field Key</label>
            <span className="input-bottom">members.tier.name.displayName</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tier;
