import React, { useContext } from "react";

import DynamicField from "./Forms/DynamicField";
import Email from "./Forms/Email";
import LinkField from "./Forms/LinkField";
import Name from "./Forms/Name";
import Points from "./Forms/Points";
import Program from "./Forms/Program";
import StaticField from "./Forms/StaticField";
import Tier from "./Forms/Tier";
import TermsAndCondition from "./Forms/termsAndCondition";

import Birthday from "./Forms/Birthday";
import ExpiryDate from "./Forms/ExpiryDate";
import ExternalID from "./Forms/ExternalID";
import FirstName from "./Forms/FirstName";
import Gender from "./Forms/Gender";
import GroupingIdentifier from "./Forms/GroupingIdentifier";
import LastName from "./Forms/LastName";
import Legal from "./Forms/Legal";
import MemberStatus from "./Forms/MemberStatus";
import MobileNumber from "./Forms/MobileNumber";
import OptIn from "./Forms/OptIn";
import OptOut from "./Forms/OptOut";
import Salutation from "./Forms/Salutation";
import SecondaryPoints from "./Forms/SecondaryPoints";
import Suffix from "./Forms/Suffix";
import TierPoints from "./Forms/TierPoints";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useData } from "../Context/WalletFormProvider";
import { WalletContext } from "../Context/WalletProvider";
import { setActiveMenu } from "../Store/Slices/activeMenuSlice";
import Organization from "./Forms/Organization";
import AddItem from "./Layouts/Sidebars/AddItem";
import Barcode from "./Layouts/Sidebars/Barcode";
import Location from "./Layouts/Sidebars/Location";
import Password from "./Layouts/Sidebars/Password";
import Theme from "./Layouts/Sidebars/Theme";
import WalletHeader from "./WalletHeader";
import AppleWallet from "./Wallets/AppleWallet";
import AppleWalletMembership from "./Wallets/AppleWalletMembership";
import DataCollection from "./Wallets/DataCollection";
import GoogleWallet from "./Wallets/GoogleWallet";
const Wallet = () => {
  const { formName, setFormName, wallets, setWallets, activeCardName } =
    useContext(WalletContext);

  const [barcode, setBarcode] = useState("qr");
  const wallet = useSelector((state) => state.wallet);
  const [uesrMenuWallet, setUesrMenuWallet] = useState("StoreCard");

  const { newFieldLabel } = useData();
  const { activeMenu } = useSelector((state) => state.hederMenuAcitve);
  const dispatch = useDispatch();
  const handleOnClickNavMenu = (menuName) => {
    dispatch(setActiveMenu(menuName));
  };
  const handleFormName = (child) => {
    // setFormName(child);
  };
  const handleOnClickWallets = (e) => {
    const title = e.target.title;
    if (title === "Google Wallet") {
      setWallets("Google Wallet");
    } else if (title === "Apple Wallet") {
      setWallets("Apple Wallet");
    } else if (title === "Data Collection") {
      setWallets("Data Collection");
    }
  };
  const handleUserMenu = (e) => {
    setUesrMenuWallet(e);
  };
  const handleBarcode = (child) => {
    setBarcode(child);
  };
  const renderComponent = (staticText, walletProperty, component) => {
    return (
      (formName === staticText ||
        (wallet && formName === wallet[walletProperty])) &&
      component
    );
  };
  return (
    <>
      <WalletHeader
        userMenu={handleUserMenu}
        setUesrMenuWallet={setUesrMenuWallet}
      />
      <nav className="main-nav">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-3 col-3">
              <div className="nav-left">
                <div className="nav-icons d-flex justify-content-start align-items-center">
                  <div
                    className={`single-icon ${activeMenu === "Add item" ? "focus" : ""
                      }`}
                    onClick={() => handleOnClickNavMenu("Add item")}
                  >
                    <img
                      src="/assets/images/add-item.svg"
                      alt="add-item"
                      title="Add item"
                    />
                  </div>
                  <div
                    className={`single-icon ${activeMenu === "Theme" ? "focus" : ""
                      }`}
                    onClick={() => handleOnClickNavMenu("Theme")}
                  >
                    <img
                      src="/assets/images/theme.svg"
                      alt="theme"
                      title="Theme"
                    />
                  </div>
                  <div
                    className={`single-icon ${activeMenu === "Barcode" ? "focus" : ""
                      }`}
                    onClick={() => handleOnClickNavMenu("Barcode")}
                  >
                    <img
                      src="/assets/images/barcode.svg"
                      alt="barcode"
                      title="Barcode"
                    />
                  </div>
                  {/* <div
                    className={`single-icon ${
                      activeMenu  === "Location" ? "focus" : ""
                    }`}
                  >
                    <img
                      src="/assets/images/location.svg"
                      alt="location"
                      title="Location"
                      onClick={handleOnClickNavMenu}
                    />
                  </div>
                  <div
                    className={`single-icon ${
                      activeMenu  === "Password" ? "focus" : ""
                    }`}
                  >
                    <img
                      src="/assets/images/password.svg"
                      alt="password"
                      title="Password"
                      onClick={handleOnClickNavMenu}
                    />
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-6">
              <div className="nav-middle">
                <ul className="d-flex justify-content-center align-items-center">
                  <li
                    title="Google Wallet"
                    onClick={handleOnClickWallets}
                    className={
                      wallets === "Google Wallet" ? "click-wallet" : ""
                    }
                  >
                    Google Wallet
                  </li>
                  <li
                    title="Apple Wallet"
                    onClick={handleOnClickWallets}
                    className={wallets === "Apple Wallet" ? "click-wallet" : ""}
                  >
                    Apple Wallet
                  </li>
                  {/* <li
                    title="Data Collection"
                    onClick={handleOnClickWallets}
                    className={
                      wallets === "Data Collection" ? "click-wallet" : ""
                    }
                  >
                    Data Collection
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="wallet-wrapper">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-3 col-md-3 col-3">
              {activeMenu === "Add item" && <AddItem coupon={uesrMenuWallet} />}
              {activeMenu === "Theme" && <Theme formName={handleFormName} />}
              {activeMenu === "Barcode" && (
                <Barcode formName={handleFormName} barcode={handleBarcode} />
              )}{" "}
              {/* BARCODE COMPONENT */}
              {activeMenu === "Location" && (
                <Location formName={handleFormName} />
              )}
              {activeMenu === "Password" && (
                <Password formName={handleFormName} />
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-6">
              <div className="wallet-body">
                {(activeCardName === "StoreCard" ||
                  activeCardName === "GenericPass" ||
                  activeCardName === "Coupon") &&
                  wallets === "Google Wallet" && (
                    <GoogleWallet
                      getBarcode={barcode}
                      formName={handleFormName}
                    />
                  )}
                {/* {uesrMenuWallet === "GenericPass" &&
                  wallets === "Google Wallet" && (
                    <GoogleWalletMembership
                      getBarcode={barcode}
                      formName={handleFormName}
                    />
                  )}
                {uesrMenuWallet === "Coupon" && wallets === "Google Wallet" && (
                  <GoogleWalletCoupon
                    getBarcode={barcode}
                    formName={handleFormName}
                  />
                )} */}
                {activeCardName === "StoreCard" &&
                  wallets === "Apple Wallet" && (
                    <AppleWallet
                      // getBarcode={barcode}
                      cardName={uesrMenuWallet}
                    />
                  )}
                {activeCardName === "Coupon" && wallets === "Apple Wallet" && (
                  <AppleWallet getBarcode={barcode} cardName={uesrMenuWallet} />
                )}
                {activeCardName === "GenericPass" &&
                  wallets === "Apple Wallet" && (
                    <AppleWalletMembership
                      getBarcode={barcode}
                      cardName={uesrMenuWallet}
                    />
                  )}
                {wallets === "Data Collection" && <DataCollection />}
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-3">
              {newFieldLabel && <DynamicField />}

              {!newFieldLabel &&
                <>
                  {renderComponent("Points", "points", <Points />)}
                  {renderComponent("Program", "program", <Program />)}
                  {renderComponent("Name", "name", <Name />)}
                  {renderComponent("Tier", "tier", <Tier />)}
                  {renderComponent(
                    "Information",
                    "information",
                    <TermsAndCondition />
                  )}
                  {renderComponent("Link", "link", <LinkField />)}
                  {/* {renderComponent(
                  "New Field",
                  "newDynamicField",
                  <DynamicField />
                )} */}
                  {renderComponent(
                    "New Static Field",
                    "newStaticField",
                    <StaticField />
                  )}
                  {renderComponent("Email", "email", <Email />)}
                  {renderComponent("Expiry Date", "expiryDate", <ExpiryDate />)}

                  {renderComponent(
                    "Mobile Number",
                    "mobileNumber",
                    <MobileNumber />
                  )}
                  {renderComponent("First Name", "firstName", <FirstName />)}
                  {renderComponent("Last Name", "lastName", <LastName />)}
                  {renderComponent("Salutation", "salutation", <Salutation />)}
                  {renderComponent("Suffix", "suffix", <Suffix />)}
                  {renderComponent("Gender", "gender", <Gender />)}
                  {renderComponent("Date of Birth", "dateOfBirth", <Birthday />)}
                  {renderComponent("Legal", "legal", <Legal />)}
                  {renderComponent("Opt in", "optIn", <OptIn />)}
                  {renderComponent("Opt out", "optOut", <OptOut />)}
                  {renderComponent("External ID", "externalID", <ExternalID />)}
                  {renderComponent("Tier Points", "tierPoints", <TierPoints />)}
                  {renderComponent(
                    "Secondary Points",
                    "secondaryPoints",
                    <SecondaryPoints />
                  )}
                  {renderComponent(
                    "Member Status",
                    "memberStatus",
                    <MemberStatus />
                  )}
                  {renderComponent(
                    "Grouping Identifier",
                    "groupingIdentifier",
                    <GroupingIdentifier />
                  )}
                  {renderComponent(
                    "Organization",
                    "organization",
                    <Organization />
                  )}
                </>
              }

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
