import React from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  return (
    <>
      <div className="container signin-page">
        <div className="row signin-signout-page">
          <div className="col-lg-8">
            <div className="signin-signout-page-left">
              <img src='/assets/images/forgotpassword-bg.svg' alt="fogotpassword-bg"/>
            </div>
          </div>
          <div className="col-lg-4 bg-white">
            <div className="signin-signout-page-right">
              <h2>Welcome back</h2>
              <p>Welcome back! Please enter your details.</p>
              <h3 className="fw-bold">Forgot Password</h3>
              <p>Enter email address associated with your account and we will send you a link to reset your password.</p>
              <div className="main-form">
                <form>
                  <div className="mb-4">
                    <Form.Label htmlFor="email">Email address</Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      placeholder="jean@jrs.com"
                    />
                  </div>
                  <div className="mb-4">
                    <Button variant="primary" type="submit">Request Password Reset</Button>
                  </div>
                </form>
              </div>
              <p className="mb-5">Already have an account? <Link to="/signin">Login instead</Link></p>
              <img src='/assets/images/keos-logo-light.svg' alt="keos-logo" className="keos-logo"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;