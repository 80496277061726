import { createSlice } from "@reduxjs/toolkit";

const initialState = ["Email"];

const addPresentSlice = createSlice({
  name: "addElementInThisPass",
  initialState,
  reducers: {
    addElement: (state, action) => {
      const elementToAdd = action.payload;
      return [...state, elementToAdd];
    },
    removePresentFieldElement: (state, action) => {
      const elementToRemove = action.payload;
      return state.filter((element) => element !== elementToRemove);
    },
    resetPresentFields: (state) => {
      return initialState;
    },
  },
});

export const { addElement, removePresentFieldElement, resetPresentFields } =
  addPresentSlice.actions;
export default addPresentSlice.reducer;
