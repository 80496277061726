import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

const Register = () => {

  const navigate = useNavigate();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [data, setData] = useState(
    {
      name: name,
      email: email,
      password: password,
    }
  );
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    // console.log('DATA', data);
  }, [data]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  }
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }

  const registerUser = async (e) => {
    e.preventDefault();

    const requestData = {
      name: name,
      email: email,
      password: password,
    };

    setData(requestData);
    setLoading(true);

    const url = `${process.env.REACT_APP_API_ENDPOINT}register`;
    try {
      const response = await axios.post(url, requestData);
      navigate('/signin');
      toast.success('Successfully Registered')
      setLoading(false);

    } catch (error) {
      console.error('Error:', error);
      toast.error('Registration faild')
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  const handlePasswordViewOnClick = () => {
    setPasswordVisible(!passwordVisible);
  }

  return (
    <>
      <div className="container signout-page">
        <div className="row signin-signout-page">
          <div className="col-lg-8">
            <div className="signin-signout-page-left">
              <img src='/assets/images/signout-bg.svg' alt="signout-bg" />
            </div>
          </div>
          <div className="col-lg-4 bg-white">
            <div className="signin-signout-page-right">
              <h2>Start your 14-day free trial</h2>
              <p><strong>No credit card required.</strong> Try all Pro feature for 14 days. Upgrade at anytime to <strong>Pro</strong> for <strong>$() per month.</strong></p>
              <div className="main-form">
                <form onSubmit={registerUser}>
                  <div className="mb-4">
                    <Form.Label htmlFor="name">Name</Form.Label>
                    <Form.Control
                      type="text"
                      id="name"
                      placeholder="John Doe"
                      onChange={handleNameChange}
                    />
                  </div>
                  <div className="mb-4">
                    <Form.Label htmlFor="email">Email address</Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      placeholder="jean@jrs.com"
                      onChange={handleEmailChange}
                    />
                  </div>
                  <div className="mb-4 position-relative">
                    <Form.Label htmlFor="password">Password</Form.Label>
                    <Form.Control
                      type={passwordVisible ? 'text' : 'password'}
                      id="password"
                      onChange={handlePasswordChange}
                    />
                    <div className="pass-view" onClick={handlePasswordViewOnClick}>
                      {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </div>
                  </div>
                  <div className="mb-4">
                    <Button variant="primary" type="submit" disabled={loading}>
                      {loading ? 'Loading...' : 'Submit'}
                    </Button>
                  </div>
                </form>
              </div>
              <span>Or continue with</span>
              {/* <div className="d-flex justify-content-between align-items-center mb-4">
                <button className="d-flex justify-content-center align-items-center"> <img src='/assets/images/google.svg' alt="google" /> Sign in wiith Google</button>
                <button className="d-flex justify-content-center align-items-center"> <img src='/assets/images/sso.svg' alt="google" /> Sign in wiith Google</button>
              </div> */}
              <p className="mb-5">By signin up, you agree to our <strong>Terms of service</strong> and <strong>Privacy Policy</strong>. Need help? <strong>Get in touch.</strong></p>
              <img src='/assets/images/keos-logo-light.svg' alt="keos-logo" className="keos-logo" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;