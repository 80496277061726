import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useData } from "../../Context/WalletFormProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MaintenanceModal from "../Modals/MaintenanceModal";
import Dropdown from 'react-bootstrap/Dropdown';
import { FaBell, FaEnvelope } from "react-icons/fa6";

const Header = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const { secondaryFormsData } = useData();
  const [pkPass, setPkPass] = useState();
  const walletData = [...secondaryFormsData, { userId: userInfo?.id }];
  const randomSixDigitNumber = Math.floor(100000 + Math.random() * 900000);
  const [showMaintenanceModal, setShowMaintenanceModal] = useState(false);

  const handlePostPrimaryData = async (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_API_ENDPOINT}generate-pass`;
    try {
      if (secondaryFormsData.length === 0) {
        return;
      }
      const token = JSON.parse(localStorage.getItem("accessToken"));
      const response = await axios.post(url, walletData, {
        responseType: "arraybuffer", // Ensure the response is treated as binary data
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Handle the response
      const blob = new Blob([response.data], {
        type: "application/vnd.apple.pkpass",
      });

      // Trigger download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `pass_${randomSixDigitNumber}.pkpass`;
      link.click();

      setPkPass(response.data); // You might not need this line if it's just binary data
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [customClass, setCustomClass] = useState(false);
  const [wallets, setWallets] = useState("Apple Wallet");

  const handleOnClick = (e) => {
    setWallets(e.target.textContent);
  };

  const handleOnClickClose = () => {
    setShowModal(false);
  };
  const handleUserMenu = () => {
    setCustomClass((prevState) => !prevState);
  };

  const handleSignOut = () => {
    localStorage.clear()
    navigate("/signin");
  };
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    setUserInfo(userData);
  }, []);

  const underMaintenance = () => {
    setShowMaintenanceModal(true);
  }

  return (
    <>
      <header>
        {/* HEADER TOP */}
        <div className="header-top">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                <Link to="/">
                  <img
                    src="/assets/images/logo.svg"
                    alt="logo"
                    className="site-logo"
                  />
                </Link>
              </div>
              <div className="col-lg-9 col-md-9 col-sm-9 col-9">

                {userInfo !== null ?
                  <>
                    <div className="top-right d-flex justify-content-end align-items-center">
                      <div className="icons d-flex justify-content-start align-items-center">

                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <div className="icon-single">
                              <img src="/assets/images/envelop.svg" alt="envelop" />
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <div className="dd-list">
                                <p> <FaEnvelope /> Demo message</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaEnvelope /> Demo message</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaEnvelope /> Demo message</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaEnvelope /> Demo message</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaEnvelope /> Demo message</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaEnvelope /> Demo message</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaEnvelope /> Demo message</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaEnvelope /> Demo message</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaEnvelope /> Demo message</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaEnvelope /> Demo message</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaEnvelope /> Demo message</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaEnvelope /> Demo message</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaEnvelope /> Demo message</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaEnvelope /> Demo message</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaEnvelope /> Demo message</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaEnvelope /> Demo message</p>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <div className="icon-single">
                              <img src="/assets/images/bell.svg" alt="bell" />
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <div className="dd-list">
                                <p> <FaBell /> [Demo] Remove the PassKit legal disclaimer from the back of your pass.</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaBell /> [Demo] Remove the PassKit legal disclaimer from the back of your pass.</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaBell /> [Demo] Remove the PassKit legal disclaimer from the back of your pass.</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaBell /> [Demo] Remove the PassKit legal disclaimer from the back of your pass.</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaBell /> [Demo] Remove the PassKit legal disclaimer from the back of your pass.</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaBell /> [Demo] Remove the PassKit legal disclaimer from the back of your pass.</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaBell /> [Demo] Remove the PassKit legal disclaimer from the back of your pass.</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaBell /> [Demo] Remove the PassKit legal disclaimer from the back of your pass.</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaBell /> [Demo] Remove the PassKit legal disclaimer from the back of your pass.</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaBell /> [Demo] Remove the PassKit legal disclaimer from the back of your pass.</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaBell /> [Demo] Remove the PassKit legal disclaimer from the back of your pass.</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaBell /> [Demo] Remove the PassKit legal disclaimer from the back of your pass.</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaBell /> [Demo] Remove the PassKit legal disclaimer from the back of your pass.</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaBell /> [Demo] Remove the PassKit legal disclaimer from the back of your pass.</p>
                              </div>
                              <div className="dd-list">
                                <p> <FaBell /> [Demo] Remove the PassKit legal disclaimer from the back of your pass.</p>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>

                      </div>
                      <div
                        className="user-info d-flex justify-content-start align-items-center"
                        onClick={handleUserMenu}
                      >
                        <img src="/assets/images/avatar.jpg" alt="bell" />
                        <div className="info position-relative">
                          <p>
                            Hi, <strong>{userInfo?.name}</strong>
                          </p>
                          <span>Administrator</span>
                          <img src="/assets/images/arrow.svg" alt="arrow" />
                          <ul
                            className={`userMenu ${customClass == true ? "show" : ""
                              }`}
                          >
                            {/* <li>Profile</li>
                                                <li>Settings</li> */}
                            <li onClick={handleSignOut}>Signout</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>

                  : <>
                    <div className="top-right buttons d-flex justify-content-end align-items-center">
                      <Link to={'/signin'}>
                        <p>login</p>
                      </Link>
                      <Link to={'/register'}>
                        <p>register</p>
                      </Link>
                    </div>
                  </>}

              </div>
            </div>
          </div>
        </div>

        {/* Modal */}
        <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
          <div className="d-flex justify-content-between align-items-center px-4 pt-4">
            <img
              src="/assets/images/gear.svg"
              alt="close-btn"
              className="gear-btn"
            />
            <img
              src="/assets/images/close.svg"
              alt="close-btn"
              className="share-btn"
              onClick={handleOnClickClose}
            />
          </div>
          <div className="px-4 modal-heading">
            <h2>Settings</h2>
            <p>Need wording to describe this task.</p>
          </div>

          <Modal.Body>
            <div className="wallet-body">
              <ul className="settings-tab d-flex justify-content-center align-items-center">
                <li
                  className={`${wallets === "General" ? "tab-active" : ""}`}
                  onClick={handleOnClick}
                >
                  General
                </li>
                <li
                  className={`${wallets === "Google Wallet" ? "tab-active" : ""
                    }`}
                  onClick={handleOnClick}
                >
                  Google Wallet
                </li>
                <li
                  className={`${wallets === "Apple Wallet" ? "tab-active" : ""
                    }`}
                  onClick={handleOnClick}
                >
                  Apple Wallet
                </li>
              </ul>
              <div className="wallets tab">
                {wallets === "General" && (
                  <>
                    <div className="form-area">
                      <div className="mb-4">
                        <label htmlFor="templateName" className="form-label">
                          Template Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="templateName"
                          id="templateName"
                          placeholder="Design Name"
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="description" className="form-label">
                          Description
                        </label>
                        <textarea
                          className="form-control"
                          id="description"
                          rows="3"
                          placeholder="Your special card"
                        ></textarea>
                      </div>
                      <div className="mb-4">
                        <label htmlFor="timezone" className="form-label">
                          Timezone
                        </label>
                        <select className="form-select" id="timezone">
                          <option value="Western Indonesia Time - Jakarta(GMT=+7)">
                            Western Indonesia Time - Jakarta (GMT=+7)
                          </option>
                          <option value="Western Indonesia Time - Jakarta(GMT=+8)">
                            Western Indonesia Time - Jakarta (GMT=+8)
                          </option>
                          <option value="Western Indonesia Time - Jakarta(GMT=+9)">
                            Western Indonesia Time - Jakarta (GMT=+9)
                          </option>
                        </select>
                      </div>
                      <div className="mb-4">
                        <label htmlFor="language" className="form-label">
                          Default Language
                        </label>
                        <select className="form-select" id="language">
                          <option value="English">English</option>
                          <option value="Bangla">Bangla</option>
                        </select>
                      </div>
                      <div className="mb-4">
                        <div className="hr"></div>
                      </div>
                      <div className="mb-4 buttons d-flex justify-content-between align-items-center flex-wrap">
                        <button type="submit">Cancel</button>
                        <button type="submit">Save</button>
                      </div>
                    </div>
                  </>
                )}
                {wallets === "Google Wallet" && (
                  <>
                    <div className="form-area">
                      <div className="mb-4">
                        <label
                          htmlFor="organizationName"
                          className="form-label"
                        >
                          Organization Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="organizationName"
                          id="organizationName"
                          placeholder="Design Name"
                        />
                      </div>
                      <div className="mb-4">
                        <label className="form-label">
                          Default Android Wallet Type
                        </label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="googlePay"
                            id="googlePay"
                          />
                          <label
                            className="form-check-label fw-normal"
                            for="googlePay"
                          >
                            Google Pay
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="alternativePay"
                            id="alternativePay"
                          />
                          <label
                            className="form-check-label fw-normal"
                            for="alternativePay"
                          >
                            Alternative Pay
                          </label>
                        </div>
                      </div>
                      <div className="mb-4">
                        <label className="form-label">
                          Alternative Wallet Provider
                        </label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="walletPass"
                            id="walletPass"
                          />
                          <label
                            className="form-check-label fw-normal"
                            for="walletPass"
                          >
                            Wallet Pass
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="passWallet"
                            id="passWallet"
                          />
                          <label
                            className="form-check-label fw-normal"
                            for="passWallet"
                          >
                            PassWallet
                          </label>
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="hr"></div>
                      </div>
                      <div className="mb-4 buttons d-flex justify-content-between align-items-center flex-wrap">
                        <button type="submit">Cancel</button>
                        <button type="submit">Save</button>
                      </div>
                    </div>
                  </>
                )}
                {wallets === "Apple Wallet" && (
                  <>
                    <div className="form-area">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="allowSharing"
                        />
                        <label className="form-check-label" for="allowSharing">
                          Allow Sharing
                        </label>
                      </div>
                      <div className="mb-4">
                        <label htmlFor="sharingUrl" className="form-label">
                          Sharing URL
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="sharingUrl"
                          id="sharingUrl"
                        />
                      </div>
                      <div className="mb-4">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="passPersonalization"
                            id="passPersonalization"
                          />
                          <label
                            className="form-check-label fw-normal"
                            for="passPersonalization"
                          >
                            Enable Pass Personalization
                          </label>
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="hr"></div>
                      </div>
                      <div className="mb-4 buttons d-flex justify-content-between align-items-center flex-wrap">
                        <button type="submit">Cancel</button>
                        <button type="submit">Save</button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </header>
      <MaintenanceModal showMaintenanceModal={showMaintenanceModal} setShowMaintenanceModal={setShowMaintenanceModal} />
    </>
  );
};

export default Header;
