import React, { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addElement } from "../Store/Slices/AddPresentFieldsSlice";
import {
  addOrUpdateRowData,
  removeItemFromRow,
  resetGoogleRowState,
} from "../Store/Slices/GoogleWalletData/Fields/googleFieldsRow";
import {
  removeGoogleFormsData,
  resetGoogleFormsState,
} from "../Store/Slices/GoogleWalletData/allRowFormsData/googleWalletFormsData";
import { removeAuxiliaryField } from "../Store/Slices/auxiliaryFormsDataSlice";
import { removeBackField } from "../Store/Slices/backFieldsFormsDataSlice";
import {
  resetPassDetails,
  setPassThemeInfo,
} from "../Store/Slices/passDetailsSlice";
import { removeObjectsById } from "../utils/RemoveObjectsFromPrimaryFormData";
import { useData } from "./WalletFormProvider";
import { resetPrimaryFormsData } from "../Store/Slices/primaryFormsDataSlice";
import { resetFormsLabels } from "../Store/Slices/WalletSlice";
export const WalletContext = createContext();

const WalletProvider = ({ children }) => {
  const [activeField, setActiveField] = useState("");
  const [wallets, setWallets] = useState("Apple Wallet");
  const [formName, setFormName] = useState("");
  const [passOptions, setPassOptions] = useState(["Points"]);
  const [auxFields, setAuxFields] = useState([]);
  const [couponColor, setCouponColor] = useState("rgb(138, 120, 101)");
  const {
    secondaryFormsData,
    setSecondaryFormsData,
    setHeaderFields,
    newFieldLabel,
    setNewFieldLabel,
    setPrimaryFormData,
  } = useData();
  const [secondaryFields, setSecondaryFields] = useState([]);
  const [formInputs, setFormInputs] = useState([
    { label: "Information", value: "Information" },
  ]);
  const [activeCardName, setActiveCardName] = useState("");
  const dispatch = useDispatch();
  //
  const [primaryFields, setPrimaryFields] = useState({});
  const [backFieldsText, setBackFieldsText] = useState([]);
  console.log("🚀 ~ WalletProvider ~ backFieldsText:", backFieldsText);
  const [membershipAppleWalletBgColor, setMembershipAppleWalletBgColor] =
    useState("rgb(248,212,25)");
  //
  const removeForm = (formName, formId) => {
    // remove from google row
    dispatch(removeItemFromRow(formName));
    dispatch(removeGoogleFormsData(formId));

    dispatch(addElement(formName));
    // Use filter to create a new array without the matching element
    const updatedOptions = passOptions.filter((option) => option !== formName);
    setPassOptions(updatedOptions);
    // Use filter secondaryFields
    const updatedFields = secondaryFields.filter(
      (field) => field.label !== formName
    );
    setSecondaryFields(updatedFields);
    // Use filter auxiliaryFields
    const updatedAuxFields = auxFields.filter(
      (field) => field.label !== formName
    );
    setAuxFields(updatedAuxFields);
    setFormName("");

    // Use filter primaryFields
    setFormName("");
    // Use filter secondaryFields
    const updatedFormInputs = formInputs.filter(
      (option) => option.label !== formName
    );
    setFormInputs(updatedFormInputs);
    // Dispatch the removeBackField action with the formId
    dispatch(removeBackField(formId));
    dispatch(removeAuxiliaryField(formId));
    // Remove from secondary
    const updateSecondaryFormsData = removeObjectsById(
      secondaryFormsData,
      formId
    );
    setSecondaryFormsData(updateSecondaryFormsData); //
    if (formName === "Points" || formName === "Expiry Date") {
      setHeaderFields({});
    }
    if (formName === newFieldLabel) {
      setNewFieldLabel("");
    }
    if (formName === primaryFields.label) {
      setPrimaryFields({});
      setPrimaryFormData({});
    }
    // if (formName === primaryFormData.label) {
    //   setPrimaryFormData({});
    // }
  };
  //
  useEffect(() => {
    dispatch(resetGoogleFormsState());
    dispatch(resetGoogleRowState());
    if (wallets === "Google Wallet") {
      dispatch(resetPassDetails());
      dispatch(setPassThemeInfo({ formate: "QR_CODE" }));
    } else {
      // dispatch(resetPassDetails());
      // dispatch(setPassThemeInfo({ formate: "PKBarcodeFormatQR" }));
    }
    // setFormName("");
    setPrimaryFormData({});
    dispatch(resetFormsLabels());

    if( activeCardName === "Coupon" ){
      dispatch(setPassThemeInfo({ color: couponColor ? couponColor : "rgb(138, 120, 101)" }));

    }
    if( activeCardName === "GenericPass" ){
      dispatch(setPassThemeInfo({ color: membershipAppleWalletBgColor ? membershipAppleWalletBgColor : "rgb(248, 212, 25)" }));

    }
  }, [wallets, activeCardName]);
  //
  useEffect(() => {
    if (wallets === "Google Wallet") {
      if (activeCardName === "StoreCard" || activeCardName === "GenericPass") {
        dispatch(
          addOrUpdateRowData({ label: "Points", value: "Points", row: 1 })
        );
      }
    }
  }, [activeCardName, wallets]);
  //


  const walletInfo = {
    passOptions,
    setPassOptions,
    formName,
    setFormName,
    activeField,
    setActiveField,
    primaryFields,
    setPrimaryFields,
    auxFields,
    setAuxFields,
    secondaryFields,
    setSecondaryFields,
    formInputs,
    setFormInputs,
    removeForm,
    activeCardName,
    setActiveCardName,
    couponColor,
    setCouponColor,
    membershipAppleWalletBgColor,
    setMembershipAppleWalletBgColor,
    wallets,
    setWallets,
    backFieldsText, 
    setBackFieldsText,
  };
  return (
    <WalletContext.Provider value={walletInfo}>
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
