import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const Password = (props) => {

    const [data, setData] = useState("");

    const handleOnClick = (e) => {
        setData(e.target.textContent);
    }
    
    props.formName(data);

    return(
        <>
            <div className='sidebar'>
                <div className='action-area d-flex justify-content-between align-items-center'>
                    <span>Password Protection</span>
                    <img src='/assets/images/close.svg' alt="close" className='close'/>
                </div>
                <div className='sidebar-password'>
                    <form>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="enable-password-protection"
                            label="Enable Password Protection"
                            className='mb-3'
                        />
                        <h5>set password</h5>
                        <input type="password" placeholder='Set your password here'/>
                        <Form.Check type="radio" className='mb-3' id='auto-logout' aria-label="radio 1" label="Auto logout after form submission" />
                        <button type='button'> <FontAwesomeIcon icon={faEdit} /> Edit Password Page</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Password;