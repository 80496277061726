import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useData } from "../../Context/WalletFormProvider";
import { WalletContext } from "../../Context/WalletProvider";
import { setDynamicLabel } from "../../Store/Slices/WalletSlice";
import { addPrimaryFormsData } from "../../Store/Slices/primaryFormsDataSlice";
import { addOrUpdateAuxiliaryField } from "../../Store/Slices/auxiliaryFormsDataSlice.jsx";
import { isInSecondaryFields } from "../../utils/ckeckIsInPrimaryField.jsx";
import { isInAuxiliaryFields } from "../../utils/chackIsInAuxiliaryField.jsx";
import { isThisRowContainThisForm } from "../../utils/checkRowFormName.jsx";
import { storeGoogleFormsData } from "../../Store/Slices/GoogleWalletData/allRowFormsData/googleWalletFormsData.jsx";
import { updateGoogleRowLabel } from "../../Store/Slices/GoogleWalletData/Fields/googleFieldsRow.jsx";

const Name = () => {
  const {
    removeForm,
    setFormName,
    setActiveField,
    setPrimaryFields,
    primaryFields,
    auxFields,
    setAuxFields,
    setSecondaryFields,
    secondaryFields,
    wallets,
    formInputs,
    setFormInputs,
  } = useContext(WalletContext);
  const { firstRow, secondRow, thirdRow, fourthRow } = useSelector(
    (state) => state.googleWalletFieldsRow
  );
  const dispatch = useDispatch();
  const { wallet, googleWalletData } = useSelector((state) => state);
  const primaryFormsData = useSelector((state) => state.primaryFormsData);
  const { firstRowData, secondRowData, thirdRowData, fourthRowData } =
    googleWalletData;
  const allRowsData = [
    firstRowData,
    secondRowData,
    thirdRowData,
    fourthRowData,
  ];
  const [defaulDisplayValue, setDefaulDisplayValue] = useState("");
  const { addOrUpdateSecondaryFormData, setPrimaryFormData } = useData();
  const [name, setName] = useState({
    id: 14,
    label: {
      label: "Label",
      value: "Name",
      baseValue: "Name",
    },
    displayValue: {
      label: "Display Value",
      value: "",
    },
  });

  const handleLabelChange = (e) => {
    const value = e.target.value;
    const payload = { form: "Name", value };
    dispatch(setDynamicLabel(payload));
    dispatch(updateGoogleRowLabel(payload));

    //
    if (!value) {
      setFormName("Name");
      setActiveField("Name");
    }
    const updatedPrimaryFields =
      primaryFields.label === "Name"
        ? { ...primaryFields, value }
        : primaryFields;
    setPrimaryFields(updatedPrimaryFields);

    const updatedAuxiliaryFields = auxFields.map((field) =>
      field.label === "Name" ? { ...field, value } : field
    );
    setAuxFields(updatedAuxiliaryFields);

    const updatedFormInputs = formInputs.map((field) =>
      field.label === "Name" ? { ...field, value } : field
    );
    setFormInputs(updatedFormInputs);

    const updatedsecondaryFields = secondaryFields.map((field) =>
      field.label === "Name" ? { ...field, value } : field
    );
    setSecondaryFields(updatedsecondaryFields);
    setActiveField(value);
    //

    setName((prevFormData) => ({
      ...prevFormData,
      label: {
        ...prevFormData.label,
        value: value,
      },
    }));
  };
  const handleDisplayValueChange = (e) => {
    const value = e.target.value;
    const payload = { formId: 14, displayValue: value };
    dispatch(addPrimaryFormsData(payload));
    setName((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: value,
      },
    }));
  };
  useEffect(() => {
    if (wallets === "Google Wallet") {
      setDataInGoogleStore();
    } else {
      if (isInSecondaryFields(secondaryFields, "Name")) {
        addOrUpdateSecondaryFormData(name);
      } else if (isInAuxiliaryFields(auxFields, "Name")) {
        dispatch(addOrUpdateAuxiliaryField(name));
      } else if (primaryFields?.label === "Name") {
        setPrimaryFormData(name);
      }
    }
  }, [name]);

  useEffect(() => {
    if (wallets === "Google Wallet") {
      const matchingElement = allRowsData
        .flatMap((rowData) => rowData)
        .find((element) => element?.id === 14);
      setDefaulDisplayValue(matchingElement?.displayValue?.value);
    } else {
      const matchingElement = primaryFormsData.find(
        (element) => element?.formId === 14
      );
      setDefaulDisplayValue(matchingElement?.displayValue);
    }
  }, []);
  // default display value
  useEffect(() => {
    setName((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: defaulDisplayValue ? defaulDisplayValue : "",
      },
      label: {
        ...prevFormData.label,
        value: wallet?.name,
      },
    }));
  }, [defaulDisplayValue, wallet?.name]);
  //
  const setDataInGoogleStore = () => {
    if (isThisRowContainThisForm(firstRow, "Name")) {
      const payload = { formData: name, row: 1 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(secondRow, "Name")) {
      const payload = { formData: name, row: 2 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(thirdRow, "Name")) {
      const payload = { formData: name, row: 3 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(fourthRow, "Name")) {
      const payload = { formData: name, row: 4 };
      dispatch(storeGoogleFormsData(payload));
    }
  };

  return (
    <>
      <div className="sidebar">
        <div className="action-area d-flex justify-content-between align-items-center">
          <span>Display Name</span>
          <div>
            <img
              src="/assets/images/delete.svg"
              alt="delete"
              className="delete"
              onClick={() => removeForm("Name", 14)}
            />
            <img
              src="/assets/images/close.svg"
              alt="close"
              className="close"
              onClick={() => setFormName("")}
            />
          </div>
        </div>
        <div className="form-area">
          <form>
            <div className="mb-4">
              <label htmlFor="label" className="form-label">
                Label
              </label>
              <input
                type="text"
                className="form-control"
                name="label"
                id="label"
                defaultValue={wallet?.name}
                onChange={handleLabelChange}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="displayValue" className="form-label">
                Display Value
              </label>
              <input
                type="text"
                className="form-control"
                name="displayValue"
                id="displayValue"
                defaultValue={defaulDisplayValue}
                onChange={handleDisplayValueChange}
              />
              <span className="input-bottom">
                {" "}
                <FontAwesomeIcon icon={faCircleQuestion} /> Static text on the
                customer pass
              </span>
            </div>
            <div className="label-text">
              <label className="form-label">Field Key</label>
              <span className="input-bottom">person.displayName</span>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Name;
