import React, { useContext, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { WalletContext } from "../../../Context/WalletProvider";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { removeActiveMenu } from "../../../Store/Slices/activeMenuSlice";

const AddItem = (props) => {
  const { passOptions, setFormName, setActiveField, activeField } =
    useContext(WalletContext);
  const advanceFields = useSelector((state) => state.removeElement);
  const inThisPassFields = useSelector((state) => state.addPresetFields);
  const dispatch = useDispatch();
  const handleOnClick = (e) => {
    setFormName(e.target.textContent);
    setActiveField(e.target.textContent);
  };

  const handleOnDrag = (e, widgetType) => {
    e.dataTransfer.setData("widgetType", widgetType);
  };

  const coupon = props.coupon;

  return (
    <>
      <div className="sidebar">
        <div className="action-area d-flex justify-content-between align-items-center">
          <span>Add item</span>
          {/* <img
            src="/assets/images/close.svg"
            alt="close"
            className="close"
            onClick={() => {
              dispatch(removeActiveMenu());
            }}
          /> */}
        </div>
        <div className="accordions">
          <Accordion defaultActiveKey="3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>In this pass</Accordion.Header>
              <Accordion.Body>
                <ul>
                  {/* <li onClick={handleOnClick}>Program Name</li> */}
                  {passOptions?.map((pass, idx) => (
                    <li
                      className={`${activeField === pass && "activeItem"}`}
                      key={idx}
                      onClick={handleOnClick}
                    >
                      {pass.charAt(0).toUpperCase() + pass.slice(1)}
                    </li>
                  ))}
                  {/*  */}
                  {/* <li onClick={handleOnClick}>Points</li>
                  <li onClick={handleOnClick}>Name</li>
                  <li onClick={handleOnClick}>Tier</li>
                  <li onClick={handleOnClick}>Information</li> */}
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Add Custom Fields</Accordion.Header>
              <Accordion.Body>
                <ul>
                  {/* <li
                    draggable="true"
                    onDragStart={(e) => handleOnDrag(e, "Link")}
                    onClick={handleOnClick}
                  >
                    <img
                      src="/assets/images/sidebar-menu-icon.svg"
                      alt="logo"
                      className="close"
                    />
                    Link
                  </li> */}
                  <li
                    draggable="true"
                    onDragStart={(e) => handleOnDrag(e, "New Field")}
                    // onClick={handleOnClick}
                  >
                    <img
                      src="/assets/images/sidebar-menu-icon.svg"
                      alt="logo"
                      className="close"
                    />
                    New Daynamic Field
                  </li>
                  <li
                    draggable="true"
                    onDragStart={(e) => handleOnDrag(e, "New Field")}
                    // onClick={handleOnClick}
                  >
                    <img
                      src="/assets/images/sidebar-menu-icon.svg"
                      alt="logo"
                      className="close"
                    />
                    New Static Field
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>Add Present Fields</Accordion.Header>
              <Accordion.Body>
                <ul>
                  {inThisPassFields?.map((field, idx) => (
                    <li
                      key={idx}
                      draggable="true"
                      onDragStart={(e) => handleOnDrag(e, field)}
                      // onClick={handleOnClick}
                    >
                      <img
                        src="/assets/images/sidebar-menu-icon.svg"
                        alt="logo"
                        className="close"
                      />
                      {field}
                    </li>
                  ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>Add Advanced Fields</Accordion.Header>
              <Accordion.Body>
                <ul>
                  {advanceFields
                    ?.filter(
                      (field) => coupon !== "Coupon" || field !== "Expiry Date"
                    )
                    .map((field, idx) => (
                      <li
                        key={idx}
                        draggable="true"
                        onDragStart={(e) => handleOnDrag(e, field)}
                        // onClick={handleOnClick}
                      >
                        <img
                          src="/assets/images/sidebar-menu-icon.svg"
                          alt="logo"
                          className="close"
                        />
                        {field}
                      </li>
                    ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default AddItem;
