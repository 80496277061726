import React, { createContext, useContext, useState } from "react";
export const WalletFormContext = createContext();
const WalletFormProvider = ({ children }) => {
  const [secondaryFormsData, setSecondaryFormsData] = useState([]);
  const [primaryFormData, setPrimaryFormData] = useState({});
  const [headerFields, setHeaderFields] = useState({
    points: {
      label: {
        label: "Label",
        value: "Points",
      },
      displayValue: {
        label: "Display Value",
        value: "100",
      },
    },
  });
  const [newFieldLabel, setNewFieldLabel] = useState("");
  const [label, setLabel] = useState("");

  //
  const addOrUpdateSecondaryFormData = (newObject) => {
    setSecondaryFormsData((prevArray) => {
      const newArray = [...prevArray];
      const existingObjectIndex = newArray.findIndex(
        (obj) => obj.id === newObject.id
      );

      if (existingObjectIndex !== -1) {
        // If the object already exists, update it
        newArray[existingObjectIndex] = {
          ...newArray[existingObjectIndex],
          ...newObject,
        };
      } else {
        // If the object doesn't exist, add it to the array
        newArray.push(newObject);
      }
      return newArray;
    });
  };

  const walletForm = {
    secondaryFormsData,
    setSecondaryFormsData,
    addOrUpdateSecondaryFormData,
    headerFields,
    setHeaderFields,
    newFieldLabel,
    setNewFieldLabel,
    label,
    setLabel,
    primaryFormData,
    setPrimaryFormData,
  };
  return (
    <WalletFormContext.Provider value={walletForm}>
      {children}
    </WalletFormContext.Provider>
  );
};
export const useData = () => {
  return useContext(WalletFormContext);
};
export default WalletFormProvider;
