import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  logoImage: "",
  heroImage: "",
  color: "rgb(30, 49, 129)",
  labelColor: "rgb(255, 255, 255)",
  formate: "PKBarcodeFormatQR",
  barcodeValue: "",
  passTypeIdentifier: "",
  passId: '',
  header: "My Organization",
  cardTitle: "My Loyalty Card",
};

const passDetailsSetterSlice = createSlice({
  name: "menuActive",
  initialState,
  reducers: {
    setPassThemeInfo: (state, action) => {
      const {
        logoImage,
        heroImage,
        color,
        labelColor,
        formate,
        barcodeValue,
        passTypeIdentifier,
        header,
        cardTitle,
        passId
      } = action.payload;
      if (logoImage) {
        state.logoImage = logoImage;
      }
      if (passId) {
        state.passId = passId;
      }
      if (heroImage) {
        state.heroImage = heroImage;
      }
      if (heroImage === "empty") {
        state.heroImage = "";
      }
      if (color) {
        state.color = color;
      }
      if (labelColor) {
        state.labelColor = labelColor;
      }
      if (formate) {
        state.formate = formate;
      }
      if (barcodeValue) {
        state.barcodeValue = barcodeValue;
      }
      if (passTypeIdentifier) {
        state.passTypeIdentifier = passTypeIdentifier;
      }
      if (header) {
        state.header = header;
      }
      if (header === "empty") {
        state.header = "";
      }
      if (cardTitle) {
        state.cardTitle = cardTitle;
      }
      if (cardTitle === "empty") {
        state.cardTitle = "";
      }
    },
    resetPassDetails: (state) => {
      return { ...initialState };
    },
  },
});

export const { setPassThemeInfo, resetPassDetails } =
  passDetailsSetterSlice.actions;
export default passDetailsSetterSlice.reducer;
