import React, { useState } from 'react';

const Location = (props) => {

    const [data, setData] = useState("");

    const handleOnClick = (e) => {
        setData(e.target.textContent);
    }
    
    props.formName(data);

    return(
        <>
            <div className='sidebar'>
                <div className='action-area d-flex justify-content-between align-items-center'>
                    <span>Relevant Location</span>
                    <img src='/assets/images/close.svg' alt="close" className='close'/>
                </div>
                <div className='sidebar-location'>
                    <button type='button'>Add Location</button>
                    <form className='location-form'>
                        <p>location 1</p>
                        <h5>location distance</h5>
                        <input type="text" placeholder='USA Pkwy, fernley, NV 89408, USA'/>
                        <img src='/assets/images/map.svg' alt="map" className='map'/>
                        <h5 className='mt-3 mb-3'>notification</h5>
                        <div className='d-flex justify-content-start align-items-center mb-3'>
                            <img src='/assets/images/notification.svg' alt="notification" className='notification'/>
                            <span>This message will be shown on the user's lock screen</span>
                        </div>
                        <input type="text" placeholder='Store nearby on 3rd'/>
                        <button type='button'>update</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Location;