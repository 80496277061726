import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useData } from "../Context/WalletFormProvider";
import { WalletContext } from "../Context/WalletProvider";
import Swal from "sweetalert2";
import {
  resetPassDetails,
  setPassThemeInfo,
} from "../Store/Slices/passDetailsSlice";
import useAllPassData from "../Store/Slices/useAllPassData";
import ConfirmModal from "./Modals/ConfirmModal";
import SettingsModal from "./Modals/SettingsModal";
import {
  addOrUpdateRowData,
  resetGoogleRowState,
} from "../Store/Slices/GoogleWalletData/Fields/googleFieldsRow";
import { resetAdvancedFields } from "../Store/Slices/RemoveElementSlice";
import { resetPrimaryFormsData } from "../Store/Slices/primaryFormsDataSlice";
import { Spinner } from "react-bootstrap";

const WalletHeader = (props) => {
  const navigate = useNavigate();

  const {
    setPrimaryFields,
    setSecondaryFields,
    setAuxFields,
    setPassOptions,
    activeCardName,
    setActiveCardName,
    setCouponColor,
    setMembershipAppleWalletBgColor,
    wallets,
    formName,
  } = useContext(WalletContext);

  const dispatch = useDispatch();
  const passKitData = useAllPassData();
  console.log("🚀 ~ WalletHeader ~ passKitData:", passKitData);

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalValue, setModalValue] = useState("");
  const [showCard, setShowCardModal] = useState(false);
  const [cardName, setCardName] = useState(false);
  const [userMenuClick, setUserMenuClick] = useState("StoreCard");
  // props.userMenu(userMenuClick);
  const {
    secondaryFormsData,
    setSecondaryFormsData,
    setHeaderFields,
    setPrimaryFormData,
  } = useData();
  const [userInfo, setUserInfo] = useState({});
  const [pkPass, setPkPass] = useState();

  const { firstRow } = useSelector((state) => state.googleWalletFieldsRow);
  const randomSixDigitNumber = Math.floor(100000 + Math.random() * 900000);
  const token = JSON.parse(localStorage.getItem("accessToken"));

  const handlePostPrimaryData = async (e) => {
    e.preventDefault();
    if (userInfo?.id) {
      if (wallets === "Google Wallet") {
        handleCreateGooglePass();
      } else {
        if (secondaryFormsData?.length === 0) {
          toast.error(
            "One form is required in secondary field for apple wallet."
          );
          return;
        }
        handleCreateApplePass();
      }
    } else {
      toast.error("Please login first.");
      setLoading(false);
      setSecondaryFields([]);
      setSecondaryFormsData([]);
      navigate("/signin");
    }
  };

  const handleMenuOnClick = (value) => {
    setUserMenuClick(value);
    setActiveCardName(value);
    resetAllStore(value);
    dispatch(resetAdvancedFields());
    dispatch(resetPrimaryFormsData());
    setPrimaryFormData({});
  };
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    setUserInfo(userData);
  }, []);

  //
  const handleCreateGooglePass = async () => {
    setLoading(true);
    const Google_Url = `${process.env.REACT_APP_API_ENDPOINT}google-generate-pass`;
    try {
      const response = await fetch(Google_Url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(passKitData),
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        setLoading(false);
        // Extract the .gpass formatted data from the response
        if (passKitData?.passDetails?.passId) {
          Swal.fire({
            title: "Google Wallet",
            text: "Successfully Updeted. Thank you !",
            icon: "success",
            confirmButtonText: "Okay",
          });
        } else {
          const clonedResponse = response.clone();
          const redirectUrl = await clonedResponse.text();
          const gpassData = await response.blob();
          const url = window.URL.createObjectURL(new Blob([gpassData]));
          const a = document.createElement("a");
          a.href = url;
          a.download = `Google_pass_${randomSixDigitNumber}.gpass`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          window.open(redirectUrl, "_blank");
        }
      } else {
        console.error("Failed to fetch data:", response.statusText);
        setLoading(false);
        toast.error(
          "Please ensure that your images have the .png extension, correct dimensions, and are under 10 MB."
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        "Please ensure that your images have the .png extension, correct dimensions, and are under 10 MB."
      );
    }
  };
  const handleCreateApplePass = async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_API_ENDPOINT}apple-generate-pass`;
    try {
      const response = await axios.post(url, passKitData, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      // Extract the .pkpass formatted data from the response
      const passType = Object.keys(passKitData)[0];
      if (passKitData[passType]?.passDetails?.passId) {
        setLoading(false);
        Swal.fire({
          title: "Apple Wallet",
          text: "Successfully Updeted. Thank you !",
          icon: "success",
          confirmButtonText: "Okay",
        });
      } else {
        const blob = new Blob([response.data], {
          type: "application/vnd.apple.pkpass",
        });
        // Trigger download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `pass_${randomSixDigitNumber}.pkpass`;
        link.click();
        setPkPass(response.data); // You might not need this line if it's just binary data
        setLoading(false);
      }
      // Handle the response
    } catch (error) {
      setLoading(false);
      toast.error(
        "Please ensure that your images have the .png extension, correct dimensions, and are under 50KB."
      );
      // toast.error("Request failed.");
      console.error("Error:", error);
    }
  };

  const resetAllStore = (value) => {
    if (value === "StoreCard" || value === "GenericPass") {
      const labelExists = firstRow.some((field) => field.label === "Points");
      if (!labelExists) {
        dispatch(
          addOrUpdateRowData({ label: "Points", value: "Points", row: 1 })
        );
      }
      dispatch(resetPassDetails());
      setHeaderFields({
        points: {
          label: {
            label: "Label",
            value: "Points",
          },
          displayValue: {
            label: "Display Value",
            value: "100",
          },
        },
      });
      // dispatch(resetAdvancedFields());
    } else {
      dispatch(resetGoogleRowState());
      setHeaderFields({
        expiryDate: {
          id: 4,
          expirySettings: {
            label: "Expiry Settings",
            value: "Not Expired",
          },
          displayValue: {
            label: "Date Format",
            value: "Not Expired",
          },
          label: {
            label: "Label",
            value: "Expiry Date",
            baseValue: "Expiry Date",
          },
        },
      });
      if (value === "GenericPass") {
        const payload = { color: "rgb(248,212,25))" };
        dispatch(setPassThemeInfo(payload));
      } else {
        const payload = { color: "rgb(138, 120, 101)" };
        dispatch(setPassThemeInfo(payload));
      }

      setCouponColor("rgb(138, 120, 101)");
      setMembershipAppleWalletBgColor("rgb(248,212,25)");
    }
    setPrimaryFields({});
    setSecondaryFields([]);
    setAuxFields([]);
    setPrimaryFormData({});
    setSecondaryFormsData([]);
  };
  //

  return (
    <div>
      {/* HEADER BOTTOM */}
      <div className="header-bottom">
        <div className="container">
          <div className="row justify-content-between align-items-center header-bottom">
            <div className="col-lg-4 col-4">
              <div className="search-form">
                <form>
                  <input type="search" placeholder="Design Name" />
                </form>
              </div>
            </div>
            <div className="col-lg-8 col-8">
              <div className="header-bottom-right d-flex justify-content-end align-items-center">
                <div className="left-icons d-flex justify-content-start align-items-center">
                  {/* 
                      <div className='single-icon'>
                        <Link to="/">
                          <img src='/assets/images/loyalty-card.svg' alt="event"/>
                        </Link>
                      </div>
                  */}
                  <div
                    className={`single-icon ${
                      activeCardName === "StoreCard" ? "focus" : ""
                    }`}
                    onClick={() => {
                      setPassOptions(["Points"]);
                      handleMenuOnClick("StoreCard");
                    }}
                  >
                    <Link to="/wallet">
                      <img
                        src="/assets/images/loyalty-card.svg"
                        alt="loyalty-card"
                        title="StoreCard"
                      />
                    </Link>
                  </div>

                  <div
                    className={`single-icon ${
                      activeCardName === "GenericPass" ? "focus" : ""
                    }`}
                    onClick={() => {
                      setPassOptions(["Points"]);
                      handleMenuOnClick("GenericPass");
                    }}
                  >
                    <Link to="/wallet">
                      <img
                        src="/assets/images/membership-card.svg"
                        alt="membership-card"
                        title="GenericPass"
                      />
                    </Link>
                  </div>
                  <div
                    className={`single-icon ${
                      activeCardName === "Coupon" ? "focus" : ""
                    }`}
                    onClick={() => {
                      setPassOptions((prev) => [...prev, "Expiry Date"]);
                      handleMenuOnClick("Coupon");
                    }}
                  >
                    <Link to="/wallet">
                      <img
                        src="/assets/images/coupon.svg"
                        alt="coupon"
                        title="Coupon"
                      />
                    </Link>
                  </div>
                  <div
                    className="single-icon"
                    onClick={() => setShowModal(true)}
                  >
                    <img
                      src="/assets/images/settings.svg"
                      alt="settings"
                      title="Settings"
                    />
                  </div>
                </div>
                <a href={pkPass} download="GeneratedpkPass.pkpass" />
                <button
                  type="button"
                  className="save-btn"
                  onClick={handlePostPrimaryData}
                >
                  {loading ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modals */}
      <SettingsModal showModal={showModal} setShowModal={setShowModal} />
      <ConfirmModal
        showCard={showCard}
        setShowCardModal={setShowCardModal}
        setModalValue={setModalValue}
        cardName={cardName}
        setCardName={setCardName}
      />
    </div>
  );
};

export default WalletHeader;
