import { faCircleQuestion, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useData } from "../../Context/WalletFormProvider";
import { WalletContext } from "../../Context/WalletProvider";
import { setDynamicLabel } from "../../Store/Slices/WalletSlice";

const StaticField = () => {
  const {
    removeForm,
    setFormName,
    setActiveField,
    setPrimaryFields,
    primaryFields,
    auxFields, 
    setAuxFields,
    setSecondaryFields,
    secondaryFields,
    formInputs,
    setFormInputs,
  } = useContext(WalletContext);
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);

  const { addOrUpdateSecondaryFormData } = useData();
  const [staticFields, setStaticFields] = useState({
    id: 20,
    fieldName: {
      label: "Field Name",
      value: "",
    },
    fieldType: {
      label: "Field Type",
      value: "",
    },
    label: {
      label: "Label",
      value: "",
      baseValue: "Static Field",
    },
    displayValue: {
      label: "Display Value",
      value: "",
    },
  });

  const handleFieldNameChange = (e) => {
    const value = e.target.value;
    setStaticFields((prevFormData) => ({
      ...prevFormData,
      fieldName: {
        ...prevFormData.fieldName,
        value: value,
      },
    }));
  };
  const handleFieldTypeChange = (e) => {
    const value = e.target.value;
    setStaticFields((prevFormData) => ({
      ...prevFormData,
      fieldType: {
        ...prevFormData.fieldType,
        value: value,
      },
    }));
  };
  const handleLabelChange = (e) => {
    const value = e.target.value;
    const payload = { form: "New Static Field", value };
    dispatch(setDynamicLabel(payload));
    //
    if (!value) {
      setFormName("New Static Field");
      setActiveField("New Static Field");
    }
    const updatedPrimaryFields = primaryFields.label === "New Static Field" ? { ...primaryFields, value } : primaryFields;
    setPrimaryFields(updatedPrimaryFields);

    const updatedAuxiliaryFields = auxFields.map((field) =>
      field.label === "New Static Field" ? { ...field, value } : field
    );
    setAuxFields(updatedAuxiliaryFields);

    const updatedFormInputs = formInputs.map((field) =>
      field.label === "New Static Field" ? { ...field, value } : field
    );
    setFormInputs(updatedFormInputs);

    const updatedsecondaryFields = secondaryFields.map((field) =>
      field.label === "New Static Field" ? { ...field, value } : field
    );
    setSecondaryFields(updatedsecondaryFields);
    setActiveField(value);
    //

    setStaticFields((prevFormData) => ({
      ...prevFormData,
      label: {
        ...prevFormData.label,
        value: value,
      },
    }));
  };
  const handledDisplayValueChange = (e) => {
    const value = e.target.value;
    setStaticFields((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: value,
      },
    }));
  };
  useEffect(() => {
    addOrUpdateSecondaryFormData(staticFields);
  }, [staticFields]);
    // const primaryFormsData = useSelector((state) => state.primaryFormsData);
  // const [defaulDisplayValue, setDefaulDisplayValue] = useState("");

  // defaultValue={defaulDisplayValue}

  // const payload = { formId: 5, displayValue: value };
  // dispatch(addPrimaryFormsData(payload));

  // useEffect(() => {
  //   const matchingElement = primaryFormsData.find(
  //     (element) => element?.formId === 19
  //   );
  //   setDefaulDisplayValue(matchingElement?.displayValue);
  // }, []);

  return (
    <>
      <div className="sidebar">
        <div className="action-area d-flex justify-content-between align-items-center">
          <span>Custom Field</span>
          <div>
            <img
              src="/assets/images/delete.svg"
              alt="delete"
              className="delete"
              onClick={() => removeForm("New Static Field", 20)}
            />
            <img src="/assets/images/close.svg" alt="close" className="close" />
          </div>
        </div>
        <div className="form-area">
          <form>
            <div className="mb-4">
              <label htmlFor="fieldName" className="form-label">
                Field Name
              </label>
              <input
                type="text"
                className="form-control"
                name="title"
                id="fieldName"
                placeholder="New data field xxx"
                onChange={handleFieldNameChange}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="fieldType" className="form-label">
                Field Type
              </label>
              <select
                className="form-select"
                id="fieldType"
                onChange={handleFieldTypeChange}
              >
                <option value="multi line text">Multi Line Text</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="newField" className="form-label">
                Label
              </label>
              <input
                type="text"
                className="form-control"
                name="newField"
                id="newField"
                defaultValue={wallet?.newStaticField}
                onChange={handleLabelChange}
              />
            </div>
            <div className="caution-box d-flex justify-content-start align-items-start">
              <div className="left">
                <FontAwesomeIcon icon={faCircleQuestion} />
              </div>
              <div className="right">
                <div className="top d-flex justify-content-between align-items-center">
                  <span>Caution</span>
                  <FontAwesomeIcon icon={faXmark} />
                </div>
                <div className="bottom">
                  <p>
                    The latest version of Google Wallet has updated formatting
                    for long text fields. Please review this help article if you
                    see any inconsistencies on Android devices.
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="displayValue" className="form-label">
                Display Value
              </label>
              <input
                type="text"
                className="form-control"
                name="displayValue"
                id="displayValue"
                placeholder="New field xxx"
                onChange={handledDisplayValueChange}
              />
              <span className="input-bottom">
                {" "}
                <FontAwesomeIcon icon={faCircleQuestion} /> Static text on the
                customer pass
              </span>
            </div>
            <div className="label-text">
              <label className="form-label">Field Key</label>
              <span className="input-bottom">meta.newDataFieldxxx</span>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default StaticField;
