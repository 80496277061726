import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeMenu: "Add item",
};

const menuActivateSlice = createSlice({
  name: "menuActive",
  initialState,
  reducers: {
    setActiveMenu: (state, action) => {
      const menuName = action.payload;
      state.activeMenu = menuName;
    },
    removeActiveMenu: (state) => {
      state.activeMenu = "Add item";
    },
  },
});

export const { setActiveMenu, removeActiveMenu } =
  menuActivateSlice.actions;
export default menuActivateSlice.reducer;
