import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal";
import toast from 'react-hot-toast';
import MaintenanceModal from './MaintenanceModal';

const SettingsModal = ({ showModal, setShowModal }) => {
    const [wallets, setWallets] = useState("Apple Wallet");
    const [showMaintenanceModal, setShowMaintenanceModal] = useState(false);


    const handleOnClickClose = () => {
        setShowModal(false);
    };
    const handleOnClick = (e) => {
        setWallets(e.target.textContent);
    };
    const handleButtonClick = () => {
        setShowMaintenanceModal(true);
    }
    return (
        <>
            <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
                <div className="d-flex justify-content-between align-items-center px-4 pt-4">
                    <img
                        src="/assets/images/gear.svg"
                        alt="close-btn"
                        className="gear-btn"
                    />
                    <h5 style={{ color: 'tomato' }}>Demo only! Functionality does't work.</h5>
                    <img
                        src="/assets/images/close.svg"
                        alt="close-btn"
                        className="share-btn"
                        onClick={handleOnClickClose}
                    />
                </div>
                <div className="px-4 modal-heading">
                    <h2>Settings</h2>
                    <p>Need wording to describe this task.</p>
                </div>

                <Modal.Body>
                    <div className="wallet-body">
                        <ul className="settings-tab d-flex justify-content-center align-items-center">
                            <li className={`${wallets === "General" ? "tab-active" : ""}`} onClick={handleOnClick}>General</li>
                            <li className={`${wallets === "Google Wallet" ? "tab-active" : ""}`} onClick={handleOnClick}>Google Wallet</li>
                            <li className={`${wallets === "Apple Wallet" ? "tab-active" : ""}`} onClick={handleOnClick}>Apple Wallet</li>
                        </ul>
                        <div className="wallets tab">
                            {wallets === "General" && (
                                <>
                                    <div className="form-area">
                                        <div className="mb-4">
                                            <label htmlFor="templateName" className="form-label">
                                                Template Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="templateName"
                                                id="templateName"
                                                placeholder="Design Name"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="description" className="form-label">
                                                Description
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="description"
                                                rows="3"
                                                placeholder="Your special card"
                                            ></textarea>
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="timezone" className="form-label">
                                                Timezone
                                            </label>
                                            <select className="form-select" id="timezone">
                                                <option value="Western Indonesia Time - Jakarta(GMT=+7)">
                                                    Western Indonesia Time - Jakarta (GMT=+7)
                                                </option>
                                                <option value="Western Indonesia Time - Jakarta(GMT=+8)">
                                                    Western Indonesia Time - Jakarta (GMT=+8)
                                                </option>
                                                <option value="Western Indonesia Time - Jakarta(GMT=+9)">
                                                    Western Indonesia Time - Jakarta (GMT=+9)
                                                </option>
                                            </select>
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="language" className="form-label">
                                                Default Language
                                            </label>
                                            <select className="form-select" id="language">
                                                <option value="English">English</option>
                                                <option value="Bangla">Bangla</option>
                                            </select>
                                        </div>
                                        <div className="mb-4">
                                            <div className="hr"></div>
                                        </div>
                                        <div className="mb-4 buttons d-flex justify-content-between align-items-center flex-wrap">
                                            <button onClick={handleOnClickClose} type="submit">Cancel</button>
                                            <button onClick={handleButtonClick} type="submit">Save</button>
                                        </div>
                                    </div>
                                </>
                            )}
                            {wallets === "Google Wallet" && (
                                <>
                                    <div className="form-area">
                                        <div className="mb-4">
                                            <label
                                                htmlFor="organizationName"
                                                className="form-label"
                                            >
                                                Organization Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="organizationName"
                                                id="organizationName"
                                                placeholder="Design Name"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label">
                                                Default Android Wallet Type
                                            </label>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="googlePay"
                                                    id="googlePay"
                                                />
                                                <label
                                                    className="form-check-label fw-normal"
                                                    for="googlePay"
                                                >
                                                    Google Pay
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="alternativePay"
                                                    id="alternativePay"
                                                />
                                                <label
                                                    className="form-check-label fw-normal"
                                                    for="alternativePay"
                                                >
                                                    Alternative Pay
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label">
                                                Alternative Wallet Provider
                                            </label>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="walletPass"
                                                    id="walletPass"
                                                />
                                                <label
                                                    className="form-check-label fw-normal"
                                                    for="walletPass"
                                                >
                                                    Wallet Pass
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="passWallet"
                                                    id="passWallet"
                                                />
                                                <label
                                                    className="form-check-label fw-normal"
                                                    for="passWallet"
                                                >
                                                    PassWallet
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <div className="hr"></div>
                                        </div>
                                        <div className="mb-4 buttons d-flex justify-content-between align-items-center flex-wrap">
                                            <button onClick={handleOnClickClose} type="submit">Cancel</button>
                                            <button onClick={handleButtonClick} type="submit">Save</button>
                                        </div>
                                    </div>
                                </>
                            )}
                            {wallets === "Apple Wallet" && (
                                <>
                                    <div className="form-area">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="allowSharing"
                                            />
                                            <label className="form-check-label" for="allowSharing">
                                                Allow Sharing
                                            </label>
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="sharingUrl" className="form-label">
                                                Sharing URL
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="sharingUrl"
                                                id="sharingUrl"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="passPersonalization"
                                                    id="passPersonalization"
                                                />
                                                <label
                                                    className="form-check-label fw-normal"
                                                    for="passPersonalization"
                                                >
                                                    Enable Pass Personalization
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <div className="hr"></div>
                                        </div>
                                        <div className="mb-4 buttons d-flex justify-content-between align-items-center flex-wrap">
                                            <button onClick={handleOnClickClose} type="submit">Cancel</button>
                                            <button onClick={handleButtonClick} type="submit">Save</button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <MaintenanceModal showMaintenanceModal={showMaintenanceModal} setShowMaintenanceModal={setShowMaintenanceModal} />
        </>
    )
}

export default SettingsModal