import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  logoImage: "",
  heroImage: "",
  color: "rgb(138, 120, 101)",
  labelColor: "rgb(255, 255, 255)",
  formate: "PKBarcodeFormatQR",
  barcodeValue: "",
  passTypeIdentifier: "",
};

const passCouponThemeSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    setCouponPassThemeInfo: (state, action) => {
      const {
        logoImage,
        heroImage,
        color,
        labelColor,
        formate,
        barcodeValue,
        passTypeIdentifier,
      } = action.payload;
      if (logoImage) {
        state.logoImage = logoImage;
      }
      if (heroImage) {
        state.heroImage = heroImage;
      }
      if (heroImage === "empty") {
        state.heroImage = "";
      }
      if (color) {
        state.color = color;
      }
      if (labelColor) {
        state.labelColor = labelColor;
      }
      if (formate) {
        state.formate = formate;
      }
      if (barcodeValue) {
        state.barcodeValue = barcodeValue;
      }
      if (passTypeIdentifier) {
        state.passTypeIdentifier = passTypeIdentifier;
      }
    },
    resetCouponThemeDetails: (state) => {
      return { ...initialState };
    },
  },
});

export const { setCouponPassThemeInfo, resetCouponThemeDetails } =
  passCouponThemeSlice.actions;
export default passCouponThemeSlice.reducer;
