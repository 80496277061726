import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLoaderData, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useData } from "../../Context/WalletFormProvider";
import { WalletContext } from "../../Context/WalletProvider";
import { removePresentFieldElement } from "../../Store/Slices/AddPresentFieldsSlice.jsx";
import { removeElement } from "../../Store/Slices/RemoveElementSlice.jsx";
import { addSingleFormLabel } from "../../Store/Slices/SingleFormLabelSlice.jsx";
import {
  setDynamicLabel,
  setFormNameFromRedux,
} from "../../Store/Slices/WalletSlice.jsx";
import { setActiveMenu } from "../../Store/Slices/activeMenuSlice.jsx";
import { resetAuxiliaryFormsDataState } from "../../Store/Slices/auxiliaryFormsDataSlice.jsx";
import {
  resetPassDetails,
  setPassThemeInfo,
} from "../../Store/Slices/passDetailsSlice.jsx";
import {
  addPrimaryFormsData,
  resetPrimaryFormsData,
} from "../../Store/Slices/primaryFormsDataSlice.jsx";
import { TruncateString } from "../../utils/TruncateString.jsx";

const AppleWallet = (props) => {
  const {
    setFormName,
    activeField,
    setActiveField,
    passOptions,
    setPassOptions,
    secondaryFields,
    setSecondaryFields,
    formInputs,
    setFormInputs,
    activeCardName,
    couponColor,
    setActiveCardName,
    wallets,
    setCouponColor,
  } = useContext(WalletContext);
  const passDetailsDraft = useLoaderData();
  const { id } = useParams();
  const [renderLastAddedField, setRenderLastAddedField] = useState("");
  const {
    setNewFieldLabel,
    newFieldLabel,
    setLabel,
    setHeaderFields,
    setSecondaryFormsData,
    setPrimaryFormData,
  } = useData();
  //
  const dispatch = useDispatch();
  const { wallet, passThemeDetails, backFieldsFormData } = useSelector(
    (state) => state
  );
  const barcodeImage = passThemeDetails.formate;
  const primaryFormLabel = useSelector((state) => state.singleFormLabelData);
  const { logoImage, heroImage, passTypeIdentifier, color, labelColor } =
    useSelector((state) => state?.passThemeDetails);
  const [newFieldNumber, setNewFieldNumber] = useState(6);
  //   Set form name for render the specific form

  const handleOnClick = (formName) => {
    const isLastDigitNumber = /\d$/.test(formName);
    if (isLastDigitNumber) {
      setNewFieldLabel(formName);
      setActiveField(formName);
    } else {
      setNewFieldLabel("");
    }
    dispatch(setFormNameFromRedux(formName));
    setFormName(formName);
    setActiveField(formName);
    dispatch(setActiveMenu("Add item"));
  };

  const handleOnDrag = (e, widgetType) => {
    e.dataTransfer.setData("widgetType", widgetType);
  };
  // Drop the item and set in the state
  const handleOnDropInBackField = (e) => {
    const widgetType = e.dataTransfer.getData("widgetType");

    if (formInputs.length < 3) {
      const labelExists = formInputs.some(
        (field) => field.label === widgetType
      );
      if (!labelExists) {
        setFormInputs([
          ...formInputs,
          { label: widgetType, value: widgetType },
        ]);
      }
    }
  };

  // Drop the item for primary fields and set in the state
  const OnDropInSecondaryFields = (e) => {
    const widgetType = e.dataTransfer.getData("widgetType");
    setRenderLastAddedField(widgetType + newFieldNumber);
    if (widgetType === "Link" || widgetType === "Information") {
      Swal.fire({
        title: "Notice!",
        text: "You can add information and Link field only Passkit back side.",
        icon: "info",
        confirmButtonText: "Okay",
      });
      return;
    }
    if (
      widgetType === "Points" ||
      (activeCardName === "Coupon" && widgetType === "Expiry Date")
    ) {
      if (!passOptions.includes(widgetType)) {
        setPassOptions([...passOptions, widgetType]);
        dispatch(removePresentFieldElement(widgetType));
      }
      return; 
    }
    if (secondaryFields.length < 3) {
      const labelExists = secondaryFields.some(
        (field) => field.label === widgetType
      );
      if (!labelExists) {
        setSecondaryFields([
          ...secondaryFields,
          { label: widgetType, value: widgetType },
        ]);
        dispatch(removeElement(widgetType));
        dispatch(removePresentFieldElement(widgetType));
      }
      if (widgetType === "New Field") {
        setSecondaryFields([
          ...secondaryFields,
          {
            label: widgetType + newFieldNumber,
            value: widgetType + newFieldNumber,
          },
        ]);
        setNewFieldNumber((prevNumber) => prevNumber + 1);
        // Dynamic label for dynamic field
        const labelPayLoad = {
          formId: widgetType + newFieldNumber,
          label: widgetType + newFieldNumber,
        };
        dispatch(addSingleFormLabel(labelPayLoad));
      }
      //TODO: Need to create new field with number
    }

    const updatedFormInputs = formInputs.filter((item) => item !== widgetType);
    setFormInputs(updatedFormInputs);
  };

  //
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  //
  useEffect(() => {
    const matchingElement = primaryFormLabel.find(
      (element) => element?.formId === newFieldLabel
    );
    setLabel(matchingElement?.label);
  }, [newFieldLabel]);

  //
  useEffect(() => {
    const lastFromName =
      secondaryFields.length > 0
        ? secondaryFields[secondaryFields.length - 1]
        : null;
    if (lastFromName) {
      const isLastDigitNumber = /\d$/.test(lastFromName.value);
      if (isLastDigitNumber) {
        setNewFieldLabel(lastFromName.value);
      }
      setFormName(lastFromName.value);
      setActiveField(lastFromName.value);
    }
  }, [renderLastAddedField]);

  // useEffect(() => {
  //   const lastFromName =
  //     secondaryFields.length > 0
  //       ? secondaryFields[secondaryFields.length - 1]
  //       : null;
  //   const isLastDigitNumber = /\d$/.test(lastFromName?.value);
  //   if (isLastDigitNumber) {
  //     setNewFieldLabel(lastFromName?.value);
  //   }
  //   setFormName(lastFromName?.value);
  //   setActiveField(lastFromName?.value);
  // }, [renderLastAddedField]);

  useEffect(() => {
    if (passDetailsDraft?.pass_type === "apple") {
      saveSecondaryFieldsWithDraftData();
    } else {
      dispatch(resetPassDetails());
      setSecondaryFields([]);
      setSecondaryFormsData([]);
      dispatch(resetAuxiliaryFormsDataState());
      // setActiveCardName('StoreCard')
    }
  }, [passDetailsDraft]);

  const saveSecondaryFieldsWithDraftData = () => {

    const passType = Object.keys(passDetailsDraft)[0];
    if (passType === "GenericPass") {
      return;
    }
    const passData = passDetailsDraft[passType];
    const passDetails = passDetailsDraft[passType]?.passDetails || {};
    const payload = {
      color: passDetails.color,
      labelColor: passDetails.labelColor,
      logoImage: passDetails.logoImage,
      heroImage: passDetails.heroImage,
      formate: passDetails.formate,
      passId: id,
      barcodeValue: passDetails.barcodeValue,
    };
    setCouponColor(passDetails.color);
    dispatch(setPassThemeInfo(payload));
    // dispatch(setPassThemeInfo({color: passDetails.color}));
    dispatch(setPassThemeInfo({ passId: id }));
    setHeaderFields(passData?.headerFields);
    const arrayOfObjects = Object.entries(
      passDetailsDraft[passType]?.secondaryFormsData || {}
    ).map(([key, value]) => {
      const newObj = { [key]: value };
      return newObj;
    });
    // Extract label values from each object
    const extractedFields = arrayOfObjects.map((obj) => {
      const key = Object.keys(obj)[0];
      const { label } = obj[key];
      return { label: label?.baseValue, value: label?.value };
    });

    const passFields = extractedFields.map((item) => ({
      label: item.label,
      value: item.value,
    }));

    // FORM DATA SETUP
    // Dynamic label
    passFields?.forEach(({ label, value }) => {
      dispatch(setDynamicLabel({ form: label, value }));
    });
    setSecondaryFields(passFields);

    // Dynamic display value
    passData?.secondaryFormsData?.forEach(({ id, displayValue }) => {
      dispatch(
        addPrimaryFormsData({ formId: id, displayValue: displayValue?.value })
      );
    });
    // SET UP FORM DATA IN STORE
    // setActiveCardName(passType);
    setSecondaryFormsData([...passData?.secondaryFormsData]);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-6 text-center">
          <span>Front</span>
          <div
            className="wallet-front-apple"
            style={{
              backgroundColor: activeCardName === "Coupon" ? couponColor : color,
            }}
          >
            <div className="wallet-form-header d-flex justify-content-between align-items-center">
              <div
                className="wallet-header d-flex justify-content-start align-items-center"
                onClick={() => dispatch(setActiveMenu("Theme"))}
              >
                {logoImage ? (
                  <img src={logoImage} alt="wallet-logo" />
                ) : (
                  <img
                    src="/assets/images/sidebar-logo.svg"
                    alt="wallet-logo"
                  />
                )}
              </div>
              {passOptions.includes("Points") &&
                activeCardName !== "Coupon" && (
                  <div
                    onClick={() => {
                      setFormName("Points");
                      dispatch(setActiveMenu("Add item"));
                    }}
                    className={`field d-flex justify-content-start align-items-center ${
                      activeField === "Points" ? "activeField" : ""
                    }`}
                  >
                    <span className="dot"></span>
                    <span
                      style={{
                        color: labelColor ? labelColor : "ffffff",
                      }}
                    >
                      {TruncateString(wallet?.points)}
                    </span>
                  </div>
                )}
              {activeCardName === "Coupon" &&
                passOptions.includes("Expiry Date") && (
                  <div
                    onClick={() => {
                      setFormName("Expiry Date");
                      dispatch(setActiveMenu("Add item"));
                    }}
                    className={`field d-flex justify-content-start align-items-center ${
                      activeField === "Expiry Date" ? "activeField" : ""
                    }`}
                  >
                    <span className="dot"></span>
                    <span
                      style={{
                        color: labelColor ? labelColor : "ffffff",
                      }}
                    >
                      {TruncateString(wallet?.expiryDate)}
                    </span>
                  </div>
                )}
            </div>
            <div
              className="hero-area my-4"
              onClick={() => dispatch(setActiveMenu("Theme"))}
            >
              {heroImage ? (
                <img src={heroImage} alt="hero-image" />
              ) : (
                <img src="/assets/images/hero-image.png" alt="hero-image" />
              )}
            </div>
            <div
              onDragOver={handleDragOver}
              onDrop={OnDropInSecondaryFields}
              className="field d-flex gap-1 justify-content-between align-items-center"
              onClick={() => dispatch(setActiveMenu("Add item"))}
            >
              {secondaryFields &&
                secondaryFields?.map((field, index) => (
                  <span
                    key={index}
                    className={`fieldItem ${
                      field?.label === activeField ? "activeField" : ""
                    }`}
                    onClick={() => handleOnClick(field?.label)}
                    // draggable
                    onDragStart={(e) => handleOnDrag(e, field)}
                    style={{
                      color: labelColor ? labelColor : "#ffffff",
                    }}
                  >
                    {TruncateString(field?.value)}
                  </span>
                ))}
            </div>
            <div className="wallet-footer mt-5">
              <div
                className="barcode"
                onClick={() => dispatch(setActiveMenu("Barcode"))}
              >
                {barcodeImage === "qr" ? (
                  <div className="">
                    <img
                      src="/assets/images/barcode1.svg"
                      alt="barcode"
                      className="qr"
                    />
                  </div>
                ) : barcodeImage === "PKBarcodeFormatAztec" ? (
                  <img
                    src="/assets/images/barcode2.svg"
                    alt="barcode"
                    className="qr"
                  />
                ) : barcodeImage === "PKBarcodeFormatPDF417" ? (
                  <img
                    src="/assets/images/barcode3.svg"
                    alt="barcode"
                    className="Aztec"
                  />
                ) : barcodeImage === "PKBarcodeFormatCode128" ? (
                  <img
                    src="/assets/images/barcode4.svg"
                    alt="barcode"
                    className="Aztec"
                  />
                ) : (
                  <img
                    src="/assets/images/barcode1.svg"
                    alt="barcode"
                    className="qr"
                  />
                )}
                <span>{passTypeIdentifier}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 text-center">
          <span>Back/Details</span>
          <div className="wallet-details">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h4>Details</h4>
              <img
                src="/assets/images/share.svg"
                alt="share-btn"
                className="share-btn"
              />
            </div>
            <div className="wallet-header apple">
              <img
                src="/assets/images/apple-wallet-details-header.svg"
                alt="wallet-header"
              />
            </div>
            <span className="title">Your Special Card</span>
            <div onDragOver={handleDragOver} onDrop={handleOnDropInBackField}>
              {/* <button className="add mb-4">Add Application</button> */}

              <div className="checkbox-input d-flex justify-content-between align-items-center">
                <label className="text" htmlFor="automatic-updates">
                  Automatic Updates
                </label>
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    id="automatic-updates"
                    className="form-check-input"
                    defaultChecked
                    title="Toggle to allow user to receive updates on changes to their card"
                  />
                </div>
              </div>

              <div className="checkbox-input d-flex justify-content-between align-items-center">
                <label className="text" htmlFor="allow-notifications">
                  Allow Notifications
                </label>
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    id="allow-notifications"
                    className="form-check-input"
                    defaultChecked
                    title="Toggle to allow receive notifications"
                    disabled
                  />
                </div>
              </div>

              <button
                className="add text-danger mt-3"
                disabled
                title="Allow user to remove pass from their wallet"
              >
                Remove Pass
              </button>
              <div
                className="wallet-details-appleWallet"
                onDragOver={handleDragOver}
                onDrop={handleOnDropInBackField}
              >
                <div
                  onClick={() => {
                    dispatch(setActiveMenu("Add item"));
                  }}
                >
                  {formInputs?.map((field, index) => (
                    <div
                      key={index}
                      className="wallet-input"
                      id="wallet-tier"
                      draggable="true"
                      onClick={() => handleOnClick(field?.label)}
                      onDragStart={(e) => handleOnDrag(e, field?.label)}
                    >
                      <label htmlFor="tier">
                        {TruncateString(field?.value)}
                      </label>
                      <input type="text" id="tier" readOnly />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppleWallet;
