import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useData } from "../../Context/WalletFormProvider";
import { WalletContext } from "../../Context/WalletProvider";
import { setDynamicLabel } from "../../Store/Slices/WalletSlice";
import { addPrimaryFormsData } from "../../Store/Slices/primaryFormsDataSlice";
import { isThisRowContainThisForm } from "../../utils/checkRowFormName";
import { storeGoogleFormsData } from "../../Store/Slices/GoogleWalletData/allRowFormsData/googleWalletFormsData";
import { updateGoogleRowLabel } from "../../Store/Slices/GoogleWalletData/Fields/googleFieldsRow";

const Points = () => {
  const {
    removeForm,
    setFormName,
    setActiveField,
    setSecondaryFields,
    secondaryFields,
    wallets,
  } = useContext(WalletContext);
  const { firstRow } = useSelector((state) => state.googleWalletFieldsRow);
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const { primaryFormsData, googleWalletData } = useSelector((state) => state);
  const { firstRowData, secondRowData, thirdRowData, fourthRowData } =
    googleWalletData;
  const allRowsData = [
    firstRowData,
    secondRowData,
    thirdRowData,
    fourthRowData,
  ];
  const { headerFields, setHeaderFields } = useData();
  const [defaulDisplayValue, setDefaulDisplayValue] = useState(
    headerFields?.points?.displayValue?.value
  );
  const [points, setPoints] = useState({
    id: 16,
    label: {
      label: "Label",
      value: "Points",
      baseValue: "Points",
    },
    displayValue: {
      label: "Display Value",
      value: "",
    },
  });

  const handleLabelChange = (e) => {
    const value = e.target.value;
    const payload = { form: "Points", value };
    dispatch(setDynamicLabel(payload));
    dispatch(updateGoogleRowLabel(payload));

    //
    if (!value) {
      setFormName("Points");
      setActiveField("Points");
    }
    const updatedsecondaryFields = secondaryFields.map((field) =>
      field.label === "Points" ? { ...field, value } : field
    );
    setSecondaryFields(updatedsecondaryFields);
    setActiveField(value);
    //

    setPoints((prevFormData) => ({
      ...prevFormData,
      label: {
        ...prevFormData.label,
        value: value,
      },
    }));
  };
  const handleDisplayValueChange = (e) => {
    const value = e.target.value;
    const payload = { formId: 16, displayValue: value };
    dispatch(addPrimaryFormsData(payload));
    setPoints((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: value,
      },
    }));
  };
  useEffect(() => {
    if (wallets === "Google Wallet") {
      if (isThisRowContainThisForm(firstRow, "Points")) {
        const payload = { formData: points, row: 1 };
        dispatch(storeGoogleFormsData(payload));
      }
    } else {
      // addOrUpdateSecondaryFormData(points);
      setHeaderFields({ points });
    }
  }, [points]);

  useEffect(() => {
    if (wallets === "Google Wallet") {
      const matchingElement = allRowsData
        .flatMap((rowData) => rowData)
        .find((element) => element?.id === 16);
      setDefaulDisplayValue(matchingElement?.displayValue?.value);
    } else {
      const matchingElement = primaryFormsData.find(
        (element) => element?.formId === 16
      );
      setDefaulDisplayValue(matchingElement?.displayValue);
    }
  }, []);
  //
  // default display value
  useEffect(() => {
    setPoints((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: defaulDisplayValue ? defaulDisplayValue : "",
      },
      label: {
        ...prevFormData.label,
        value: wallet?.points,
      },
    }));
  }, [defaulDisplayValue, wallet?.points]);
  return (
    <>
      <div className="sidebar">
        <div className="action-area d-flex justify-content-between align-items-center">
          <span>Points</span>
          <div>
            <img
              onClick={() => removeForm("Points", 16)}
              src="/assets/images/delete.svg"
              alt="delete"
              className="delete"
            />
            <img
              src="/assets/images/close.svg"
              alt="close"
              className="close"
              onClick={() => setFormName("")}
            />
          </div>
        </div>
        <div className="form-area">
          <div className="mb-4">
            <label htmlFor="label" className="form-label">
              Label
            </label>
            <input
              type="text"
              className="form-control"
              name="label"
              id="label"
              defaultValue={wallet?.points}
              onChange={handleLabelChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="displayValue" className="form-label">
              Display Value
            </label>
            <input
              type="text"
              className="form-control"
              name="displayValue"
              id="displayValue"
              defaultValue={defaulDisplayValue}
              onChange={handleDisplayValueChange}
            />
            <span className="input-bottom">
              {" "}
              <FontAwesomeIcon icon={faCircleQuestion} /> Static text on the
              customer pass
            </span>
          </div>
          <div className="suggestion-box">
            <FontAwesomeIcon icon={faCircleQuestion} />
            <p>
              Don’t like the horizontal layout? Convert field type to text field
              to display it vertically.
            </p>
            <h5>Convert to text field</h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default Points;
