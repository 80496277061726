import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const auxiliaryFieldsFormDataSlice = createSlice({
  name: "auxiliaryFieldsFormsData",
  initialState,
  reducers: {
    addOrUpdateAuxiliaryField: (state, action) => {
      const AuxiliaryField = action.payload;
      const existingIndex = state.findIndex(
        (item) => item.id === AuxiliaryField.id
      );
      if (existingIndex !== -1) {
        state[existingIndex] = AuxiliaryField;
      } else {
        state.push(AuxiliaryField);
      }
    },
    removeAuxiliaryField: (state, action) => {
      const formId = action.payload;
      return state.filter((item) => item.id !== formId);
    },
    resetAuxiliaryFormsDataState: (state) => {
      return initialState;
    },
  },
});

export const {
  addOrUpdateAuxiliaryField,
  removeAuxiliaryField,
  resetAuxiliaryFormsDataState,
} = auxiliaryFieldsFormDataSlice.actions;
export default auxiliaryFieldsFormDataSlice.reducer;
