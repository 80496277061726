import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WalletContext } from "../../Context/WalletProvider";
import {
  addOrUpdateRowData,
  removeItemFromRow,
} from "../../Store/Slices/GoogleWalletData/Fields/googleFieldsRow";

import { useLoaderData, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useData } from "../../Context/WalletFormProvider";
import { removePresentFieldElement } from "../../Store/Slices/AddPresentFieldsSlice";
import { removeElement } from "../../Store/Slices/RemoveElementSlice";
import { addSingleFormLabel } from "../../Store/Slices/SingleFormLabelSlice";
import {
  setDynamicLabel,
  setFormNameFromRedux,
} from "../../Store/Slices/WalletSlice";
import { setActiveMenu } from "../../Store/Slices/activeMenuSlice";
import { setPassThemeInfo } from "../../Store/Slices/passDetailsSlice";
import { TruncateString } from "../../utils/TruncateString";
import useAllPassData from "../../Store/Slices/useAllPassData.jsx";
import { storeGoogleFormsData } from "../../Store/Slices/GoogleWalletData/allRowFormsData/googleWalletFormsData.jsx";

const GoogleWallet = (props) => {
  const {
    setFormName,
    activeField,
    setActiveField,
    activeCardName,
    couponColor,
    formInputs,
    setFormInputs,
    membershipAppleWalletBgColor,
    setCouponColor,
    setMembershipAppleWalletBgColor,
    setBackFieldsText,
  } = useContext(WalletContext);
  const dispatch = useDispatch();

  const passDetailsDraft = useLoaderData();
  const { id } = useParams();

  const [newFieldNumber, setNewFieldNumber] = useState(6);
  const { googleWalletFieldsRow, passThemeDetails, googleWalletData } =
    useSelector((state) => state);
  const { firstRowData, fourthRowData, secondRowData, thirdRowData } =
    googleWalletData;

  const barcodeImage = passThemeDetails.formate;
  const [renderLastAddedField, setRenderLastAddedField] = useState("");
  const { firstRow, secondRow, thirdRow, fourthRow } = googleWalletFieldsRow;
  const { logoImage, heroImage, passTypeIdentifier, color, labelColor } =
    passThemeDetails;
  const { setNewFieldLabel, newFieldLabel, setLabel, setSecondaryFormsData } =
    useData();
  const primaryFormLabel = useSelector((state) => state.singleFormLabelData);
  //   Set form name for render the specific form
  const handleOnClick = (formName) => {
    const isLastDigitNumber = /\d$/.test(formName);
    if (isLastDigitNumber) {
      setNewFieldLabel(formName);
      setActiveField(formName);
      dispatch(setFormNameFromRedux(formName));
      return;
    } else {
      setNewFieldLabel("");
    }
    dispatch(setFormNameFromRedux(formName));
    setFormName(formName);
    setActiveField(formName);
    dispatch(setActiveMenu("Add item"));
  };

  const handleOnDrag = (e, widgetType) => {
    e.dataTransfer.setData("widgetType", widgetType);
  };

  // Drop the item and set in the state
  const handleOnDropInBackField = (e) => {
    const widgetType = e.dataTransfer.getData("widgetType");
    if (widgetType === "New Field") {
      const labelPayLoad = {
        formId: widgetType + newFieldNumber,
        label: widgetType + newFieldNumber,
      };
      setFormInputs([
        ...formInputs,
        {
          label: widgetType + newFieldNumber,
          value: widgetType + newFieldNumber,
        },
      ]);
      dispatch(addSingleFormLabel(labelPayLoad));
      setNewFieldNumber((prevNumber) => prevNumber + 1);
      return;
    }
    const labelExists = formInputs.some((field) => field.label === widgetType);
    if (!labelExists) {
      dispatch(removeItemFromRow(widgetType));
      dispatch(removeElement(widgetType));
      dispatch(removePresentFieldElement(widgetType));
      setFormInputs([...formInputs, { label: widgetType, value: widgetType }]);
    }
  };

  // Google wallet row drop
  const onDropInRow = (e, row) => {
    const widgetType = e.dataTransfer.getData("widgetType");
    setFormName(widgetType);
    setRenderLastAddedField(widgetType);
    if (widgetType === "Link") {
      Swal.fire({
        title: "Notice!",
        text: "You can add Link field only the Passkit back side.",
        icon: "info",
        confirmButtonText: "Okay",
      });
      return;
    }
    const currentRow = getRowArray(row);
    if (!currentRow.includes(widgetType) && currentRow.length < 3) {
      const labelExists = formInputs.some(
        (field) => field.label === widgetType
      );
      if (labelExists) {
        const updatedFormInputs = formInputs.filter(
          (field) => field.label !== widgetType
        );
        setFormInputs(updatedFormInputs);
      }
      if (widgetType === "New Field") {
        const payload = {
          label: widgetType + newFieldNumber,
          value: widgetType + newFieldNumber,
          row,
        };
        dispatch(addOrUpdateRowData(payload));
        setFormInputs([
          ...formInputs,
          {
            label: widgetType + newFieldNumber,
            value: widgetType + newFieldNumber,
          },
        ]);
        setNewFieldNumber((prevNumber) => prevNumber + 1);

        // Dynamic label for dynamic field
        const labelPayLoad = {
          formId: widgetType + newFieldNumber,
          label: widgetType + newFieldNumber,
        };
        dispatch(addSingleFormLabel(labelPayLoad));
      } else {
        const payload = { label: widgetType, value: widgetType, row };
        dispatch(addOrUpdateRowData(payload));
        dispatch(removeElement(widgetType));
        dispatch(removePresentFieldElement(widgetType));
        setFormInputs([
          ...formInputs,
          { label: widgetType, value: widgetType },
        ]);
      }
    }
  };

  //
  const getRowArray = (row) => {
    switch (row) {
      case 1:
        return firstRow;
      case 2:
        return secondRow;
      case 3:
        return thirdRow;
      case 4:
        return fourthRow;
      default:
        return [];
    }
  };
  //
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  // Form acitvate on drop

  useEffect(() => {
    const nonEmptyRows = [firstRow, secondRow, thirdRow, fourthRow].filter(
      (row) => row && row.length > 0
    );
    const lastRow =
      nonEmptyRows.length > 0 ? nonEmptyRows[nonEmptyRows.length - 1] : null;
    const lastFromName = lastRow ? lastRow[lastRow.length - 1] : null;

    if (lastFromName && !document.activeElement.tagName.includes("INPUT")) {
      const isLastDigitNumber = /\d$/.test(lastFromName.value);
      if (isLastDigitNumber) {
        setNewFieldLabel(lastFromName.value);
      }
      setFormName(lastFromName.value);
      setActiveField(lastFromName.value);
    }
  }, [renderLastAddedField, firstRow, secondRow, thirdRow, fourthRow]);

  //
  useEffect(() => {
    const matchingElement = primaryFormLabel.find(
      (element) => element?.formId === newFieldLabel
    );
    setLabel(matchingElement?.label);
  }, [newFieldLabel]);

  //
  const handleDropGoogleWllet = (e) => {
    const widgetType = e.dataTransfer.getData("widgetType");
    if (widgetType === "Organization") {
      const payload = {
        header: "My Organization",
      };
      dispatch(setPassThemeInfo(payload));
    }
    if (widgetType === "Program") {
      const payload = {
        cardTitle: "My Loyalty Card",
      };
      dispatch(setPassThemeInfo(payload));
    }
  };
  const getCardBackgroundColor = () => {
    if (activeCardName === "StoreCard") {
      return passThemeDetails.color;
    } else if (activeCardName === "Coupon") {
      return couponColor;
    } else if (activeCardName === "GenericPass") {
      return membershipAppleWalletBgColor;
    } else {
      return passThemeDetails.color;
    }
  };
  // Save google data from draft
  useEffect(() => {
    if (passDetailsDraft?.pass_type === "google") {
      setGoogleDraftData();
    }
  }, []);

  const rowDataArrays = [
    passDetailsDraft?.textModulesData?.firstRowData,
    passDetailsDraft?.textModulesData?.secondRowData,
    passDetailsDraft?.textModulesData?.thirdRowData,
    passDetailsDraft?.textModulesData?.fourthRowData,
  ];
  const setGoogleDraftData = () => {
    rowDataArrays[0]?.forEach((obj, itemIndex) => {
      const payload = { formData: obj, row: 1 };
      dispatch(storeGoogleFormsData(payload));
    });

    rowDataArrays[1]?.forEach((obj, itemIndex) => {
      const payload = { formData: obj, row: 2 };
      dispatch(storeGoogleFormsData(payload));
    });

    rowDataArrays[2]?.forEach((obj, itemIndex) => {
      const payload = { formData: obj, row: 3 };
      dispatch(storeGoogleFormsData(payload));
    });

    rowDataArrays[3]?.forEach((obj, itemIndex) => {
      const payload = { formData: obj, row: 4 };
      dispatch(storeGoogleFormsData(payload));
    });

    // Set draft data in all rows
    setDraftDataInAllRowAndSomeInfo();
    setRenderLastAddedField("render");
  };

  const setDraftDataInAllRowAndSomeInfo = () => {
    rowDataArrays.forEach((rowData, index) => {
      if (rowData) {
        const rowDataExtracted = rowData.map((obj) => {
          const label = obj.label.baseValue;
          const value = obj.label.value;
          const payload = { form: obj?.label?.baseValue, value };
          dispatch(setDynamicLabel(payload));
          return { label, value };
        });
        rowDataExtracted.forEach(({ label, value }) => {
          const payload = { label, value, row: index + 1 };
          dispatch(addOrUpdateRowData(payload));
        });
      }
    });
    // Pass Theme info
    const passDetails = passDetailsDraft?.passDetails || {};
    const payload = {
      color: passDetails.color,
      labelColor: passDetails.labelColor,
      logoImage: passDetails.logoImage,
      heroImage: passDetails.heroImage,
      formate: passDetails.formate,
      passId: id,
      barcodeValue: passDetails.barcodeValue,
      header: passDetails.header,
      cardTitle: passDetails.cardTitle,
    };
    setMembershipAppleWalletBgColor(passDetails.color);
    setCouponColor(passDetails.color);
    dispatch(setPassThemeInfo(payload));
    dispatch(setPassThemeInfo({ passId: id }));
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-6 text-center">
          <span>Front</span>
          <div
            className="wallet-front"
            onDragOver={handleDragOver}
            onDrop={handleDropGoogleWllet}
            style={{ backgroundColor: getCardBackgroundColor() }}
          >
            <div className="wallet-form-header d-flex justify-content-between align-items-center">
              <div className="wallet-header d-flex justify-content-start align-items-center">
                <div
                  className="wallet-header d-flex justify-content-start align-items-center"
                  onClick={() => dispatch(setActiveMenu("Theme"))}
                >
                  {logoImage ? (
                    <img src={logoImage} alt="wallet-logo" />
                  ) : (
                    <img
                      src="/assets/images/sidebar-logo.svg"
                      alt="wallet-logo"
                    />
                  )}
                </div>

                <span
                  onClick={() => handleOnClick("Program")}
                  className={`fw-bold ${
                    activeField === "Program" && "border border-white p-2"
                  }`}
                  style={{
                    color: labelColor ? labelColor : "ffffff",
                  }}
                >
                  {TruncateString(passThemeDetails?.cardTitle)}
                </span>
              </div>
            </div>
            <div className="hr"></div>
            <div className="wallet-form">
              <h4
                onClick={() => handleOnClick("Organization")}
                className={`fw-bold ${
                  activeField === "Organization" && "border border-white p-2"
                }`}
                style={{
                  color: labelColor ? labelColor : "ffffff",
                }}
              >
                {passThemeDetails?.header}
              </h4>
              <div
                onDragOver={handleDragOver}
                onDrop={(e) => onDropInRow(e, 1)}
                className="field d-flex gap-1 justify-content-between align-items-center"
              >
                {firstRow?.map((field, idx) => (
                  <span
                    key={idx}
                    className={`fieldItem ${
                      field?.label === activeField ? "activeField" : ""
                    }`}
                    onClick={() => handleOnClick(field?.label)}
                    // draggable
                    onDragStart={(e) => handleOnDrag(e, field?.label)}
                    style={{
                      color: labelColor ? labelColor : "ffffff",
                    }}
                  >
                    {TruncateString(field?.value)}
                  </span>
                ))}
              </div>
              {/* 2nd row */}
              {secondRowData?.length || firstRow.length ? (
                <div
                  onDragOver={handleDragOver}
                  onDrop={(e) => onDropInRow(e, 2)}
                  className="field d-flex gap-1 justify-content-between align-items-center"
                >
                  {secondRow?.map((field, index) => (
                    <span
                      key={index}
                      className={`fieldItem ${
                        field?.label === activeField ? "activeField" : ""
                      }`}
                      style={{
                        color: labelColor ? labelColor : "ffffff",
                      }}
                      onClick={() => handleOnClick(field?.label)}
                      // draggable
                      onDragStart={(e) => handleOnDrag(e, field?.label)}
                    >
                      {TruncateString(field?.value)}
                    </span>
                  ))}
                </div>
              ) : (
                <></>
              )}

              {/* 3rd row */}
              {thirdRowData?.length || secondRow.length ? (
                <div
                  onDragOver={handleDragOver}
                  onDrop={(e) => onDropInRow(e, 3)}
                  className="field d-flex gap-1 justify-content-between align-items-center"
                >
                  {thirdRow?.map((field, index) => (
                    <span
                      key={index}
                      className={`fieldItem ${
                        field?.label === activeField ? "activeField" : ""
                      }`}
                      style={{
                        color: labelColor ? labelColor : "ffffff",
                      }}
                      onClick={() => handleOnClick(field?.label)}
                      // draggable
                      onDragStart={(e) => handleOnDrag(e, field?.label)}
                    >
                      {TruncateString(field?.value)}
                    </span>
                  ))}
                </div>
              ) : (
                <></>
              )}

              {/* 4th row */}
              {fourthRowData?.length || thirdRow.length ? (
                <div
                  onDragOver={handleDragOver}
                  onDrop={(e) => onDropInRow(e, 4)}
                  className="field d-flex gap-1 justify-content-between align-items-center"
                >
                  {fourthRow?.map((field, index) => (
                    <span
                      key={index}
                      className={`fieldItem ${
                        field?.label === activeField ? "activeField" : ""
                      }`}
                      style={{
                        color: labelColor ? labelColor : "ffffff",
                      }}
                      onClick={() => handleOnClick(field?.label)}
                      // draggable
                      onDragStart={(e) => handleOnDrag(e, field?.label)}
                    >
                      {TruncateString(field?.value)}
                    </span>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="wallet-footer mt-5">
              <div
                className="barcode"
                onClick={() => dispatch(setActiveMenu("Barcode"))}
              >
                {barcodeImage === "qr" || barcodeImage === "QR_CODE" ? (
                  <div className="">
                    <img
                      src="/assets/images/barcode1.svg"
                      alt="barcode"
                      className="qr"
                    />
                  </div>
                ) : barcodeImage === "PKBarcodeFormatAztec" ||
                  barcodeImage === "AZTEC" ? (
                  <img
                    src="/assets/images/barcode2.svg"
                    alt="barcode"
                    className="qr"
                  />
                ) : barcodeImage === "PKBarcodeFormatPDF417" ||
                  barcodeImage === "PDF_417" ? (
                  <img
                    src="/assets/images/barcode3.svg"
                    alt="barcode"
                    className="Aztec"
                  />
                ) : barcodeImage === "PKBarcodeFormatCode128" ||
                  barcodeImage === "CODE_128" ? (
                  <img
                    src="/assets/images/barcode4.svg"
                    alt="barcode"
                    className="Aztec"
                  />
                ) : (
                  <img
                    src="/assets/images/barcode1.svg"
                    alt="barcode"
                    className="qr"
                  />
                )}
                <span>{passTypeIdentifier}</span>
              </div>
            </div>
            <div
              className={`my-4 ${
                activeCardName === "GenericPass"
                  ? "hero-google-membership"
                  : "hero-google"
              }`}
              onClick={() => dispatch(setActiveMenu("Theme"))}
            >
              {heroImage ? (
                <img src={heroImage} alt="hero-image" />
              ) : (
                <img
                  src={
                    activeCardName === "GenericPass"
                      ? "/assets/images/person.png"
                      : "/assets/images/hero-image.png"
                  }
                  alt="hero-image"
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6 text-center">
          <span>Back/Details</span>
          <div
            className="wallet-details"
            onDragOver={handleDragOver}
            // onDrop={handleOnDropInBackField}
          >
            <h4 className="mb-4">Details</h4>
            <form>
              {formInputs?.map((field, index) => (
                <div
                  key={index}
                  className="wallet-input"
                  id="wallet-tier"
                  // draggable="true"
                  onClick={() => handleOnClick(field?.label)}
                  onDragStart={(e) => handleOnDrag(e, field?.label)}
                >
                  <label htmlFor="tier">{TruncateString(field?.value)}</label>
                  <input type="text" id="tier" readOnly />
                </div>
              ))}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleWallet;
