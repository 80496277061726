import React from 'react';
import { useState } from 'react';

const DataCollection = () => {

    const [formInputs, setFormInputs] = useState([]);

    const handleOnDrag = (e, widgetType) => {
        e.dataTransfer.setData("widgetType", widgetType);
    }
    const handleOnDrop = (e) => {
        const widgetType = e.dataTransfer.getData("widgetType");
        setFormInputs([...formInputs, widgetType]);
    }
    const handleDragOver = (e) => {
        e.preventDefault();
    }

    return(
        <>
            <div className='row data-collection'>
                <div className='col-lg-12 text-center'>
                    <div className='wallet-details mt-4'>
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <h4>Details</h4>
                            <img src='/assets/images/share.svg' alt="share-btn" className='share-btn'/>
                        </div>
                        <div className='wallet-header d-flex justify-content-start align-items-center'>
                            <img src='/assets/images/wallet-logo.svg' alt="wallet-logo"/>
                        </div>
                        <span className='title'>Enroll Below</span>
                        <form onDragOver={handleDragOver} onDrop={handleOnDrop}>
                            <div 
                                className='wallet-input'
                                id='description'
                                draggable="true"
                                onDragStart={(e)=> handleOnDrag(e, "Description")}
                            >
                                <textarea className="form-control" id="description" rows="3" placeholder='Description' readOnly></textarea>
                            </div>
                            <div 
                                className='wallet-input'
                                id='wallet-name'
                                draggable="true"
                                onDragStart={(e)=> handleOnDrag(e, "Name")}
                            >
                                <label htmlFor="name">name</label>
                                <input type="text" id='name' readOnly/>
                            </div>
                            <div 
                                className='wallet-input'
                                id='wallet-email'
                                draggable="true"
                                onDragStart={(e)=> handleOnDrag(e, "Email")}
                            >
                                <label htmlFor="email">email</label>
                                <input type="text" id='email' readOnly/>
                            </div>
                            <div 
                                className='wallet-input'
                                id='footer'
                                draggable="true"
                                onDragStart={(e)=> handleOnDrag(e, "Footer")}
                            >
                                <textarea className="form-control" id="description" rows="3" placeholder='Footer' readOnly></textarea>
                            </div>
                            {formInputs.map((formInput, index)=>(
                                <div 
                                    className='wallet-input'
                                    id='wallet-email'
                                    draggable="true"
                                    onDragStart={(e)=> handleOnDrag(e, "Email")}
                                >
                                    <label htmlFor="email">{formInput}</label>
                                    <input type="text" id='email' readOnly/>
                                </div>
                            ))}
                            <button type='submit' className='form-btn'>Enroll</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DataCollection;