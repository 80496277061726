import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { WalletContext } from "../../Context/WalletProvider";
import { useData } from "../../Context/WalletFormProvider";

const useAllPassData = () => {
  const [userInfo, setUserInfo] = useState({});
  const { activeCardName, wallets, membershipAppleWalletBgColor } =
    useContext(WalletContext);
  const { secondaryFormsData, headerFields, primaryFormData } = useData();
  const {
    secondaryFields,
    passThemeDetails,
    backFieldsFormData,
    addOrUpdateAuxiliaryField,
    googleWalletData,
    couponPassThemeDetils,
  } = useSelector((state) => state);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    setUserInfo(userData);
  }, []);
  // actual data format
  const passData = {
    [activeCardName]: {
      secondaryFormsData,
      userId: userInfo?.id,
      passDetails: passThemeDetails,
      headerFields,
      backFields: backFieldsFormData,
      primaryFormsData: primaryFormData,
      auxiliaryFormsData: addOrUpdateAuxiliaryField,
    },
  };

  const googlePassData = {
    passDetails: {
      ...passThemeDetails,
      activeCardName,
      color:
        activeCardName === "GenericPass"
          ? membershipAppleWalletBgColor
          : passThemeDetails.color,
    },
    textModulesData: googleWalletData,
    userId: userInfo?.id,
    id: "3388000000022308850",
    classId: "3388000000022308850",
  };

  return wallets === "Google Wallet" ? googlePassData : passData;
};

export default useAllPassData;

