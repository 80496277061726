import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  resetPassDetails,
  setPassThemeInfo,
} from "../Store/Slices/passDetailsSlice";
import Header from "../components/Layouts/Header";
import Drafts from "./../exampleData/draft.json";
import { WalletContext } from "../Context/WalletProvider";
import { useData } from "../Context/WalletFormProvider";
import { resetPrimaryFormsData } from "../Store/Slices/primaryFormsDataSlice";
import {
  addOrUpdateRowData,
  resetGoogleRowState,
} from "../Store/Slices/GoogleWalletData/Fields/googleFieldsRow";
import { resetGoogleFormsState } from "../Store/Slices/GoogleWalletData/allRowFormsData/googleWalletFormsData";
import { resetPresentFields } from "../Store/Slices/AddPresentFieldsSlice";
import { resetAdvancedFields } from "../Store/Slices/RemoveElementSlice";

const Draft = () => {
  const navigate = useNavigate();
  const { setActiveCardName, setMembershipAppleWalletBgColor } =
    useContext(WalletContext);
  const { setPrimaryFormData } = useData();
  const [visibleMenus, setVisibleMenus] = useState(
    Array(Drafts.length).fill(false)
  );
  const [allPassess, setAllPassess] = useState([]);
  const [loading, setLoading] = useState(true);
  const passTypeApple = allPassess?.filter(pass => pass.pass_type === "apple");
  const passTypeGoogle = allPassess?.filter(pass => pass.pass_type === "google");
  const user = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const getAllPassess = async () => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}get-generate-pass?user_id=${user?.id}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const comingRes = await response.json();
      setAllPassess(comingRes?.reverse());

      // savesecondaryFieldsWithDraftData(comingRes)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.id) {
      getAllPassess();
    }
  }, []);

  const handleDotsClick = (index) => {
    const newVisibleMenus = [...visibleMenus];
    newVisibleMenus[index] = !newVisibleMenus[index];
    setVisibleMenus(newVisibleMenus);
  };

  const handleCreateBtnClick = async () => {
    const payload = {
      logoImage: "",
      heroImage: "",
      color: "rgb(30, 49, 129)",
      labelColor: "rgb(255, 255, 255)",
      formate: "PKBarcodeFormatQR",
      barcodeValue: "",
      passTypeIdentifier: "",
      passId: "",
    };
    dispatch(setPassThemeInfo(payload));
    setActiveCardName("StoreCard");

    navigate("/wallet");
  };

  useEffect(() => {
    dispatch(resetPrimaryFormsData());
    setPrimaryFormData({});
    // redux
    dispatch(resetPresentFields());
    dispatch(resetAdvancedFields());
    dispatch(resetPassDetails());
    dispatch(resetGoogleRowState());
    dispatch(resetGoogleFormsState());
    dispatch(addOrUpdateRowData({ label: "Points", value: "Points", row: 1 }));
    setMembershipAppleWalletBgColor("rgb(248,212,25)");
  }, []);

  return (
    <>
      <Header />

      <div className="container custom py-5">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="section-heading">My Drafts</h2>
          </div>
        </div>
      </div>

      <div className="container custom">
        <div className="row align-items-start">

          <div className="col-lg-3">
            <div className="create-button" onClick={handleCreateBtnClick}>
              <p className="plus">+</p>
              <p className="text">create new</p>
            </div>
          </div>

          {loading ? (
            <div class="spinner-border text-primary draft-page" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          ) : (
            <div className="row divider">
              {passTypeApple.length >= 1 ? (
                <div className="col-lg-6">
                  <h2 className="mb-4">Apple Wallets</h2>
                  <div className="row">
                    {passTypeApple?.map((draft, index) => (
                      <div
                        className="col-lg-6"
                        key={index}
                        onClick={() => {
                          dispatch(setPassThemeInfo({ passId: draft?.pass_id }));
                        }}
                      >
                        {/* <span onClick={() => handleDotsClick(index)} className="s-dots">
                  ...
                </span> */}
                        <Link
                          to={`/wallet/${draft?.pass_id}/${draft?.pass_type}`}
                          className="draft-box"
                        >
                          <div className="d-flex justify-content-between align-items-end">
                            <ul className="d-flex justify-content-start align-items-center">
                              {/* <li>
                        <img
                          src="/assets/images/membership-card.svg"
                          alt="membership"
                        />
                      </li> */}
                              <li>
                                <p>{draft?.pass_type} Wallet</p>
                                {draft?.pass_type === "google" && (
                                  <p>{draft?.passDetails?.activeCardName}</p>
                                )}
                                {draft?.pass_type === "apple" && (
                                  <p>{Object.keys(draft)[0]}</p>
                                )}
                              </li>
                            </ul>
                            <div className="dots position-relative">
                              <ul
                                className={`position-absolute ${visibleMenus[index] ? "active" : ""
                                  }`}
                              >
                                <li>
                                  <Link to={"/"}>Edit</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <h3>ID:{draft?.pass_id}</h3>
                          {/* <h3>#{index + 1}</h3> */}
                          {/* <span>Created 2023/11/29 12:39</span> */}
                          {/* <span>Pass ID: {draft?.pass_id} </span> */}
                          <span>Draft pass</span>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              ) : <div className="col-lg-6">
                <div class="alert alert-warning" role="alert">
                  No apple wallets found! You need to create first...
                </div>
              </div>

              }

              {passTypeGoogle.length >= 1 ? (
                <div className="col-lg-6">
                  <h2 className="mb-4">Google Wallets</h2>
                  <div className="row">
                    {passTypeGoogle?.map((draft, index) => (
                      <div
                        className="col-lg-6"
                        key={index}
                        onClick={() => {
                          dispatch(setPassThemeInfo({ passId: draft?.pass_id }));
                        }}
                      >
                        {/* <span onClick={() => handleDotsClick(index)} className="s-dots">
                    ...
                  </span> */}
                        <Link
                          to={`/wallet/${draft?.pass_id}/${draft?.pass_type}`}
                          className="draft-box"
                        >
                          <div className="d-flex justify-content-between align-items-end">
                            <ul className="d-flex justify-content-start align-items-center">
                              {/* <li>
                          <img
                            src="/assets/images/membership-card.svg"
                            alt="membership"
                          />
                        </li> */}
                              <li>
                                <p>{draft?.pass_type} Wallet</p>
                                {draft?.pass_type === "google" && (
                                  <p>{draft?.passDetails?.activeCardName}</p>
                                )}
                                {draft?.pass_type === "apple" && (
                                  <p>{Object.keys(draft)[0]}</p>
                                )}
                              </li>
                            </ul>
                            <div className="dots position-relative">
                              <ul
                                className={`position-absolute ${visibleMenus[index] ? "active" : ""
                                  }`}
                              >
                                <li>
                                  <Link to={"/"}>Edit</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <h3>ID:{draft?.pass_id}</h3>
                          {/* <h3>#{index + 1}</h3> */}
                          {/* <span>Created 2023/11/29 12:39</span> */}
                          {/* <span>Pass ID: {draft?.pass_id} </span> */}
                          <span>Draft pass</span>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              ) : <div className="col-lg-6">
                <div class="alert alert-warning" role="alert">
                  No google wallets found! You need to create first...
                </div>
              </div>
              }
            </div>
          )}

        </div>
      </div>
    </>
  );
};

export default Draft;
