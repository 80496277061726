import React, { useContext, useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setPassThemeInfo } from "../../../Store/Slices/passDetailsSlice";
import { removeActiveMenu } from "../../../Store/Slices/activeMenuSlice";
import { WalletContext } from "../../../Context/WalletProvider";
import { setCouponPassThemeInfo } from "../../../Store/Slices/couponPassThemeInfo";
import { IoCloudUploadOutline } from "react-icons/io5";
import ImageCropper from "../../ImageCropper/ImageCropper";
import Swal from "sweetalert2";

const Theme = (props) => {
  const [data, setData] = useState("");
  const { logoImage, heroImage, color, labelColor } = useSelector(
    (state) => state?.passThemeDetails
  );
  const {
    activeCardName,
    setCouponColor,
    setMembershipAppleWalletBgColor,
    wallets,
  } = useContext(WalletContext);
  const logoImageInputRef = useRef(null);
  const heroImageInputRef = useRef(null);

  const dispatch = useDispatch();
  // props.formName(data);

  const handleLogoChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (!file.name.toLowerCase().endsWith(".png")) {
        toast.error("Please upload an image with a .png extension");
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const base64String = event.target.result;
          const payload = { logoImage: base64String };

          // Dispatch action based on activeCardName
          if (activeCardName === "Coupon") {
            dispatch(setCouponPassThemeInfo(payload));
            dispatch(setPassThemeInfo(payload));
          } else {
            dispatch(setPassThemeInfo(payload));
          }
          // const width = img.width;
          // const height = img.height;
          // if (
          //   activeCardName === "GenericPass" &&
          //   (width !== 120 || height !== 128)
          // ) {
          //   toast.error(
          //     "Please upload a logo with dimensions 120x128 and Image must be .png extension"
          //   );
          //   return;
          // } else {
          //   const base64String = event.target.result;
          //   const payload = { logoImage: base64String };

          //   // Dispatch action based on activeCardName
          //   if (activeCardName === "Coupon") {
          //     dispatch(setCouponPassThemeInfo(payload));
          //     dispatch(setPassThemeInfo(payload));
          //   } else {
          //     dispatch(setPassThemeInfo(payload));
          //   }
          // }
        };

        img.src = event.target.result;
      };

      reader.readAsDataURL(file);
    }
  };

  const handleHeroImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (!file.name.toLowerCase().endsWith(".png")) {
        toast.error("Please upload an image with a .png extension");
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();

        img.onload = () => {
          const width = img.width;
          const height = img.height;
          if (
            activeCardName === "GenericPass" &&
            (width > 120 || height > 128)
          ) {
            Swal.fire({
              title: "",
              text: "Please upload a image with dimensions 120x128 and image must be .png extension",
              icon: "info",
              confirmButtonText: "Okay",
            });
            return;
          }
          if (width > 410 || height > 128) {
            Swal.fire({
              title: "",
              text: "Please upload an image with dimensions 410x128 and image must be .png extension",
              icon: "info",
              confirmButtonText: "Okay",
            });
            return;
          } else {
            const base64String = event.target.result;
            const payload = { heroImage: base64String };
            if (activeCardName === "Coupon") {
              dispatch(setCouponPassThemeInfo(payload));
              dispatch(setPassThemeInfo(payload));
            } else {
              dispatch(setPassThemeInfo(payload));
            }
          }
        };

        img.src = event.target.result;
      };

      reader.readAsDataURL(file);
    }
  };

  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgb(${r}, ${g}, ${b})`;
  };

  const handleColorChange = (e) => {
    const hexColor = e.target.value;
    const rgbColor = hexToRgb(hexColor);
    const payload = { color: rgbColor };

    if (activeCardName === "Coupon") {
      setCouponColor(rgbColor);
      const payload = { color: rgbColor };
      // dispatch(setCouponPassThemeInfo(payload));
      dispatch(setPassThemeInfo(payload));
    } else if (activeCardName === "GenericPass") {
      setMembershipAppleWalletBgColor(rgbColor);
      const payload = { color: rgbColor };
      dispatch(setPassThemeInfo(payload));
    } else {
      dispatch(setPassThemeInfo(payload));
    }
  };
  const handleLabelColorChange = (e) => {
    const hexColor = e.target.value;
    const rgbColor = hexToRgb(hexColor);
    const payload = { labelColor: rgbColor };
    if (activeCardName === "Coupon") {
      // setCouponColor(rgbColor);
      dispatch(setCouponPassThemeInfo(payload));
      dispatch(setPassThemeInfo(payload));
    } else {
      dispatch(setPassThemeInfo(payload));
    }
  };

  const handleRemoveImageOnClick = () => {
    const payload = { heroImage: "empty" };
    if (activeCardName === "Coupon") {
      dispatch(setCouponPassThemeInfo(payload));
    } else {
      dispatch(setPassThemeInfo(payload));
    }
  };

  useEffect(() => {
    logoImageInputRef.current.value = "";
    heroImageInputRef.current.value = "";
  }, [activeCardName]);

  return (
    <>
      <div className="sidebar">
        {/* <div>
          <ImageCropper />
        </div> */}
        <div className="action-area d-flex justify-content-between align-items-center">
          <span>Theme</span>
          {/* <img
            src="/assets/images/close.svg"
            alt="close"
            className="close"
            onClick={() => {
              dispatch(removeActiveMenu());
            }}
          /> */}
        </div>
        <div className="sidebar-theme">
          <form>
            <h5>Logo</h5>
            <p>
              Recommended dimensions is 160 by 50 and must be .png extension.
            </p>
            {/* <div className="logo-box">
              {logoImage ? (
                <img
                  src={logoImage}
                  alt="sidebar-logo"
                  className="sidebar-logo"
                />
              ) : (
                <img
                  src="/assets/images/logo.svg"
                  alt="sidebar-logo"
                  className="sidebar-logo"
                />
              )}
              <input type="file" onChange={handleLogoChange} />
            </div> */}
            <div className={`logo-box d-flex flex-column`}>
              <div
                className="rounded-circle overflow-hidden d-flex justify-content-center align-items-center"
                style={{
                  width: "90px",
                  height: "90px",
                  border: "1px solid #ccc",
                }}
              >
                {logoImage ? (
                  <img
                    src={logoImage}
                    alt="sidebar-logo"
                    className="sidebar-logo"
                  />
                ) : (
                  <img
                    src="/assets/images/sidebar-logo.svg"
                    alt="sidebar-logo"
                    className="sidebar-logo"
                  />
                )}
              </div>

              <label
                htmlFor="logoImageInput"
                className="text-black font-bold border border-gray-500 p-1 rounded mb-4 mt-2"
                style={{ width: "139px" }}
              >
                Upload Photo
                <IoCloudUploadOutline className="mx-2" />
              </label>
              <input
                type="file"
                id="logoImageInput"
                accept="image/png"
                onChange={handleLogoChange}
                style={{ display: "none" }}
                ref={logoImageInputRef}
              />
            </div>

            {/*  */}
            <h5>Color</h5>
            <p>Theme Color</p>
            <div className="color-box d-flex justify-content-start align-items-center">
              <Form.Control
                type="color"
                id="color"
                defaultValue={color ? color : "#1E3181"}
                title="Choose your color"
                onChange={handleColorChange}
              />
              <Form.Label htmlFor="color">
                <img
                  src="/assets/images/color-btn.svg"
                  alt="color-btn"
                  className="color-btn"
                />
              </Form.Label>
            </div>
            <p>Label Color</p>
            <div className="color-box d-flex justify-content-start align-items-center">
              <Form.Control
                type="color"
                id="labelColor"
                defaultValue={labelColor ? labelColor : "#ffffff"}
                title="Choose your color"
                onChange={handleLabelColorChange}
              />
              <Form.Label htmlFor="labelColor">
                <img
                  src="/assets/images/color-btn.svg"
                  alt="color-btn"
                  className="color-btn"
                />
              </Form.Label>
            </div>
            <h5>Hero Image (Optional)</h5>
            <p className="">
              Add a hero image to give customizers an idea of this program.
              Recommended dimensions: 410 by 128 and 120 by 128 for Generic
              Pass, and it must be a .png extension.
            </p>

            {/* <div
              className={`${
                activeCardName === "GenericPass"
                  ? "hero-image-box-generic"
                  : "hero-image-box"
              }`}
            >
              {heroImage ? (
                <img
                  src={heroImage}
                  alt="sidebar-logo"
                  className="hero-image"
                />
              ) : (
                <img
                  src="/assets/images/hero-image.png"
                  alt="sidebar-logo"
                  className="hero-image"
                />
              )}
              <input
                type="file"
                // id="smallPNGImageInput"
                // accept="image/png"
                onChange={handleHeroImageChange}
              />
            </div> */}
            <div
              className={`${
                activeCardName === "GenericPass"
                  ? "hero-image-box-generic"
                  : "hero-image-box"
              }`}
            >
              {heroImage ? (
                <img
                  src={heroImage}
                  alt="hero-image"
                  className="hero-image relative"
                />
              ) : (
                <img
                  src="/assets/images/hero-image.png"
                  alt="hero-image"
                  className="hero-image relative"
                />
              )}
              <label
                htmlFor="customHeroImageInput"
                className="text-black font-bold border border-gray-500 p-1 rounded"
              >
                Upload Photo
                <IoCloudUploadOutline className="mx-2" />
              </label>
              <input
                type="file"
                id="customHeroImageInput"
                accept="image/png"
                onChange={handleHeroImageChange}
                style={{ display: "none" }}
                ref={heroImageInputRef}
              />
            </div>
            <button
              type="button"
              className="d-flex justify-content-center align-items-center"
              onClick={handleRemoveImageOnClick}
            >
              <img src="/assets/images/remove.svg" alt="remove" /> Remove Image
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Theme;
