import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const secondaryFormsDataSlice = createSlice({
  name: "secondaryFormsData",
  initialState,
  reducers: {
    addOrUpdateSecondaryFormsData: (state, action) => {
      const newObject = action.payload;
      const existingObjectIndex = state.findIndex(
        (obj) => obj.id === newObject.id
      );

      if (existingObjectIndex !== -1) {
        // If the object already exists, update it
        return state.map((obj, index) =>
          index === existingObjectIndex ? { ...obj, ...newObject } : obj
        );
      } else {
        // If the object doesn't exist, add it to the array
        return [...state, newObject];
      }
    },
  },
});

export const { addOrUpdateSecondaryFormsData } =
  secondaryFormsDataSlice.actions;
export default secondaryFormsDataSlice.reducer;
