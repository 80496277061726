import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const singleFormLabelSlice = createSlice({
  name: "menuActive",
  initialState,
  reducers: {
    addSingleFormLabel: (state, action) => {
      const elementToAdd = action.payload;
      const existingElement = state.find(
        (element) => element.formId === elementToAdd.formId
      );
      if (existingElement) {
        existingElement.label = elementToAdd.label;
      } else {
        state.push(elementToAdd);
      }
    },
    removePresentFieldElement: (state, action) => {
      const elementToRemove = action.payload;
      return state.filter((element) => element !== elementToRemove);
    },
  },
});

export const { addSingleFormLabel, removePresentFieldElement } =
  singleFormLabelSlice.actions;
export default singleFormLabelSlice.reducer;
