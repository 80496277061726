import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useData } from "../../Context/WalletFormProvider";
import { WalletContext } from "../../Context/WalletProvider";
import { setDynamicLabel } from "../../Store/Slices/WalletSlice";
import { addPrimaryFormsData } from "../../Store/Slices/primaryFormsDataSlice";
import { addOrUpdateAuxiliaryField } from "../../Store/Slices/auxiliaryFormsDataSlice.jsx";
import { isInSecondaryFields } from "../../utils/ckeckIsInPrimaryField.jsx";
import { isInAuxiliaryFields } from "../../utils/chackIsInAuxiliaryField.jsx";
import { isThisRowContainThisForm } from "../../utils/checkRowFormName.jsx";
import { storeGoogleFormsData } from "../../Store/Slices/GoogleWalletData/allRowFormsData/googleWalletFormsData.jsx";
import { updateGoogleRowLabel } from "../../Store/Slices/GoogleWalletData/Fields/googleFieldsRow.jsx";

const OptOut = () => {
  const {
    removeForm,
    setFormName,
    setActiveField,
    setPrimaryFields,
    primaryFields,
    auxFields,
    setAuxFields,
    setSecondaryFields,
    secondaryFields,
    wallets,
    formInputs,
    setFormInputs,
  } = useContext(WalletContext);
  const { firstRow, secondRow, thirdRow, fourthRow } = useSelector(
    (state) => state.googleWalletFieldsRow
  );
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const { primaryFormsData, googleWalletData } = useSelector((state) => state);
  const { firstRowData, secondRowData, thirdRowData, fourthRowData } =
    googleWalletData;
  const allRowsData = [
    firstRowData,
    secondRowData,
    thirdRowData,
    fourthRowData,
  ];
  const [defaulDisplayValue, setDefaulDisplayValue] = useState("");
  const { addOrUpdateSecondaryFormData, setPrimaryFormData } = useData();
  const [optOut, setOptOut] = useState({
    id: 15,
    label: {
      label: "Label",
      value: "",
      baseValue: "Opt out",
    },
    displayValue: {
      label: "Display Value",
      value: "",
    },
  });

  const handleLabelChange = (e) => {
    const value = e.target.value;
    const payload = { form: "Opt out", value };
    dispatch(setDynamicLabel(payload));
    dispatch(updateGoogleRowLabel(payload));

    //
    if (!value) {
      setFormName("Opt out");
      setActiveField("Opt out");
    }
    const updatedPrimaryFields =
      primaryFields.label === "Opt out"
        ? { ...primaryFields, value }
        : primaryFields;
    setPrimaryFields(updatedPrimaryFields);

    const updatedAuxiliaryFields = auxFields.map((field) =>
      field.label === "Opt out" ? { ...field, value } : field
    );
    setAuxFields(updatedAuxiliaryFields);

    const updatedFormInputs = formInputs.map((field) =>
      field.label === "Opt out" ? { ...field, value } : field
    );
    setFormInputs(updatedFormInputs);

    const updatedsecondaryFields = secondaryFields.map((field) =>
      field.label === "Opt out" ? { ...field, value } : field
    );
    setSecondaryFields(updatedsecondaryFields);
    setActiveField(value);
    //

    setOptOut((prevFormData) => ({
      ...prevFormData,
      label: {
        ...prevFormData.label,
        value: value,
      },
    }));
  };
  const handleDisplayValueChange = (e) => {
    const value = e.target.value;
    const payload = { formId: 15, displayValue: value };
    dispatch(addPrimaryFormsData(payload));
    setOptOut((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: value,
      },
    }));
  };
  useEffect(() => {
    if (wallets === "Google Wallet") {
      setDataInGoogleStore();
    } else {
      if (isInSecondaryFields(secondaryFields, "Opt out")) {
        addOrUpdateSecondaryFormData(optOut);
      } else if (isInAuxiliaryFields(auxFields, "Opt out")) {
        dispatch(addOrUpdateAuxiliaryField(optOut));
      } else if (primaryFields?.label === "Opt out") {
        setPrimaryFormData(optOut);
      }
    }
  }, [optOut]);

  useEffect(() => {
    if (wallets === "Google Wallet") {
      const matchingElement = allRowsData
        .flatMap((rowData) => rowData)
        .find((element) => element?.id === 15);
      setDefaulDisplayValue(matchingElement?.displayValue?.value);
    } else {
      const matchingElement = primaryFormsData.find(
        (element) => element?.formId === 15
      );
      setDefaulDisplayValue(matchingElement?.displayValue);
    }
  }, []);
  // default display value
  useEffect(() => {
    setOptOut((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: defaulDisplayValue ? defaulDisplayValue : "",
      },
      label: {
        ...prevFormData.label,
        value: wallet?.optOut,
      },
    }));
  }, [defaulDisplayValue, wallet?.optOut]);
  //
  const setDataInGoogleStore = () => {
    if (isThisRowContainThisForm(firstRow, "Opt out")) {
      const payload = { formData: optOut, row: 1 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(secondRow, "Opt out")) {
      const payload = { formData: optOut, row: 2 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(thirdRow, "Opt out")) {
      const payload = { formData: optOut, row: 3 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(fourthRow, "Opt out")) {
      const payload = { formData: optOut, row: 4 };
      dispatch(storeGoogleFormsData(payload));
    }
  };

  return (
    <>
      <div className="sidebar">
        <div className="action-area d-flex justify-content-between align-items-center">
          <span>Opt Out</span>
          <div>
            <img
              src="/assets/images/delete.svg"
              alt="delete"
              className="delete"
              onClick={() => removeForm("Opt out", 15)}
            />
            <img
              src="/assets/images/close.svg"
              alt="close"
              className="close"
              onClick={() => setFormName("")}
            />
          </div>
        </div>
        <div className="form-area">
          <div className="mb-4">
            <label htmlFor="optOut" className="form-label">
              Label
            </label>
            <input
              type="text"
              className="form-control"
              name="optOut"
              id="optOut"
              defaultValue={wallet?.optOut}
              onChange={handleLabelChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="displayValue" className="form-label">
              Display Value
            </label>
            <input
              type="text"
              className="form-control"
              name="displayValue"
              id="displayValue"
              defaultValue={defaulDisplayValue}
              onChange={handleDisplayValueChange}
            />
            <span className="input-bottom">
              {" "}
              <FontAwesomeIcon icon={faCircleQuestion} /> Static text on the
              customer pass
            </span>
          </div>
          <div className="label-text">
            <label className="form-label">Field Key</label>
            <span className="input-bottom">person.optOut</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default OptOut;
