import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faXmark } from "@fortawesome/free-solid-svg-icons";
import { WalletContext } from "../../Context/WalletProvider";
import { useData } from "../../Context/WalletFormProvider";
import { useSelector, useDispatch } from "react-redux";
import { setDynamicLabel } from "../../Store/Slices/WalletSlice.jsx";
import { addPrimaryFormsData } from "../../Store/Slices/primaryFormsDataSlice.jsx";
import { isThisRowContainThisForm } from "../../utils/checkRowFormName.jsx";
import { updateGoogleRowLabel } from "../../Store/Slices/GoogleWalletData/Fields/googleFieldsRow.jsx";
import { storeGoogleFormsData } from "../../Store/Slices/GoogleWalletData/allRowFormsData/googleWalletFormsData.jsx";

const ExpiryDate = () => {
  const {
    removeForm,
    setFormName,
    activeField,
    setActiveField,
    setSecondaryFields,
    secondaryFields,
    activeCardName,
    auxFields,
    setAuxFields,
    wallets,
    formInputs,
    setFormInputs,
  } = useContext(WalletContext);
  const { googleWalletFieldsRow } = useSelector((state) => state);
  const { firstRow, secondRow, thirdRow, fourthRow } = googleWalletFieldsRow;
  const { addOrUpdateSecondaryFormData, setHeaderFields } = useData();
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const { primaryFormsData, googleWalletData } = useSelector((state) => state);
  const { firstRowData, secondRowData, thirdRowData, fourthRowData } =
    googleWalletData;
  const allRowsData = [
    firstRowData,
    secondRowData,
    thirdRowData,
    fourthRowData,
  ];  const [defaulDisplayValue, setDefaulDisplayValue] = useState("");
  const [expirySettings, setExpirySettings] = useState("");

  const [expiryDate, setExpiryDate] = useState({
    id: 4,
    expirySettings: {
      label: "Expiry Settings",
      value: "Not Expired",
    },
    displayValue: {
      label: "Date Format",
      value: "Not Expired",
    },
    label: {
      label: "Label",
      value: "Expiry Date",
      baseValue: "Expiry Date",
    },
  });
  // // time foormationg
  const providedDate = new Date("2024-01-11");
  providedDate.setHours(0, 0, 0, 0);
  const currentTimeInUtc = new Date().toLocaleTimeString("en-US", {
    timeZone: "UTC",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  const timeZoneOffsetMinutes = new Date().getTimezoneOffset();
  const timeZoneOffsetHours = Math.abs(timeZoneOffsetMinutes) / 60;
  const timeZoneOffsetString = `${timeZoneOffsetHours >= 0 ? "+" : "-"}${String(
    timeZoneOffsetHours
  ).padStart(2, "0")}:00`;
  // // formated
  const handleexpirySettingsChange = (e) => {
    const value = e.target.value;
    setExpirySettings(value);
    setExpiryDate((prevFormData) => ({
      ...prevFormData,
      expirySettings: {
        ...prevFormData.expirySettings,
        value: value,
      },
    }));
    if (expirySettings === "expires") {
      setExpiryDate((prevFormData) => ({
        ...prevFormData,
        displayValue: {
          ...prevFormData?.displayValue,
          value: "Not Expired",
        },
      }));
    }
  };
  const handledisplayValueChange = (e) => {
    const value = `${e.target.value}T${currentTimeInUtc}${timeZoneOffsetString}`;
    // const value = e.target.value;
    const payload = { formId: 4, displayValue: value };
    dispatch(addPrimaryFormsData(payload));
    setExpiryDate((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: value ? value : "Not Expired",
      },
    }));
  };
  const handleLabelChange = (e) => {
    const value = e.target.value;
    const payload = { form: "Expiry Date", value };
    dispatch(setDynamicLabel(payload));
    dispatch(updateGoogleRowLabel(payload));

    // setFormName(value);
    //
    if (!value) {
      setFormName("Expiry Date");
      setActiveField("Expiry Date");
    }
    const updatedsecondaryFields = secondaryFields.map((field) =>
      field?.label === "Expiry Date" ? { ...field, value } : field
    );
    // Update the primaryFields array with the modified element
    const updatedAuxiliaryFields = auxFields.map((field) =>
      field?.label === "Expiry Date" ? { ...field, value } : field
    );
    setAuxFields(updatedAuxiliaryFields);

    const updatedFormInputs = formInputs.map((field) =>
      field.label === "Expiry Date" ? { ...field, value } : field
    );
    setFormInputs(updatedFormInputs);

    // Update the secondaryFields array with the modified element
    setSecondaryFields(updatedsecondaryFields);
    setActiveField(value);
    //
    setExpiryDate((prevFormData) => ({
      ...prevFormData,
      label: {
        ...prevFormData?.label,
        value: value,
      },
    }));
  };

  useEffect(() => {
    if (wallets === "Google Wallet") {
      setDataInGoogleStore();
    } else {
      if (activeCardName === "Coupon") {
        setHeaderFields({ expiryDate });
      } else {
        addOrUpdateSecondaryFormData(expiryDate);
      }
    }
  }, [expiryDate]);

  useEffect(() => {
    if (wallets === "Google Wallet") {
      const matchingElement = allRowsData
        .flatMap((rowData) => rowData)
        .find((element) => element?.id === 4);
      setDefaulDisplayValue(matchingElement?.displayValue?.value);
    } else {
      const matchingElement = primaryFormsData.find(
        (element) => element?.formId === 4
      );
      setDefaulDisplayValue(matchingElement?.displayValue);
    }
  }, []);
 // default display value
 useEffect(() => {
  setExpiryDate((prevFormData) => ({
    ...prevFormData,
    displayValue: {
      ...prevFormData?.displayValue,
      value: defaulDisplayValue? defaulDisplayValue : "",
    },
    label: {
      ...prevFormData.label,
      value: wallet?.expiryDate,
    },
  }));
}, [defaulDisplayValue, wallet?.expiryDate]);
  //
  const setDataInGoogleStore = () => {
    if (isThisRowContainThisForm(firstRow, "Expiry Date")) {
      const payload = { formData: expiryDate, row: 1 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(secondRow, "Expiry Date")) {
      const payload = { formData: expiryDate, row: 2 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(thirdRow, "Expiry Date")) {
      const payload = { formData: expiryDate, row: 3 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(fourthRow, "Expiry Date")) {
      const payload = { formData: expiryDate, row: 4 };
      dispatch(storeGoogleFormsData(payload));
    }
  };

  return (
    <>
      <div className="sidebar">
        <div className="action-area d-flex justify-content-between align-items-center">
          <span>Expiry Date</span>
          <div>
            <img
              src="/assets/images/delete.svg"
              alt="delete"
              className="delete"
              onClick={() => removeForm("Expiry Date", 4)}
            />
            <img
              src="/assets/images/close.svg"
              alt="close"
              className="close"
              onClick={() => setFormName("")}
            />
          </div>
        </div>
        <div className="form-area">
          <p>Expiry settings (apple and google)</p>
          <div className="mb-4">
            <label htmlFor="expirySettings" className="form-label">
              Expiry Settings
            </label>
            <select
              className="form-select"
              id="expirySettings"
              onChange={handleexpirySettingsChange}
            >
              <option value="notExpired">Does not Expire</option>
              <option value="expires">Expires on a fixed date</option>
            </select>
          </div>

          {(expirySettings === "expires" || defaulDisplayValue) && (
            <div className="mb-4">
              <label htmlFor="displayValue" className="form-label">
                Expiry Date
              </label>
              <input
                type="date"
                className="form-control"
                name="displayValue"
                id="displayValue"
                defaultValue={defaulDisplayValue}
                onChange={handledisplayValueChange}
              />
            </div>
          )}

          <div className="mb-4">
            <label htmlFor="label" className="form-label">
              Label
            </label>
            <input
              type="text"
              className="form-control"
              name="label"
              id="label"
              defaultValue={wallet?.expiryDate}
              onChange={handleLabelChange}
            />
          </div>
          
          <div className="label-text">
            <label className="form-label">Field Key</label>
            <span className="input-bottom">universal.expiryDate</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpiryDate;
