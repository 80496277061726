import React, { useContext, useEffect } from "react";
import Header from "../components/Layouts/Header";
import Wallet from "../components/Wallet";
import { useLoaderData } from "react-router-dom";
import { WalletContext } from "../Context/WalletProvider";

const Home = () => {
  const { activeCardName, setActiveCardName, setWallets } =
    useContext(WalletContext);
  const passDetailsDraft = useLoaderData();

  useEffect(() => {
    if (passDetailsDraft?.pass_type === "apple") {
      setWallets("Apple Wallet");
      const passType = Object.keys(passDetailsDraft)[0];
      setActiveCardName(passType);
    } else if (passDetailsDraft?.pass_type === "google") {
      setWallets("Google Wallet");
      setActiveCardName(passDetailsDraft?.passDetails?.activeCardName);
    } else {
      setWallets("Google Wallet");
      setActiveCardName("StoreCard");
    }
  }, []);

  return (
    <>
      <Header />
      <Wallet />
    </>
  );
};

export default Home;
