import { createSlice } from "@reduxjs/toolkit";

const initialState = [
  "First Name",
  "Last Name",
  "Mobile Number",
  "Name",
  "Gender",
  "Date of Birth",
  "Tier",
  "Salutation",
  "Suffix",
  "Legal",
  "Opt in",
  "Opt out",
  "External ID",
  "Tier Points",
  "Secondary Points",
  "Member Status",
  "Grouping Identifier",
  "Expiry Date",
  "Email",
  "Information",
];

const removeElementSlice = createSlice({
  name: "removeElement",
  initialState,
  reducers: {
    removeElement: (state, action) => {
      const elementToRemove = action.payload;
      return state.filter((element) => element !== elementToRemove);
    },
    resetAdvancedFields: () => [...initialState],
  },
});

export const { removeElement, resetAdvancedFields } =
  removeElementSlice.actions;
export default removeElementSlice.reducer;
