import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch ,useSelector} from "react-redux";
import { useData } from "../../Context/WalletFormProvider";
import { WalletContext } from "../../Context/WalletProvider";
import { setDynamicLabel } from "../../Store/Slices/WalletSlice";
import { addPrimaryFormsData } from "../../Store/Slices/primaryFormsDataSlice";
import { addOrUpdateAuxiliaryField } from "../../Store/Slices/auxiliaryFormsDataSlice.jsx";
import { isInSecondaryFields } from "../../utils/ckeckIsInPrimaryField.jsx";
import { isInAuxiliaryFields } from "../../utils/chackIsInAuxiliaryField.jsx";
import { isThisRowContainThisForm } from "../../utils/checkRowFormName.jsx";
import { storeGoogleFormsData } from "../../Store/Slices/GoogleWalletData/allRowFormsData/googleWalletFormsData.jsx";
import { updateGoogleRowLabel } from "../../Store/Slices/GoogleWalletData/Fields/googleFieldsRow.jsx";
const Birthday = () => {
  const {
    removeForm,
    setFormName,
    setActiveField,
    primaryFields,
    setPrimaryFields,
    auxFields, 
    setAuxFields,
    setSecondaryFields,
    secondaryFields,
    wallets,
    formInputs,
    setFormInputs,
  } = useContext(WalletContext);
  const { googleWalletFieldsRow,googleWalletData } = useSelector((state) => state);
  const { firstRow, secondRow, thirdRow, fourthRow } = googleWalletFieldsRow;
  const { firstRowData, secondRowData, thirdRowData, fourthRowData } =
  googleWalletData;
const allRowsData = [
  firstRowData,
  secondRowData,
  thirdRowData,
  fourthRowData,
];
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const primaryFormsData = useSelector((state) => state.primaryFormsData);
  const [defaulDisplayValue, setDefaulDisplayValue] = useState("");

  const { addOrUpdateSecondaryFormData, setPrimaryFormData } = useData();
  const [dateOfBirth, setDateOfBirth] = useState({
    id: 1,
    label: {
      label: "Date of Birth",
      value: "",
      baseValue: "Date of Birth",
    },
    displayValue: {
      label: "Display Value",
      value: "",
    },
  });
  const handleLabelChange = (e) => {
    const value = e.target.value;
    const payload = { form: "Date of Birth", value };
    dispatch(setDynamicLabel(payload));
    dispatch(updateGoogleRowLabel(payload));

    //
    if (!value) {
      setFormName("Date of Birth");
      setActiveField("Date of Birth");
    }
    const updatedPrimaryField = primaryFields?.label === "Date of Birth" && { label:value, value:primaryFields.value};
    setPrimaryFields(updatedPrimaryField);

    const updatedAuxiliaryFields = auxFields.map((field) =>
      field?.label === "Date of Birth" ? { ...field, value } : field
    );
    setAuxFields(updatedAuxiliaryFields);

    const updatedFormInputs = formInputs.map((field) =>
      field.label === "Date of Birth" ? { ...field, value } : field
    );
    setFormInputs(updatedFormInputs);
    
    const updatedsecondaryFields = secondaryFields.map((field) =>
      field?.label === "Date of Birth" ? { ...field, value } : field
    );
    setSecondaryFields(updatedsecondaryFields);
    setActiveField(value);
    //
    setDateOfBirth((prevFormData) => ({
      ...prevFormData,
      label: {
        ...prevFormData?.label,
        value: value,
      },
    }));
  };
  const handleDisplayValueChange = (e) => {
    const value = e.target.value;
    const payload = { formId: 1, displayValue: value };
    dispatch(addPrimaryFormsData(payload));
    setDateOfBirth((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: value,
      },
    }));
  };
  useEffect(() => {
    if (wallets === "Google Wallet") {
      setDataInGoogleStore();
    } else {
      if (isInSecondaryFields(secondaryFields, "Date of Birth")) {
        addOrUpdateSecondaryFormData(dateOfBirth);
      } else if (isInAuxiliaryFields(auxFields, "Date of Birth")) {
        dispatch(addOrUpdateAuxiliaryField(dateOfBirth));
      }
      else if (primaryFields?.label === "Date of Birth") {
        setPrimaryFormData(dateOfBirth)
      }
    }

  }, [dateOfBirth]);
    //
    useEffect(() => {
      if (wallets === "Google Wallet") {
        const matchingElement = allRowsData
          .flatMap((rowData) => rowData)
          .find((element) => element?.id === 1);
        setDefaulDisplayValue(matchingElement?.displayValue?.value);
      } else {
        const matchingElement = primaryFormsData.find(
          (element) => element?.formId === 1
        );
        setDefaulDisplayValue(matchingElement?.displayValue);
      }
    }, []);
  // default display value
  useEffect(() => {
    setDateOfBirth((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: defaulDisplayValue? defaulDisplayValue : "",
      },
      label: {
        ...prevFormData.label,
        value: wallet?.dateOfBirth,
      },
    }));
  }, [defaulDisplayValue, wallet?.dateOfBirth]);
  //
  const setDataInGoogleStore = () => {
    if (isThisRowContainThisForm(firstRow, "Date of Birth")) {
      const payload = { formData: dateOfBirth, row: 1 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(secondRow, "Date of Birth")) {
      const payload = { formData: dateOfBirth, row: 2 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(thirdRow, "Date of Birth")) {
      const payload = { formData: dateOfBirth, row: 3 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(fourthRow, "Date of Birth")) {
      const payload = { formData: dateOfBirth, row: 4 };
      dispatch(storeGoogleFormsData(payload));
    }
  };

  return (
    <>
      <div className="sidebar">
        <div className="action-area d-flex justify-content-between align-items-center">
          <span>Date of Birth</span>
          <div>
            <img
              src="/assets/images/delete.svg"
              alt="delete"
              className="delete"
              onClick={() => removeForm("Date of Birth", 1)}
            />
            <img src="/assets/images/close.svg" alt="close" className="close" onClick={() => setFormName("")} />
          </div>
        </div>
        <div className="form-area">

          <div className="mb-4">
            <label htmlFor="label" className="form-label">
              Label
            </label>
            <input
              type="text"
              className="form-control"
              name="label"
              id="label"
              defaultValue={wallet?.dateOfBirth}
              onChange={handleLabelChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="displayValue" className="form-label">
              Display Value
            </label>
            <input
              type="text"
              className="form-control"
              name="displayValue"
              id="displayValue"
              defaultValue={defaulDisplayValue}
              onChange={handleDisplayValueChange}
            />
            <span className="input-bottom">
              {" "}
              <FontAwesomeIcon icon={faCircleQuestion} /> Static text on the
              customer pass
            </span>
          </div>
          <div className="label-text">
            <label htmlFor="programName" className="form-label">
              Field Key
            </label>
            <span className="input-bottom">meta.dateOfBirth</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Birthday;
