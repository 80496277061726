import { faCircleQuestion, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useData } from "../../Context/WalletFormProvider";
import { WalletContext } from "../../Context/WalletProvider";
import { setDynamicLabel } from "../../Store/Slices/WalletSlice";
import { addSingleFormLabel } from "../../Store/Slices/SingleFormLabelSlice";
import { addPrimaryFormsData } from "../../Store/Slices/primaryFormsDataSlice";
import { addOrUpdateAuxiliaryField } from "../../Store/Slices/auxiliaryFormsDataSlice.jsx";
import { isInSecondaryFields } from "../../utils/ckeckIsInPrimaryField.jsx";
import { isInAuxiliaryFields } from "../../utils/chackIsInAuxiliaryField.jsx";
import { isThisRowContainThisForm } from "../../utils/checkRowFormName.jsx";
import { storeGoogleFormsData } from "../../Store/Slices/GoogleWalletData/allRowFormsData/googleWalletFormsData.jsx";
import { updateGoogleRowLabel } from "../../Store/Slices/GoogleWalletData/Fields/googleFieldsRow.jsx";

const DynamicField = () => {
  const {
    removeForm,
    setFormName,
    setActiveField,
    setPrimaryFields,
    primaryFields,
    auxFields,
    setAuxFields,
    setSecondaryFields,
    secondaryFields,
    wallets,
    formInputs,
    setFormInputs,
  } = useContext(WalletContext);
  const dispatch = useDispatch();
  const { firstRow, secondRow, thirdRow, fourthRow } = useSelector(
    (state) => state.googleWalletFieldsRow
  );
  const {
    addOrUpdateSecondaryFormData,
    newFieldLabel,
    label,
    setPrimaryFormData,
    setNewFieldLabel,
  } = useData();
  const { primaryFormsData, googleWalletData } = useSelector((state) => state);
  const { firstRowData, secondRowData, thirdRowData, fourthRowData } =
    googleWalletData;
  const allRowsData = [
    firstRowData,
    secondRowData,
    thirdRowData,
    fourthRowData,
  ];
  const [defaulDisplayValue, setDefaulDisplayValue] = useState("");
  const [renderSetterFunc, setRenderSetterFunc] = useState("");
  const [newLabel, setNewLabel] = useState(label);

  const [dynamicFields, setDynamicFields] = useState({
    id: newFieldLabel,
    label: {
      label: "Label",
      value: newLabel,

      baseValue: newFieldLabel,
    },
    displayValue: {
      label: "Display Value",
      value: "",
    },
  });


  const handleLabelChange = (e) => {
    const value = e.target.value;
    setNewLabel(value);
    setRenderSetterFunc(value);
    const payload = { form: newFieldLabel, value };
    // new dynamic field------
    const labelPayload = { formId: newFieldLabel, label: value };
    dispatch(addSingleFormLabel(labelPayload));
    //----------
    dispatch(setDynamicLabel(payload));
    dispatch(updateGoogleRowLabel(payload));

    //
    if (!value) {
      setFormName(newFieldLabel);
      setActiveField(newFieldLabel);
    }
    const updatedsecondaryFields = secondaryFields.map((field) =>
      field.label === newFieldLabel ? { ...field, value } : field
    );
    const updatedAuxiliaryFields = auxFields.map((field) =>
      field.label === newFieldLabel ? { ...field, value } : field
    );
    setAuxFields(updatedAuxiliaryFields);

    const updatedFormInputs = formInputs.map((field) =>
      field.label === newFieldLabel ? { ...field, value } : field
    );
    setFormInputs(updatedFormInputs);

    setSecondaryFields(updatedsecondaryFields);
    setActiveField(value);
    //
    const updatedPrimaryFields =
      primaryFields.label === newFieldLabel
        ? { ...primaryFields, value }
        : primaryFields;
    setPrimaryFields(updatedPrimaryFields);
    //
    setDynamicFields((prevFormData) => ({
      ...prevFormData,
      label: {
        ...prevFormData.label,
        value: value,
      },
    }));
  };

  const handledDisplayValueChange = (e) => {
    const value = e.target.value;
    setDefaulDisplayValue(value);
    setRenderSetterFunc(value);
    const payload = {
      formId: newFieldLabel,
      displayValue: value,
      label: dynamicFields?.label?.value,
    };
    dispatch(addPrimaryFormsData(payload));
    setDynamicFields((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: value,
      },
    }));
  };

  useEffect(() => {
    if (wallets === "Google Wallet") {
      setDataInGoogleStore();
    } else {
      if (isInSecondaryFields(secondaryFields, newFieldLabel)) {
        addOrUpdateSecondaryFormData(dynamicFields);
      } else if (isInAuxiliaryFields(auxFields, newFieldLabel)) {
        dispatch(addOrUpdateAuxiliaryField(dynamicFields));
      } else if (primaryFields?.label === newFieldLabel) {
        setPrimaryFormData(dynamicFields);
      }
    }
  }, [renderSetterFunc]);
  //
  const setDataInGoogleStore = () => {
    if (isThisRowContainThisForm(firstRow, newFieldLabel)) {
      const payload = { formData: dynamicFields, row: 1 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(secondRow, newFieldLabel)) {
      const payload = { formData: dynamicFields, row: 2 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(thirdRow, newFieldLabel)) {
      const payload = { formData: dynamicFields, row: 3 };
      dispatch(storeGoogleFormsData(payload));
    }
    if (isThisRowContainThisForm(fourthRow, newFieldLabel)) {
      const payload = { formData: dynamicFields, row: 4 };
      dispatch(storeGoogleFormsData(payload));
    }
  };
  //
  useEffect(() => {
    setDynamicFields((prevFormData) => ({
      ...prevFormData,
      id: newFieldLabel,
      label: {
        ...prevFormData.label,
        value: newLabel,
      },
      displayValue: {
        ...prevFormData?.displayValue,
        value: defaulDisplayValue ? defaulDisplayValue : "",
      },
    }));
  }, [newLabel]);
  //Change base value
  useEffect(() => {
    setDynamicFields((prevFormData) => ({
      ...prevFormData,
      label: {
        ...prevFormData.label,
        baseValue: newFieldLabel,
      },
    }));
  }, [newFieldLabel]);

  // for baseValue
  useEffect(() => {
    if (wallets === "Google Wallet") {
      const matchingElement = allRowsData
        .flatMap((rowData) => rowData)
        .find((element) => element?.id === newFieldLabel);
      setDefaulDisplayValue(matchingElement?.displayValue?.value);
    } else {
      const matchingElement = primaryFormsData.find(
        (element) => element?.formId === newFieldLabel
      );
      if (matchingElement) {
        setDefaulDisplayValue(matchingElement?.displayValue);
      } else {
        setDefaulDisplayValue("");
      }
    }
    setNewLabel(label);
  }, [label, newFieldLabel]);
  //
  // default display value
  useEffect(() => {
    setDynamicFields((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: defaulDisplayValue ? defaulDisplayValue : "",
      },
      label: {
        ...prevFormData.label,
        value: newLabel,
      },
    }));
  }, [defaulDisplayValue, newLabel, newFieldLabel]);
  return (
    <>
      <div className="sidebar">
        <div className="action-area d-flex justify-content-between align-items-center">
          <span>Custom Field</span>
          <div>
            <img
              src="/assets/images/delete.svg"
              alt="delete"
              className="delete"
              onClick={() => removeForm(newFieldLabel, newFieldLabel)}
            />
            <img
              src="/assets/images/close.svg"
              alt="close"
              className="close"
              onClick={() => { 
                setFormName("");
                 setNewFieldLabel("") 
                }}
            />
          </div>
        </div>
        <div className="form-area">
          {/* <div className="mb-4">
            <label htmlFor="fieldName" className="form-label">
              Field Name
            </label>
            <input
              type="text"
              className="form-control"
              name="fieldName"
              id="fieldName"
              placeholder="New data field xxx"
              onChange={handleFieldNameChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="fieldType" className="form-label">
              Field Type
            </label>
            <select
              className="form-select"
              id="fieldType"
              onChange={handleFieldTypeChange}
            >
              <option value="multi line text">Multi Line Text</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div> */}
          <div className="mb-4">
            <label htmlFor="label" className="form-label">
              Label
            </label>
            <input
              type="text"
              className="form-control"
              name="label"
              id="label"
              value={newLabel}
              onChange={handleLabelChange}
            />
          </div>
          <div className="caution-box d-flex justify-content-start align-items-start">
            <div className="left">
              <FontAwesomeIcon icon={faCircleQuestion} />
            </div>
            <div className="right">
              <div className="top d-flex justify-content-between align-items-center">
                <span>Caution</span>
                <FontAwesomeIcon icon={faXmark} />
              </div>
              <div className="bottom">
                <p>
                  The latest version of Google Wallet has updated formatting for
                  long text fields. Please review this help article if you see
                  any inconsistencies on Android devices.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="displayValue" className="form-label">
              Display Value
            </label>
            <input
              type="text"
              className="form-control"
              name="displayValue"
              id="displayValue"
              value={defaulDisplayValue}
              onChange={handledDisplayValueChange}
            />
            <span className="input-bottom">
              {" "}
              <FontAwesomeIcon icon={faCircleQuestion} /> Static text on the
              customer pass
            </span>
          </div>
          <div className="label-text">
            <label htmlFor="programName" className="form-label">
              Field Key
            </label>
            <span className="input-bottom">meta.newDataFieldxxx</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicField;
