import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const primaryFormsDataSlice = createSlice({
  name: "primaryFormsData",
  initialState,
  reducers: {
    addPrimaryFormsData: (state, action) => {
      const elementToAdd = action.payload;
      const existingElement = state.find(
        (element) => element.formId === elementToAdd.formId
      );
      if (existingElement) {
        existingElement.displayValue = elementToAdd.displayValue;
      } else {
        state.push(elementToAdd);
      }
    },
    resetPrimaryFormsData: (state) => {
      return [];
    },
  },
});

export const { addPrimaryFormsData, resetPrimaryFormsData } =
  primaryFormsDataSlice.actions;
export default primaryFormsDataSlice.reducer;
