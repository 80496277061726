import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstRow: [],
  secondRow: [],
  thirdRow: [],
  fourthRow: [],
};

const googleWalletFieldsRow = createSlice({
  name: "googleWalletRow",
  initialState,
  reducers: {
    addOrUpdateRowData: (state, action) => {
      const payload = action.payload;
      const { row, label } = payload;

      switch (row) {
        case 1:
          handleRow(state.firstRow, label, payload);
          break;
        case 2:
          handleRow(state.secondRow, label, payload);
          break;
        case 3:
          handleRow(state.thirdRow, label, payload);
          break;
        case 4:
          handleRow(state.fourthRow, label, payload);
          break;
        default:
        // Handle other cases if needed
      }
    },
    updateGoogleRowLabel: (state, action) => {
      const { form, value } = action.payload;
      Object.keys(state).forEach((rowKey) => {
        state[rowKey] = state[rowKey].map((item) =>
          item.label === form ? { ...item, value } : item
        );
      });
    },

    removeItemFromRow: (state, action) => {
      const label = action.payload;
      return {
        ...state,
        firstRow: state.firstRow.filter((item) => item.label !== label),
        secondRow: state.secondRow.filter((item) => item.label !== label),
        thirdRow: state.thirdRow.filter((item) => item.label !== label),
        fourthRow: state.fourthRow.filter((item) => item.label !== label),
      };
    },
    setGoogleRowPrevData: (state, action) => {
      const { firstRow, secondRow, thirdRow, fourthRow } = action.payload;
      return {
        firstRow: [...firstRow],
        secondRow: [...secondRow],
        thirdRow: [...thirdRow],
        fourthRow: [...fourthRow],
      };
    },
    resetGoogleRowState: (state) => {
      return initialState;
    },
  },
});
// Helper function to update or add an item in a row
const handleRow = (rowArray, label, payload) => {
  const existingIndex = rowArray.findIndex((item) => item.label === label);

  if (existingIndex !== -1) {
    rowArray[existingIndex] = payload;
  } else {
    rowArray.push(payload);
  }
};
export const {
  addOrUpdateRowData,
  updateGoogleRowLabel,
  removeItemFromRow,
  resetGoogleRowState,
  setGoogleRowPrevData,
} = googleWalletFieldsRow.actions;
export default googleWalletFieldsRow.reducer;
