import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const backFieldsFormDataSlice = createSlice({
  name: "backFieldsFormData",
  initialState,
  reducers: {
    addOrUpdateBackField: (state, action) => {
      const backField = action.payload;
      const existingIndex = state.findIndex((item) => item.id === backField.id);
      if (existingIndex !== -1) {
        state[existingIndex] = backField;
      } else {
        state.push(backField);
      }
    },
    removeBackField: (state, action) => {
      const formId = action.payload;
      return state.filter((item) => item.id !== formId);
    },
  },
});

export const { addOrUpdateBackField,removeBackField } = backFieldsFormDataSlice.actions;
export default backFieldsFormDataSlice.reducer;
