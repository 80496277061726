import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { setPassThemeInfo } from "../../../Store/Slices/passDetailsSlice";
import { removeActiveMenu } from "../../../Store/Slices/activeMenuSlice";
import { WalletContext } from "../../../Context/WalletProvider";

const Barcode = (props) => {
  const [showbarcode, setShowbarcode] = useState(true);
  const { formate } = useSelector((state) => state?.passThemeDetails);
  const { wallets } = useContext(WalletContext);
  const dispatch = useDispatch();
  props.barcode(formate);

  const handleOnClick = (e) => {
    const formate = e.target.title;
    if (wallets === "Google Wallet") {
      if (formate === "PKBarcodeFormatQR") {
        dispatch(setPassThemeInfo({ formate: "QR_CODE" }));
      } else if (formate === "PKBarcodeFormatAztec") {
        dispatch(setPassThemeInfo({ formate: "AZTEC" }));
      } else if (formate === "PKBarcodeFormatPDF417") {
        dispatch(setPassThemeInfo({ formate: "PDF_417" }));
      } else if (formate === "PKBarcodeFormatCode128") {
        dispatch(setPassThemeInfo({ formate: "CODE_128" }));
      }
    } else {
      const payload = { formate };
      dispatch(setPassThemeInfo(payload));
    }
  };

  const handleBarcodeValueChange = (e) => {
    const value = e.target.value;
    const payload = { barcodeValue: value };
    dispatch(setPassThemeInfo(payload));
  };

  const handlePassTypeIdentifierChange = (e) => {
    const value = e.target.value;
    const payload = { passTypeIdentifier: value };
    dispatch(setPassThemeInfo(payload));
  };

  return (
    <>
      <div className="sidebar">
        <div className="action-area d-flex justify-content-between align-items-center">
          <span>Barcode</span>
          {/* <img
            src="/assets/images/close.svg"
            alt="close"
            className="close"
            onClick={() => {
              dispatch(removeActiveMenu());
            }}
          /> */}
        </div>
        <div className="sidebar-theme">
          <form>
            <Form.Check // prettier-ignore
              type="switch"
              id="show-barcode"
              label="Show barcode on pass"
              checked={showbarcode}
              onChange={() => setShowbarcode(!showbarcode)}
            />
            {showbarcode === true && (
              <div>
                <div className="barcodes">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className={`barcode-single ${
                          formate === "PKBarcodeFormatQR" ? "active" : ""
                        }`}
                        onClick={handleOnClick}
                        title="PKBarcodeFormatQR"
                      >
                        <img
                          src="/assets/images/barcode1.svg"
                          alt="barcode"
                          className="barcode-square"
                          title="PKBarcodeFormatQR"
                          onClick={handleOnClick}
                        />
                        <span title="PKBarcodeFormatQR" onClick={handleOnClick}>
                          qr code
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className={`barcode-single ${
                          formate === "PKBarcodeFormatAztec" ? "active" : ""
                        }`}
                        onClick={handleOnClick}
                        title="PKBarcodeFormatAztec"
                      >
                        <img
                          src="/assets/images/barcode2.svg"
                          alt="barcode"
                          className="barcode-square"
                          onClick={handleOnClick}
                          title="PKBarcodeFormatAztec"
                        />
                        <span
                          onClick={handleOnClick}
                          title="PKBarcodeFormatAztec"
                        >
                          aztec
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className={`barcode-single ${
                          formate === "PKBarcodeFormatPDF417" ? "active" : ""
                        }`}
                        onClick={handleOnClick}
                        title="PKBarcodeFormatPDF417"
                      >
                        <img
                          src="/assets/images/barcode3.svg"
                          alt="barcode"
                          className="barcode-rectangle"
                          onClick={handleOnClick}
                          title="PKBarcodeFormatPDF417"
                        />
                        <span
                          onClick={handleOnClick}
                          title="PKBarcodeFormatPDF417"
                        >
                          pdf 417
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className={`barcode-single ${
                          formate === "PKBarcodeFormatCode128" ? "active" : ""
                        }`}
                        onClick={handleOnClick}
                        title="PKBarcodeFormatCode128"
                      >
                        <img
                          src="/assets/images/barcode4.svg"
                          alt="barcode"
                          className="barcode-rectangle"
                          onClick={handleOnClick}
                          title="PKBarcodeFormatCode128"
                        />
                        <span
                          onClick={handleOnClick}
                          title="PKBarcodeFormatCode128"
                        >
                          code 128
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <h5>encode barcode content</h5>
                <input
                  type="text"
                  placeholder="${pid}"
                  id="barcodeValue"
                  onChange={handleBarcodeValueChange}
                />
                <h5>text below barcode</h5>
                <input
                  type="text"
                  placeholder="${pid}"
                  id="passTypeIdentifier"
                  onChange={handlePassTypeIdentifierChange}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="security-animation"
                  label="Security Animation"
                />
                {/* <h5 className='mt-3'>NFC support</h5>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="use-nfc"
                                label="Use NFC"
                            /> */}
                {/* <h5 className='mt-3'>NFC payload</h5>
                            <div className='payload-items'>
                                <span>tier points <FontAwesomeIcon icon={faXmark} /> </span>
                                <span>tier points <FontAwesomeIcon icon={faXmark} /> </span>
                                <span>tier <FontAwesomeIcon icon={faXmark} /> </span>
                                <span>tierasdf <FontAwesomeIcon icon={faXmark} /> </span>
                                <button type='button'>Clear all</button>
                            </div>
                            <h5>select option</h5>
                            <div className='checkboxes'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="check in" id="checkIn" />
                                    <label className="form-check-label" htmlFor="checkIn">
                                        check in
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="new (custom field)" id="newCustomField" />
                                    <label className="form-check-label" htmlFor="newCustomField">
                                        new (custom field)
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="new (custom field)" id="newDynamicField" />
                                    <label className="form-check-label" htmlFor="newDynamicField">
                                        New Field (custom field)
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="salutaion" id="salutaion" />
                                    <label className="form-check-label" htmlFor="salutaion">
                                        salutaion
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="legal" id="legal" />
                                    <label className="form-check-label" htmlFor="legal">
                                        legal
                                    </label>
                                </div>
                            </div> */}
              </div>
            )}
            <div className="alert-box d-flex justify-content-start align-items-start">
              <FontAwesomeIcon icon={faQuestionCircle} />
              <div className="content">
                <p>
                  Your program is not eligible for NFC support. There are two
                  requirements for NFC capability
                </p>
                <ol type="1">
                  <li>Account eligible for production</li>
                  <li>Project has NFC capable certificate attached</li>
                </ol>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Barcode;
