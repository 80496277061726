import React from "react";

const ErrorScreen = () => {
  return (
    <div className="error-screen-container">
      {/* 404 Error Section Start */}
      <div className="col-lg-12">
        <div className="error-content text-lg-center">
          <h1 className="display-1">404</h1>
          <h2 className="display-4">OH! You're lost.</h2>
          <p className="lead">Please try again.</p>
          <a href="/" className="btn btn-lg btn-primary">
            Back to home
          </a>
        </div>
      </div>
      {/* 404 Error Section End */}
    </div>
  );
};

export default ErrorScreen;
