import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formName: "",
  expiryDate: "Expiry Date",
  mobileNumber: "Mobile Number",
  points: "Points",
  programName: "Program Name",
  name: "Name",
  tier: "Tier",
  link: "Link",
  newDynamicField: "New Field",
  newStaticField: "New Static Field",
  firstName: "First Name",
  lastName: "Last Name",
  salutation: "Salutation",
  suffix: "Suffix",
  gender: "Gender",
  dateOfBirth: "Date of Birth",
  legal: "Legal",
  optIn: "Opt in",
  optOut: "Opt out",
  externalID: "External ID",
  tierPoints: "Tier Points",
  secondaryPoints: "Secondary Points",
  memberStatus: "Member Status",
  groupingIdentifier: "Grouping Identifier",
  email: "Email",
  information: "Information",
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setDynamicLabel: (state, action) => {
      const { form, value } = action.payload;
      if (form === "Expiry Date") {
        state.expiryDate = value;
      } else if (form === "Mobile Number") {
        state.mobileNumber = value;
      } else if (form === "Points") {
        state.points = value;
      } else if (form === "Program Name") {
        state.programName = value;
      } else if (form === "Name") {
        state.name = value;
      } else if (form === "Tier") {
        state.tier = value;
      } else if (form === "Link") {
        state.link = value;
      } else if (form === "New Field") {
      } else if (form === "Information") {
        state.information = value;
      } else if (form === "New Field") {
        state.newDynamicField = value;
      } else if (form === "New Static Field") {
        state.newStaticField = value;
      } else if (form === "Email") {
        state.email = value;
      } else if (form === "First Name") {
        state.firstName = value;
      } else if (form === "Last Name") {
        state.lastName = value;
      } else if (form === "Salutation") {
        state.salutation = value;
      } else if (form === "Suffix") {
        state.suffix = value;
      } else if (form === "Gender") {
        state.gender = value;
      } else if (form === "Date of Birth") {
        state.dateOfBirth = value;
      } else if (form === "Legal") {
        state.legal = value;
      } else if (form === "Opt in") {
        state.optIn = value;
      } else if (form === "Opt out") {
        state.optOut = value;
      } else if (form === "External ID") {
        state.externalID = value;
      } else if (form === "Tier Points") {
        state.tierPoints = value;
      } else if (form === "Secondary Points") {
        state.secondaryPoints = value;
      } else if (form === "Member Status") {
        state.memberStatus = value;
      } else if (form === "Grouping Identifier") {
        state.groupingIdentifier = value;
      }
    },
    setFormNameFromRedux: (state, action) => {
      state.formName = action.payload;
    },
    resetFormsLabels: (state) => {
      return initialState;
    },
  },
});

export const { setDynamicLabel, setFormNameFromRedux,resetFormsLabels } = walletSlice.actions;

export default walletSlice.reducer;
