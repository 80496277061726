import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstRowData: [],
  secondRowData: [],
  thirdRowData: [],
  fourthRowData: [],
};
const googleWalletFormData = createSlice({
  name: "googleWalletFormData",
  initialState,
  reducers: {
    storeGoogleFormsData: (state, action) => {
      const { formData, row } = action.payload;
      if (row === 1) {
        const existingIndex = state.firstRowData.findIndex(
          (item) => item.id === formData.id
        );
        if (existingIndex !== -1) {
          state.firstRowData[existingIndex] = formData;
        } else {
          state.firstRowData.push(formData);
        }
      }
      if (row === 2) {
        const existingIndex = state.secondRowData.findIndex(
          (item) => item.id === formData.id
        );
        if (existingIndex !== -1) {
          state.secondRowData[existingIndex] = formData;
        } else {
          state.secondRowData.push(formData);
        }
      }
      if (row === 3) {
        const existingIndex = state.thirdRowData.findIndex(
          (item) => item.id === formData.id
        );
        if (existingIndex !== -1) {
          state.thirdRowData[existingIndex] = formData;
        } else {
          state.thirdRowData.push(formData);
        }
      }
      if (row === 4) {
        const existingIndex = state.fourthRowData.findIndex(
          (item) => item.id === formData.id
        );
        if (existingIndex !== -1) {
          state.fourthRowData[existingIndex] = formData;
        } else {
          state.fourthRowData.push(formData);
        }
      }
    },
    removeGoogleFormsData: (state, action) => {
      const id = action.payload;
      return {
        ...state,
        firstRowData: state.firstRowData?.filter((item) => item.id !== id),
        secondRowData: state.secondRowData?.filter((item) => item.id !== id),
        thirdRowData: state.thirdRowData?.filter((item) => item.id !== id),
        fourthRowData: state.fourthRowData?.filter((item) => item.id !== id),
      };
    },
    resetGoogleFormsState: (state) => {
      return initialState;
    },
  },
});

export const { storeGoogleFormsData, removeGoogleFormsData,resetGoogleFormsState } =
  googleWalletFormData.actions;
export default googleWalletFormData.reducer;
