import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

const SignIn = () => {

  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }

  const loginUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requestData = {
      email: email,
      password: password,
    };

    const url = `${process.env.REACT_APP_API_ENDPOINT}login`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        toast.error("Please check your email and password")
        throw new Error('Network response was not ok');
      }

      const comingRes = await response.json();
      localStorage.setItem("accessToken", JSON.stringify(comingRes.token));
      localStorage.setItem("userData", JSON.stringify(comingRes.user));
      navigate('/');
      toast.success("Login successfull")
      setLoading(false);
    } catch (error) {
      toast.error("Please check your email and password")
      setLoading(false);
    }
  }

  const handlePasswordViewOnClick = () => {
    setPasswordVisible(!passwordVisible);
  }

  return (
    <>
      <div className="container signin-page">
        <div className="row signin-signout-page">
          <div className="col-lg-8">
            <div className="signin-signout-page-left">
              <img src='/assets/images/signin-bg.svg' alt="signin-bg" />
            </div>
          </div>
          <div className="col-lg-4 bg-white">
            <div className="signin-signout-page-right">
              <h2>Welcome back</h2>
              <p>Welcome back! Please enter your details.</p>
              <h3>Sign in to your account</h3>
              <div className="main-form">
                <form onSubmit={loginUser}>
                  <div className="mb-4">
                    <Form.Label htmlFor="email">Email address</Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      placeholder="jean@jrs.com"
                      onChange={handleEmailChange}
                    />
                  </div>
                  <div className="mb-4 position-relative">
                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Label htmlFor="password">Password</Form.Label>
                      <Link to="/forgot-password">Forgot?</Link>
                    </div>
                    <Form.Control
                      type={passwordVisible ? 'text' : 'password'}
                      id="password"
                      onChange={handlePasswordChange}
                    />
                    <div className="pass-view" onClick={handlePasswordViewOnClick}>
                      {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </div>
                  </div>
                  <div className="mb-4">
                    <Button variant="primary" type="submit" disabled={loading}>
                      {loading ? 'Loading...' : 'Sign in'}
                    </Button>
                  </div>
                </form>
              </div>
              {/* <div className="d-flex justify-content-between align-items-center flex-wrap mb-4">
                  <button className="d-flex justify-content-center align-items-center"> <img src='/assets/images/google.svg' alt="google"/> Sign in wiith Google</button>
                  <button className="d-flex justify-content-center align-items-center"> <img src='/assets/images/sso.svg' alt="google"/> Sign in wiith Google</button>
              </div> */}
              <p className="mb-5">Don't have an account? <Link to="/register">Create an account</Link></p>
              <img src='/assets/images/keos-logo-light.svg' alt="keos-logo" className="keos-logo" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;