import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WalletContext } from "../../Context/WalletProvider";
import { setPassThemeInfo } from "../../Store/Slices/passDetailsSlice.jsx";

const Program = () => {
  const {
    removeForm,
    setFormName,
    setActiveField,
  } = useContext(WalletContext);
  const dispatch = useDispatch();
  const { passThemeDetails } = useSelector((state) => state);

  const primaryFormsData = useSelector((state) => state.primaryFormsData);
  const [defaulDisplayValue, setDefaulDisplayValue] = useState("");
  const [program, setProgram] = useState({
    id: 17,
    programName: {
      label: "Program Name",
      value: "My Loyalty Card",
    },
  });

  const handleprogramChange = (e) => {
    const value = e.target.value;
    const payload = {
      cardTitle: value,
    };
    dispatch(setPassThemeInfo(payload));
    if (!value) {
      setFormName("Program");
      setActiveField("Program");
      const payload = {
        cardTitle: "empty",
      };
      dispatch(setPassThemeInfo(payload));
    }
    setProgram((prevFormData) => ({
      ...prevFormData,
      programName: {
        ...prevFormData.programName,
        value: value,
      },
    }));
  };


  useEffect(() => {
    const matchingElement = primaryFormsData.find(
      (element) => element?.formId === 17
    );
    setDefaulDisplayValue(matchingElement?.displayValue);
  }, []);
  useEffect(() => {
    setProgram((prevFormData) => ({
      ...prevFormData,
      label: {
        ...prevFormData.label,
        value: passThemeDetails?.header,
      },
    }));
  }, [passThemeDetails?.header]);

  return (
    <>
      <div className="sidebar">
        <div className="action-area d-flex justify-content-between align-items-center">
          <span>Name</span>
          <div>
            <img
              src="/assets/images/close.svg"
              alt="close"
              className="close"
              onClick={() => setFormName("")}
            />
          </div>
        </div>
        <div className="form-area">
          <p>Program settings (apple and google)</p>
          <div className="mb-3">
            <label htmlFor="programName" className="form-label">
              Program Name
            </label>
            <input
              type="text"
              className="form-control"
              name="programName"
              id="programName"
              defaultValue={passThemeDetails?.cardTitle}
              onChange={handleprogramChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Program;
