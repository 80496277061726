import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useData } from "../../Context/WalletFormProvider.jsx";
import { WalletContext } from "../../Context/WalletProvider.jsx";
import { setDynamicLabel } from "../../Store/Slices/WalletSlice.jsx";
import { addOrUpdateAuxiliaryField } from "../../Store/Slices/auxiliaryFormsDataSlice.jsx";
import { isInSecondaryFields } from "../../utils/ckeckIsInPrimaryField.jsx";
import { isInAuxiliaryFields } from "../../utils/chackIsInAuxiliaryField.jsx";
import { setPassThemeInfo } from "../../Store/Slices/passDetailsSlice.jsx";

const Organization = () => {
  const {
    removeForm,
    setFormName,
    setActiveField,
    primaryFields,
    auxFields,
    secondaryFields,
  } = useContext(WalletContext);
  const dispatch = useDispatch();
  const { passThemeDetails } = useSelector((state) => state);
  const primaryFormsData = useSelector((state) => state.primaryFormsData);
  const [defaulDisplayValue, setDefaulDisplayValue] = useState("");
  const { addOrUpdateSecondaryFormData, setPrimaryFormData } = useData();
  const [organization, setOrganization] = useState({
    id: 14,
    label: {
      label: "Label",
      value: "Organization",
      baseValue: "Organization",
    },
  });

  const handleLabelChange = (e) => {
    const value = e.target.value;
    const payload = {
      header: value,
    };
    dispatch(setPassThemeInfo(payload));
    if (!value) {
      setFormName("Organization");
      setActiveField("Organization");
      const payload = {
        header: "empty",
      };
      dispatch(setPassThemeInfo(payload));
    }
    setOrganization((prevFormData) => ({
      ...prevFormData,
      label: {
        ...prevFormData.label,
        value: value,
      },
    }));
  };
  useEffect(() => {
    if (isInSecondaryFields(secondaryFields, "Organization")) {
      addOrUpdateSecondaryFormData(organization);
    } else if (isInAuxiliaryFields(auxFields, "Organization")) {
      dispatch(addOrUpdateAuxiliaryField(organization));
    } else if (primaryFields?.label === "Organization") {
      setPrimaryFormData(organization);
    }
  }, [organization]);

  useEffect(() => {
    const matchingElement = primaryFormsData.find(
      (element) => element?.formId === 14
    );
    setDefaulDisplayValue(matchingElement?.displayValue);
  }, []);

  // default display value
  useEffect(() => {
    setOrganization((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: defaulDisplayValue? defaulDisplayValue : "",
      },
      label: {
        ...prevFormData.label,
        value: passThemeDetails?.cardTitle
,
      },
    }));
  }, [defaulDisplayValue, passThemeDetails?.cardTitle
]);
  useEffect(() => {
    setOrganization((prevFormData) => ({
      ...prevFormData,
      label: {
        ...prevFormData.label,
        value: passThemeDetails?.cardTitle
,
      },
    }));
  }, [passThemeDetails?.cardTitle
]);
  return (
    <>
      <div className="sidebar">
        <div className="action-area d-flex justify-content-between align-items-center">
          <span>Display Name</span>
          <div>
            <img
              src="/assets/images/close.svg"
              alt="close"
              className="close"
              onClick={() => setFormName("")}
            />
          </div>
        </div>
        <div className="form-area">
          <form>
            <div className="mb-4">
              <label htmlFor="label" className="form-label">
                Organization Name
              </label>
              <input
                type="text"
                className="form-control"
                name="label"
                id="label"
                defaultValue={passThemeDetails?.header
}
                onChange={handleLabelChange}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Organization;
