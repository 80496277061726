import { createBrowserRouter, Navigate } from "react-router-dom";
import PrivetRoute from "../PrivetRouter/PrivetRoute";
import ForgotPassword from "../pages/ForgotPassword";
import Home from "../pages/Home";
import SignIn from "../pages/SignIn";
import Register from "../pages/Register";
import Draft from "../pages/Draft";
import ErrorScreen from "../pages/ErrorScreen";
const user = JSON.parse(localStorage.getItem("userData"));

// ...
export const router = createBrowserRouter([
  {
    path: "/",
    element: user ? <Navigate to="/app" /> : <Home />,
  },
  {
    path: "/wallet/:id/:pass_type",
    element: <Home />,
    loader: ({ params }) => {
      return fetch(
        `${process.env.REACT_APP_API_ENDPOINT}get-generate-pass?pass_id=${params.id}&pass_type=${params.pass_type}`
      );
    },
  },
  {
    path: "/wallet",
    element: <Home />,
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/app",
    element: (
      <PrivetRoute>
        <Draft />
      </PrivetRoute>
    ),
  },
  // Catch-all route for handling all other routes
  {
    path: "*",
    element: <ErrorScreen />,
  },
]);
// ...
