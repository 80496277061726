import { configureStore, combineReducers } from "@reduxjs/toolkit";
import walletReducer from "../Store/Slices/WalletSlice";
import removeElementSlice from "../Store/Slices/RemoveElementSlice";
import addPresentFieldsElement from "../Store/Slices/AddPresentFieldsSlice";
import singleFormLabel from "./Slices/SingleFormLabelSlice";
import hederMenuAcitve from "./Slices/activeMenuSlice";
import primaryFormsData from "./Slices/primaryFormsDataSlice";
import passThemeDetails from "./Slices/passDetailsSlice";
import backFieldsFormData from "./Slices/backFieldsFormsDataSlice";
import addOrUpdateAuxiliaryField  from "./Slices/auxiliaryFormsDataSlice";
import couponPassThemeDetils from "./Slices/couponPassThemeInfo";
import addOrUpdateSecondaryFormsData from "./Slices/allFieldsFromsData/SecondaryFields/secondaryFormsDataSlice";
// Google
import googleWalletFieldsRow from "./Slices/GoogleWalletData/Fields/googleFieldsRow";
import googleWalletData from "../Store/Slices/GoogleWalletData/allRowFormsData/googleWalletFormsData";
//
const rootReducer = combineReducers({
  wallet: walletReducer,
  removeElement: removeElementSlice,
  addPresetFields: addPresentFieldsElement,
  singleFormLabelData: singleFormLabel,
  hederMenuAcitve: hederMenuAcitve,
  primaryFormsData: primaryFormsData,
  passThemeDetails: passThemeDetails,
  backFieldsFormData: backFieldsFormData,
  addOrUpdateAuxiliaryField,
  couponPassThemeDetils,
  secondaryFields: addOrUpdateSecondaryFormsData,
  //Google
  googleWalletFieldsRow,
  googleWalletData,
});
const store = configureStore({
  reducer: rootReducer,
});

export default store;
