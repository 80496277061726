import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/main.css";
import "./assets/css/responsive.css";
import swDev from "./swDev";
import WalletProvider from "./Context/WalletProvider";
import WalletFormProvider from "./Context/WalletFormProvider";
import { Provider } from "react-redux";
import store from "./Store/Store";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <WalletFormProvider>
      <WalletProvider>
        <App />
      </WalletProvider>
    </WalletFormProvider>
  </Provider>
);
swDev();
