import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WalletContext } from "../../Context/WalletProvider";
import { setDynamicLabel } from "../../Store/Slices/WalletSlice";
import { addOrUpdateBackField } from "../../Store/Slices/backFieldsFormsDataSlice";
import { addPrimaryFormsData } from "../../Store/Slices/primaryFormsDataSlice";
import { updateGoogleRowLabel } from "../../Store/Slices/GoogleWalletData/Fields/googleFieldsRow";

const LinkField = () => {
  const {
    removeForm,
    setFormName,
    setActiveField,
    formInputs,
    setFormInputs,
    wallets,
  } = useContext(WalletContext);
  const dispatch = useDispatch();
  const { wallet, primaryFormsData, googleWalletData } = useSelector(
    (state) => state
  );
  const { firstRowData, secondRowData, thirdRowData, fourthRowData } =
    googleWalletData;
  const allRowsData = [
    firstRowData,
    secondRowData,
    thirdRowData,
    fourthRowData,
  ];
  const [defaulDisplayValue, setDefaulDisplayValue] = useState("");

  const [formData, setFormData] = useState({
    id: 11,
    link: {
      label: "Saved Link",
      value: "",
    },
    label: {
      label: "Title",
      value: "My New Link",
    },
    displayValue: {
      label: "URL",
      value: "",
    },
    linkType: {
      label: "Link Type",
      value: "Web",
    },
  });

  const handleLinkChange = (e) => {
    const value = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      link: {
        ...prevFormData.link,
        value: value,
      },
    }));
  };

  const handleTitleChange = (e) => {
    const value = e.target.value;
    const payload = { form: "Link", value };
    dispatch(setDynamicLabel(payload));
    dispatch(updateGoogleRowLabel(payload));

    if (!value) {
      setFormName("Link");
      setActiveField("Link");
    }
    const updatedFormInputs = formInputs.map((field) =>
      field.label === "Link" ? { ...field, value } : field
    );
    setFormInputs(updatedFormInputs);
    setActiveField(value);
    setFormName("Link");
    //

    setFormData((prevFormData) => ({
      ...prevFormData,
      label: {
        ...prevFormData.title,
        value: value,
      },
    }));
  };

  const handleUrlChange = (e) => {
    const value = e.target.value;
    const payload = { formId: 11, displayValue: value };
    dispatch(addPrimaryFormsData(payload));
    setFormData((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData.url,
        value: value,
      },
    }));
  };

  const handleLinkTypeChange = (e) => {
    const value = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      linkType: {
        ...prevFormData.linkType,
        value: value,
      },
    }));
  };

  useEffect(() => {
    dispatch(addOrUpdateBackField(formData));
  }, [formData]);

  useEffect(() => {
    if (wallets === "Google Wallet") {
      const matchingElement = allRowsData
        .flatMap((rowData) => rowData)
        .find((element) => element?.id === 11);
      setDefaulDisplayValue(matchingElement?.displayValue?.value);
    } else {
      const matchingElement = primaryFormsData.find(
        (element) => element?.formId === 11
      );
      setDefaulDisplayValue(matchingElement?.displayValue);
    }
  }, []);
  // default display value
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: defaulDisplayValue ? defaulDisplayValue : "",
      },
      label: {
        ...prevFormData.label,
        value: wallet?.link,
      },
    }));
  }, [defaulDisplayValue, wallet?.link]);

  return (
    <>
      <div className="sidebar">
        <div className="action-area d-flex justify-content-between align-items-center">
          <span>Link</span>
          <div>
            <img
              src="/assets/images/delete.svg"
              alt="delete"
              className="delete"
              onClick={() => removeForm("Link", 11)}
            />
            <img
              src="/assets/images/close.svg"
              alt="close"
              className="close"
              onClick={() => setFormName("")}
            />
          </div>
        </div>
        <div className="form-area">
          {/* <div className="mb-4">
            <label htmlFor="savedLink" className="form-label">
              Saved Link
            </label>
            <select
              className="form-select"
              id="savedLink"
              onChange={handleLinkChange}
            >
              <option value="link 3">Link 3</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div> */}
          <div className="mb-4">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              name="title"
              id="title"
              defaultValue={wallet?.link}
              onChange={handleTitleChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="title" className="form-label">
              Url
            </label>
            <input
              type="text"
              className="form-control"
              name="title"
              id="title"
              defaultValue={defaulDisplayValue}
              onChange={handleUrlChange}
            />
            <span className="input-bottom text-danger">
              {" "}
              <FontAwesomeIcon icon={faCircleQuestion} /> This field is required
            </span>
          </div>
          <div className="mb-4">
            <label htmlFor="linkType" className="form-label">
              Link Type
            </label>
            <select
              className="form-select"
              id="linkType"
              onChange={handleLinkTypeChange}
            >
              <option value="Web">Web</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkField;
