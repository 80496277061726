export const removeObjectsById = (arr, idToRemove) => {
    return arr.reduce((acc, item) => {
      if (item.id === idToRemove) {
        // If the current object has the matching id, don't include it in the result
        return acc;
      }
      if (typeof item === "object") {
        // If the current item is an object, recursively check its properties
        const updatedItem = Object.fromEntries(
          Object.entries(item).map(([key, value]) => {
            if (typeof value === "object") {
              // Recursively remove objects in nested structures
              const updatedValue = removeObjectsById([value], idToRemove)[0];
              return [key, updatedValue];
            } else {
              return [key, value];
            }
          })
        );

        // If the updated item still has properties, include it in the result
        if (Object.values(updatedItem).some((val) => val !== undefined)) {
          acc.push(updatedItem);
        }
      } else {
        // If the current item is not an object, include it in the result
        acc.push(item);
      }
      return acc;
    }, []);
  };