import React from 'react';
import Modal from 'react-bootstrap/Modal';


function MaintenanceModal({ showMaintenanceModal, setShowMaintenanceModal }) {

    const handleOnClick = () => {
        setShowMaintenanceModal(false);
    };

    return (

        <Modal size="md" show={showMaintenanceModal} onHide={() => setShowMaintenanceModal(false)} centered>
            <div className="pt-4 px-4 modal-heading text-center">
                <h2>We are working on it...</h2>
                <p className='mt-4' style={{ lineHeight: "20px" }}>Our team is working diligently to enhance and optimize it to provide you with a better user experience.</p>
            </div>

            <Modal.Body>
                <div className="pb-4 buttons">
                    <button type="button" onClick={handleOnClick}>Okay</button>
                </div>
            </Modal.Body>
        </Modal >

    )
}

export default MaintenanceModal