import { faCircleQuestion, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WalletContext } from "../../Context/WalletProvider";
import { setDynamicLabel } from "../../Store/Slices/WalletSlice";
import { addOrUpdateBackField } from "../../Store/Slices/backFieldsFormsDataSlice";
import { addPrimaryFormsData } from "../../Store/Slices/primaryFormsDataSlice";
import { useData } from "../../Context/WalletFormProvider.jsx";
import { updateGoogleRowLabel } from "../../Store/Slices/GoogleWalletData/Fields/googleFieldsRow.jsx";

const TermsAndCondition = () => {
  const {
    removeForm,
    setFormName,
    setActiveField,
    formInputs,
    setFormInputs,
    primaryFields,
    secondaryFields,
    auxFields,
    wallets,
  } = useContext(WalletContext);
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const { primaryFormsData, googleWalletData } = useSelector((state) => state);
  const { firstRowData, secondRowData, thirdRowData, fourthRowData } =
    googleWalletData;
  const allRowsData = [
    firstRowData,
    secondRowData,
    thirdRowData,
    fourthRowData,
  ];
  const [defaulDisplayValue, setDefaulDisplayValue] = useState("");
  const [information, setInformation] = useState({
    id: 26,
    label: {
      label: "Label",
      value: "Information",
      baseValue: "Information",
    },
    displayValue: {
      label: "Display Value",
      value: "",
    },
  });

  const handleLabelChange = (e) => {
    const value = e.target.value;
    const payload = { form: "Information", value };
    dispatch(setDynamicLabel(payload));
    dispatch(updateGoogleRowLabel(payload));

    //
    if (!value) {
      setFormName("Information");
      setActiveField("Information");
    }
    const updatedFormInputs = formInputs.map((field) =>
      field.label === "Information" ? { ...field, value } : field
    );
    setFormInputs(updatedFormInputs);
    setActiveField(value);
    setFormName("Information");
    //

    setInformation((prevFormData) => ({
      ...prevFormData,
      label: {
        ...prevFormData.label,
        value: value,
      },
    }));
  };
  const handleDisplayValueChange = (e) => {
    const value = e.target.value;
    const payload = { formId: 26, displayValue: value };
    dispatch(addPrimaryFormsData(payload));
    setInformation((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: value,
      },
    }));
  };
  useEffect(() => {
    dispatch(addOrUpdateBackField(information));
  }, [information]);

  useEffect(() => {
    if (wallets === "Google Wallet") {
      const matchingElement = allRowsData
        .flatMap((rowData) => rowData)
        .find((element) => element?.id === 26);
      setDefaulDisplayValue(matchingElement?.displayValue?.value);
    } else {
      const matchingElement = primaryFormsData.find(
        (element) => element?.formId === 26
      );
      setDefaulDisplayValue(matchingElement?.displayValue);
    }
  }, []);

  // default display value
  useEffect(() => {
    setInformation((prevFormData) => ({
      ...prevFormData,
      displayValue: {
        ...prevFormData?.displayValue,
        value: defaulDisplayValue ? defaulDisplayValue : "",
      },
      label: {
        ...prevFormData.label,
        value: wallet?.information,
      },
    }));
  }, [defaulDisplayValue, wallet?.information]);

  return (
    <>
      <div className="sidebar">
        <div className="action-area d-flex justify-content-between align-items-center">
          <span>Info</span>
          <div>
            <img
              src="/assets/images/delete.svg"
              alt="delete"
              className="delete"
              onClick={() => removeForm("Information", 26)}
            />
            <img
              src="/assets/images/close.svg"
              alt="close"
              className="close"
              onClick={() => setFormName("")}
            />
          </div>
        </div>
        <div className="form-area">
          <div className="mb-4">
            <label htmlFor="label" className="form-label">
              Label
            </label>
            <input
              type="text"
              className="form-control"
              name="label"
              id="label"
              defaultValue={wallet?.information}
              onChange={handleLabelChange}
            />
          </div>
          <div className="caution-box d-flex justify-content-start align-items-start">
            <div className="left">
              <FontAwesomeIcon icon={faCircleQuestion} />
            </div>
            <div className="right">
              <div className="top d-flex justify-content-between align-items-center">
                <span>Caution</span>
                <FontAwesomeIcon icon={faXmark} />
              </div>
              <div className="bottom">
                <p>
                  The latest version of Google Wallet has updated formatting for
                  long text fields. Please review this help article if you see
                  any inconsistencies on Android devices.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="displayValue" className="form-label">
              Display Value
            </label>
            <textarea
              type="textarea"
              className="form-control"
              name="displayValue"
              id="displayValue"
              defaultValue={defaulDisplayValue}
              onChange={handleDisplayValueChange}
            />
            <span className="input-bottom">
              {" "}
              <FontAwesomeIcon icon={faCircleQuestion} /> Static text on the
              customer pass
            </span>
          </div>
          <div className="label-text">
            <label className="form-label">Field Key</label>
            <span className="input-bottom">universal.info</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndCondition;
